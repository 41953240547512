import React, { useMemo } from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { TabItem } from "@src/types/antd_types";
import { Form, Radio } from "antd";
import "./filter_tabs.less";

export interface FilterTabProps {
  defaultActiveConfig: TabItem;
  items: TabItem[];
  onChange: (key: string) => void;
  disabled?: boolean;
  isLoading?: boolean;
}

const FilterTabs: React.FC<FilterTabProps> = ({
  defaultActiveConfig,
  items,
  onChange,
  disabled = false,
  isLoading = false,
}) => {
  const [form] = Form.useForm();

  useMemo(() => {
    form.setFieldsValue({ tab: defaultActiveConfig.key });
  }, [defaultActiveConfig]);

  function handleChange() {
    onChange(form.getFieldValue("tab"));
  }

  function getOptions() {
    return items.map((item: TabItem) => ({
      label: (
        <div className="tab-label">
          {item?.icon}
          {item.label} {isLoading && <LoadingOutlined />}
        </div>
      ),
      value: item.key,
      disabled: disabled,
    }));
  }

  return (
    <div className="filter-tabs">
      <Form
        form={form}
        layout="vertical"
        initialValues={{ tab: defaultActiveConfig?.key }}
        onValuesChange={handleChange}
      >
        <Form.Item name="tab" valuePropName="value">
          <Radio.Group
            block
            options={getOptions()}
            optionType="button"
            size="large"
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default FilterTabs;
