// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-input-filter .ant-input-prefix {
  color: #1d4477;
}
.search-input-filter ::placeholder {
  color: #617ca0;
}
`, "",{"version":3,"sources":["webpack://./src/features/table_filtering/components/search_input/search_input.less"],"names":[],"mappings":"AAEA;EAEI,cAAA;AAFJ;AAAA;EAMI,cAAA;AAHJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.search-input-filter {\n  .ant-input-prefix {\n    color: @brand;\n  }\n\n  ::placeholder {\n    color: @brand-70;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
