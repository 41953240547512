import React, { useState } from "react";

import { Project } from "@src/types";
import { Button, Card, Col, Row, Typography } from "antd";
import dayjs from "dayjs";

import { DeleteProjectConfirmationModal } from "../../../../components/modals/delete_project_confirmation_modal";
import { EditProjectModal } from "../edit_project_modal";

import "./project_details_card.less";
import { NavLink, useHistory } from "react-router-dom";

interface ProjectDetailsCardProps {
  project: Project;
}

const ProjectDetailsCard: React.FC<ProjectDetailsCardProps> = ({ project }) => {
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [showDeleteProjectModal, setShowDeleteProjectModal] =
    useState<boolean>(false);

  const { Paragraph } = Typography;

  function onDeleteCallback() {
    history.push(`/projects`);
  }

  return (
    <Card className="pageSection">
      <Row gutter={[48, 16]}>
        <Col sm={12}>
          <h2 data-testid="projectNameLabel" className="project-name-label">
            {project.name}
          </h2>
          <Paragraph>
            <label>Project manager:</label>
            <i> {project.projectManager.fullName}</i>
          </Paragraph>
          <Paragraph data-testid="countryLabel">
            <label>Country:</label>
            <i> {project.country}</i>
          </Paragraph>
          <Paragraph data-testid="projectPhaseLabel">
            <label>Project phase:</label>
            <i> {project.projectPhase}</i>
          </Paragraph>
          <Paragraph data-testid="stageGateLabel">
            <label>Next stage gate date (estimated):</label>
            <i>
              {" "}
              {project.stageGateDate
                ? dayjs(project.stageGateDate).format("Do MMMM YYYY")
                : "No stage gate date set"}
            </i>
          </Paragraph>
          <Paragraph>
            <label>Project time:</label>
            <i>
              {" "}
              {dayjs(project.startDate).format("Do MMMM YYYY") +
                " - " +
                dayjs(project.endDate).format("Do MMMM YYYY")}
            </i>
          </Paragraph>
          <Paragraph data-testid="projectTechnologyLabel">
            <label>Technology:</label>
            <i>
              {" "}
              {project.technologies.length > 0
                ? project.technologies.join(", ")
                : "No entry"}
            </i>
          </Paragraph>
          <Paragraph className="last-edit">
            <label>Last edit:</label>
            <i> {dayjs(project.lastEdit).format("Do MMMM YYYY")}</i>
          </Paragraph>
        </Col>
        <Col sm={12} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            size="large"
            onClick={() => setModalVisible(true)}
          >
            Edit project details
          </Button>
          {
            <EditProjectModal
              modalVisible={modalVisible}
              project={project}
              setModalVisible={setModalVisible}
            />
          }
        </Col>
      </Row>
      <div className="button-section">
        <NavLink to={`/projects/create/${project.id}`}>
          <Button
            type="primary"
            size="large"
            className="duplicate-project-button"
            data-testid="duplicate-project-button"
          >
            Duplicate project
          </Button>
        </NavLink>
        <Button
          type="primary"
          size="large"
          danger
          onClick={() => setShowDeleteProjectModal(true)}
          data-testid="deleteProjectDetailsBtn"
        >
          Delete project
        </Button>
      </div>

      {showDeleteProjectModal && (
        <DeleteProjectConfirmationModal
          setModalVisible={setShowDeleteProjectModal}
          modalVisible={showDeleteProjectModal}
          projectId={project.id}
          onDeleteCallback={onDeleteCallback}
        />
      )}
    </Card>
  );
};

export default ProjectDetailsCard;
