import React from "react";

import { CustomCellRendererProps } from "ag-grid-react";
import { Radio } from "antd";

interface RadioButtonRendererProps {
  params: CustomCellRendererProps;
}

const RadioButtonRenderer: React.FC<RadioButtonRendererProps> = ({
  params,
}) => {
  function handleChange() {
    params.api.deselectAll();

    params.node.setSelected(true);

    params.api.refreshCells({ force: true }); // needed to show that the radio button is clicked
  }

  return (
    <Radio
      type="radio"
      name="rowSelection"
      data-testid="radio-button"
      checked={params.node.isSelected()}
      onClick={handleChange}
    />
  );
};

export default RadioButtonRenderer;
