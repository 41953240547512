import React, { useState } from "react";

import "./suggest_workload.less";

import { NoteForm } from "@src/components/note/note_form";
import { ROUTES } from "@src/constants";
import { Events } from "@src/events";
import {
  useSuggestDifferentWorkloadMutation,
  useTeamLeadDeclineRequestMutation,
} from "@src/services/slices/teamLeadsApi";
import {
  ModalMsg,
  RequestedAllocations,
  SuggestWorkloadDetails,
  TeamMemberCapacity,
} from "@src/types";
import { getErrorMessage } from "@src/utils/helper";
import { message, Modal } from "antd";
import _ from "lodash";
import PubSub from "pubsub-js";

import { ProposeWorkload } from "../propose_workload";
import { useHistory } from "react-router-dom";

interface SuggestWorkloadProps {
  id: number;
  selectedMemberId: number;
  setModalVisible: (modalVisible: boolean) => void;
  modalVisible: boolean;
  selectedMemberCapacity: TeamMemberCapacity;
  requestedAllocations: RequestedAllocations;
  modalMsg: ModalMsg;
  variant: string;
}

const SuggestWorkload: React.FC<SuggestWorkloadProps> = ({
  id,
  selectedMemberId,
  setModalVisible,
  modalVisible,
  selectedMemberCapacity,
  requestedAllocations,
  modalMsg,
  variant,
}) => {
  const history = useHistory();
  const [validateSuggestWorkload, setValidateSuggestWorkload] =
    useState<SuggestWorkloadDetails>({
      hasSuggested: false,
      payload: [],
    });

  const [isActionButtonDisabled, setIsActionButtonDisabled] =
    useState<boolean>(true);

  const [suggestDifferentWorkload] = useSuggestDifferentWorkloadMutation();
  const [declineRequest] = useTeamLeadDeclineRequestMutation();

  const updateRequest = async (): Promise<void> => {
    if (variant === "propose") {
      suggestDifferentWorkload({
        id: id,
        selectedMemberId: selectedMemberId,
        requestAllocationPayload: validateSuggestWorkload.payload,
      })
        .unwrap()
        .then(() => {
          onUpdateSuccess();
        })
        .catch(() => {
          onUpdateError();
        });
    } else {
      const payload = _.isEmpty(validateSuggestWorkload.payload)
        ? []
        : validateSuggestWorkload.payload;

      declineRequest({
        payLoad: { id: id, requestAllocationPayload: payload },
        params: { declineRequestType: "propose" },
      })
        .unwrap()
        .then(() => {
          onUpdateSuccess();
        })
        .catch(() => {
          onUpdateError();
        });
    }
  };

  function onUpdateSuccess(): void {
    history.push(ROUTES.TEAM_LEAD.STAFFING_REQUESTS);
    PubSub.publish(Events.TL_REQUEST_STATUS_UPDATED);
    message.success({
      content:
        variant === "propose"
          ? "Team member proposed successfully"
          : "Request declined successfully",
    });
  }

  function onUpdateError(): void {
    setModalVisible(false);
    getErrorMessage(
      "Different workload is not saved, refresh page and try again!!"
    );
  }

  return (
    <div>
      <Modal
        className="suggest-workload"
        width="75%"
        open={modalVisible}
        title="Suggest different workload"
        maskClosable={false}
        closable={false}
        centered
        bodyStyle={{ width: "100%" }}
        onOk={updateRequest}
        onCancel={() => setModalVisible(false)}
        footer={""}
      >
        <ProposeWorkload
          allocationDetailsData={requestedAllocations}
          selectedMemberCapacity={_.cloneDeep(selectedMemberCapacity)}
          setValidateSuggestWorkload={setValidateSuggestWorkload}
          setIsActionButtonDisabled={setIsActionButtonDisabled}
          variant={variant}
        />
        <br />
        <span>{modalMsg.title}</span>
        <NoteForm
          setModalVisible={setModalVisible}
          action={updateRequest}
          requestId={id}
          modalMsg={modalMsg}
          isActionButtonDisabled={
            variant === "decline" ? false : isActionButtonDisabled
          }
          isNoteMandatory={variant === "decline"}
        />
      </Modal>
    </div>
  );
};

export default SuggestWorkload;
