import { SolutionType } from "@src/constants";
import { Employee, ProposeSolutionSettings } from "@src/types";
import dayjs from "dayjs";

export function getProposeSolutionSettings(): ProposeSolutionSettings[] {
  return [
    {
      type: SolutionType.PROPOSE_TEAM_MEMBER,
      label: "Propose team member(s)",
    },
    {
      type: SolutionType.VACANCY,
      disabled: true,
      label: "Select a vacancy which could cover the project",
      disabledTooltip: "No vacancies available",
    },
    {
      type: SolutionType.FORWARD,
      label: "Forward the request to another team lead",
    },
    {
      type: SolutionType.IN_RECRUITMENT,
      label: "Mark as 'in recruitment' for all viewers",
    },
    {
      type: SolutionType.DECLINE,
      label: "Decline",
    },
    {
      type: SolutionType.DECLINE_AND_PROPOSE,
      label: "Decline and suggest different workload",
    },
  ];
}

/**
 * Get the vacancy option label
 *
 * @param {Employee} selectedVacancy The selected vacancy
 */
export function getVacancyOptionLabel(selectedVacancy: Employee) {
  const positionStartDateString = selectedVacancy.positionStartDate
    ? dayjs(selectedVacancy.positionStartDate).format("DD/MM/YYYY") + " - "
    : "";
  const positionEndDateString = selectedVacancy.positionEndDate
    ? dayjs(selectedVacancy.positionEndDate).format("DD/MM/YYYY") + " - "
    : "";
  const positionText = `${selectedVacancy.positionText} (${selectedVacancy.deskId})`;
  const availabilityString = `${selectedVacancy.totalAvailablePercentage}%`;
  const contractType = selectedVacancy.contractType || "no contract type";

  return `${positionStartDateString}${positionEndDateString}${positionText} - ${availabilityString} - ${contractType}`;
}
