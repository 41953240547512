import {
  Allocation,
  AllocationRowData,
  ResourcePlanTableRowData,
  RoleRequest,
} from "@src/types/role_request_types";
import dayjs from "dayjs";

/**
 * Maps an array of RoleRequest objects to ResourcePlanTableRowData,
 * converting allocationDetails into a Map keyed by "month-year".
 *
 * @param roleRequestData - The array of role requests.
 * @returns An array of mapped resource plan table row data.
 */
export function mapRoleRequestData(
  roleRequestData: RoleRequest[]
): ResourcePlanTableRowData[] {
  if (!roleRequestData) return [];

  return roleRequestData?.map((roleRequest: RoleRequest) => {
    const allocationMap = new Map<string, AllocationRowData>();
    roleRequest.allocationDetails?.forEach((allocation: Allocation) => {
      const mapKey = dayjs(allocation.date).format("M-YYYY");

      allocationMap.set(mapKey, {
        percentage: allocation.requiredPercentage,
      });
    });

    const { allocationDetails, ...resourcePlanTableRequest } = roleRequest;
    return {
      ...resourcePlanTableRequest,
      allocations: allocationMap,
    } as ResourcePlanTableRowData;
  });
}
