import { Tooltip } from "antd";

import React from "react";

import "./teammember_project_renderer.less";
import useFeatureToggle from "@src/hooks/use_feature_toggle";
import {
  setDetailsModalVisible,
  setSelectedRequestId,
} from "@src/services/requestOverviewSlice";
import { useAppDispatch } from "@src/setupStore";

import * as constants from "../../../../constants";
import { ROUTES } from "../../../../constants";
import { useHistory } from "react-router-dom";

interface ProjectValue {
  fullName: string;
  genericRole: {
    genericRoleName: string;
  };
  projectName: string;
  specificRole?: string;
  projectRoleRequestId: number;
  status?: string;
}

interface TeamMemberProjectRendererProps {
  value: ProjectValue;
}

const TeamMemberProjectRenderer: React.FC<TeamMemberProjectRendererProps> = ({
  value,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isTLModalDetailsEnabled = useFeatureToggle("tlWindowedRequestDetails");
  const proposedClass =
    value.status === constants.REQUEST_STATUS.PROPOSED
      ? "project-name-proposed"
      : "project-name";

  const projectNameRenderer = (projectName: string): string => {
    return projectName.length > 50
      ? projectName.substring(0, 50) + "..."
      : projectName;
  };

  const handleClick = (roleRequestId: number): void => {
    if (!isTLModalDetailsEnabled) {
      history.push(
        `${ROUTES.TEAM_LEAD.STAFFING_REQUESTS}/suggestTeamMember/${roleRequestId}`
      );
    } else {
      dispatch(setDetailsModalVisible(true));
      dispatch(setSelectedRequestId(roleRequestId));
    }
  };

  return (
    <Tooltip
      title={`${value.projectName} ${
        value.specificRole ? ", " + value.specificRole : ""
      }`}
      data-testid="tooltip"
    >
      <div className="project-name-section">
        {value.projectName !== "Time Blocker" ? (
          <a
            onClick={() => handleClick(value.projectRoleRequestId)}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="link-to-project"
          >
            <span className={`${proposedClass}`} data-testid="project-name">
              {projectNameRenderer(value.projectName)}
            </span>
          </a>
        ) : (
          <span className="member-name" data-testid="time-blocker">
            {value.projectName}
          </span>
        )}

        {value.specificRole && (
          <>
            <span className="hyphen" data-testid="hyphen">
              -
            </span>
            <span className="role-text" data-testid="specific-role">
              {value.specificRole}
            </span>
          </>
        )}
      </div>
    </Tooltip>
  );
};

export default TeamMemberProjectRenderer;
