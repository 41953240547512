import React from "react";

import "./notification_bar.less";
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { NOTIFICATION_TYPE, REQUEST_STATUS } from "@src/constants";
import {
  useAcceptUpdatedRequestMutation,
  useGetTeamLeadRequestDetailsQuery,
  useRevokeUpdatedRequestMutation,
} from "@src/services/slices/teamLeadsApi";
import { Alert, Button, message, Space, Tooltip } from "antd";

type AlertConfig = {
  condition: boolean;
  message: React.ReactNode;
  type: NOTIFICATION_TYPE;
  action?: React.ReactNode;
};

interface NotificationBarProps {
  requestedRoleId: number;
}

const NotificationBar: React.FC<NotificationBarProps> = ({
  requestedRoleId,
}) => {
  const { data: request } = useGetTeamLeadRequestDetailsQuery(requestedRoleId, {
    skip: !requestedRoleId,
  });

  const [acceptUpdatedRequest] = useAcceptUpdatedRequestMutation();
  const [revokeUpdatedRequest] = useRevokeUpdatedRequestMutation();

  const isProposedRequestUpdated =
    (request?.status === REQUEST_STATUS.PROPOSED ||
      request?.status === REQUEST_STATUS.CONFIRMED) &&
    request?.childRequest?.status === REQUEST_STATUS.REQUESTED;

  // All possible alert configurations for the notification bar
  const alertConfigs: AlertConfig[] = [
    createAlertConfig(
      request?.isOutdated,
      "REQUEST OUTDATED",
      "This request is outdated, please get in contact with the project lead.",
      NOTIFICATION_TYPE.WARNING
    ),
    createAlertConfig(
      request?.isEmployeeDeleted,
      "EMPLOYEE DELETED",
      "The formerly assigned employee has been deleted, and the request was set back to the status requested.",
      NOTIFICATION_TYPE.INFO
    ),
    createAlertConfig(
      request?.isDeclined,
      "PROPOSAL DECLINED",
      "Please respond to this request again.",
      NOTIFICATION_TYPE.WARNING
    ),
    createAlertConfig(
      request?.isHandedOver,
      "REQUEST HANDED OVER",
      "This request has been handed over to you from another team lead.",
      NOTIFICATION_TYPE.INFO
    ),
    createAlertConfig(
      request?.isTlChangedOfAssignedEmployee,
      "REQUEST CHANGED",
      "The already confirmed team member assignment to this project request has been set back to the 'requested' status due to changes in team set-ups and team lead responsibilities.",
      NOTIFICATION_TYPE.INFO
    ),
    createAlertConfig(
      request?.childRequest && request?.isOnlyFreeTextFieldChanged,
      "REQUEST CHANGED",
      "The update has been automatically accepted.",
      NOTIFICATION_TYPE.INFO
    ),
    createAlertConfig(
      request?.childRequest && !request.isOnlyFreeTextFieldChanged,
      "REQUEST CHANGED",
      "This request has been changed. Please review the changes and accept them.",
      NOTIFICATION_TYPE.WARNING
    ),
    isProposedRequestUpdated && createProposalAlertConfig(),
  ];

  /**
   * Function to create an alert configuration object for the proposal alert.
   */
  function createProposalAlertConfig(): AlertConfig | null {
    const actionButtons: React.ReactNode = (
      <Space>
        <Tooltip
          placement="top"
          title="The new requested allocations will be confirmed"
        >
          <Button
            type="primary"
            size="small"
            icon={<CheckOutlined />}
            onClick={handleAcceptRequest}
          >
            YES
          </Button>
        </Tooltip>
        <Tooltip
          placement="top"
          title="The request would be changed to 'Requested'"
        >
          <Button
            danger
            type="primary"
            size="small"
            icon={<CloseOutlined />}
            onClick={handleRevokeRequest}
          >
            NO
          </Button>
        </Tooltip>
      </Space>
    );

    return createAlertConfig(
      true,
      "Project lead changed marked allocations",
      `Does your Proposal '${request?.assignedTeamMember}' still apply?`,
      NOTIFICATION_TYPE.WARNING,
      actionButtons
    );
  }

  /**
   * Function to create an alert configuration object.
   *
   * @param condition     The condition to check if the alert should be shown
   * @param title         The title of the alert
   * @param text          The text of the alert
   * @param type          The type of the alert
   * @param action        The action to perform when the alert is shown
   */
  function createAlertConfig(
    condition: boolean,
    title: string,
    text: string,
    type: NOTIFICATION_TYPE,
    action: React.ReactNode = null
  ): AlertConfig {
    return {
      condition,
      message: (
        <>
          <b>{title}</b> - {text}
        </>
      ),
      type,
      action,
    };
  }

  /**
   * Function to handle the request still applies. When a project lead updates a proposed request than the
   * team lead has to decide if the changed values still applies.
   */
  async function handleAcceptRequest() {
    try {
      await acceptUpdatedRequest({
        roleRequestId: request.projectRoleRequestId,
      }).unwrap();
    } catch (error) {
      console.error("Failed to accept the request", error);
    }
  }

  /**
   * Function to handle the request does not apply anymore. When a project lead updates a proposed request than the
   * team lead has to decide if the assigned employee to the new changed values still applies.
   */
  async function handleRevokeRequest() {
    try {
      await revokeUpdatedRequest({
        roleRequestId: request.projectRoleRequestId,
      }).unwrap();
      message.success("Request update revoked and set to requested state");
    } catch (error) {
      console.error("Failed to revoke the request", error);
    }
  }

  const shownAlerts: AlertConfig[] = alertConfigs?.filter(
    (alert: AlertConfig) => alert?.condition
  );

  function getNotification() {
    if (!request || !shownAlerts) return null;

    return (
      <div className="notification-bar">
        {shownAlerts.map((shownAlert: AlertConfig) => (
          <Alert
            icon={
              shownAlert.type === NOTIFICATION_TYPE.INFO ? (
                <InfoCircleOutlined />
              ) : (
                <WarningOutlined />
              )
            }
            message={shownAlert.message}
            type={shownAlert.type}
            action={shownAlert.action}
            showIcon
          />
        ))}
      </div>
    );
  }

  return getNotification();
};

export default NotificationBar;
