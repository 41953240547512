import React, { useCallback, useMemo, useRef } from "react";

import "./propose_team_member_overview_table.less";
import {
  getColumnDefs,
  getGridOptions,
} from "@src/features/staffing_request_details/utils/propose_team_member_overview_table_utils";
import {
  setConfirmationButtonDisableStatus,
  setProposedTeamMemberId,
} from "@src/services/nextStepModalSlice";
import { useGetTeamCapacitiesV2Query } from "@src/services/slices/teamLeadsApi";
import { RootState, useAppSelector } from "@src/setupStore";
import { RequestOverviewDetails } from "@src/types";
import { ProposeAllocationRowData } from "@src/types/role_request_types";
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  IRowNode,
  RowSelectedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";
import _ from "lodash";
import { useDispatch } from "react-redux";

import {
  getCapacityTopPinnedRow,
  mapCapacityToTeamMemberRowData,
} from "../../utils/propose_allocations_utils";

interface ProposeTeamMemberProps {
  request: RequestOverviewDetails;
  teamLeadId: number;
}

const ProposeTeamMemberOverviewTable: React.FC<ProposeTeamMemberProps> = ({
  request,
  teamLeadId,
}) => {
  const gridApi = useRef<GridApi | null>(null);
  const dispatch = useDispatch();

  const { proposeTeamMember } = useAppSelector(
    (state: RootState) => state.nextStepModalSlice
  );

  function formatDate(date: Date): string {
    return dayjs(date).format("YYYY-MM-DD");
  }

  const workloadEndDate: Date = _.last(request.roleAllocationDetails)?.date;

  const { data: teamMemberCapacities } = useGetTeamCapacitiesV2Query({
    teamLeadId: teamLeadId,
    startDate: formatDate(new Date()),
    endDate: formatDate(workloadEndDate),
  });

  const columnDefs = useMemo<ColDef[]>(() => {
    return getColumnDefs(workloadEndDate);
  }, [workloadEndDate]);

  const rowData = useMemo<ProposeAllocationRowData[]>(() => {
    return mapCapacityToTeamMemberRowData(teamMemberCapacities);
  }, [teamMemberCapacities]);

  const pinnedTopRow = useMemo<ProposeAllocationRowData>(() => {
    return getCapacityTopPinnedRow(request);
  }, [request, teamMemberCapacities]);

  /**
   * AG Grid onGridReady function
   * assigns the GridApi to a ref
   *
   * @param {GridReadyEvent} event AG Grid event params
   */
  const onGridReady = useCallback((event: GridReadyEvent) => {
    gridApi.current = event.api;
  }, []);

  /**
   * Callback function to handle the row selection event
   * It expands the newly selected user and collapses the previously selected user
   * @param {RowSelectedEvent} event AG Grid event params
   */
  function onRowSelected(event: RowSelectedEvent): void {
    if (proposeTeamMember.selectedTeamMemberId) {
      const previouslySelectedNode: IRowNode<ProposeAllocationRowData> =
        event.api.getRowNode(proposeTeamMember.selectedTeamMemberId.toString());

      previouslySelectedNode.setExpanded(false);
    }

    event.node.setExpanded(true, undefined, true);

    dispatch(setProposedTeamMemberId(event.data.employee?.employeeId));
    dispatch(setConfirmationButtonDisableStatus(false));
  }

  return (
    <div className="header-white propose-team-member-overview-table ag-theme-alpine">
      <AgGridReact
        rowData={rowData}
        gridOptions={getGridOptions()}
        onRowSelected={onRowSelected}
        onGridReady={onGridReady}
        columnDefs={columnDefs}
        pinnedTopRowData={[pinnedTopRow]}
      />
    </div>
  );
};

export default ProposeTeamMemberOverviewTable;
