// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-collapse-wrapper {
  width: 100%;
}
.filter-collapse-wrapper.ant-collapse {
  border: 0;
}
.filter-collapse-wrapper .ant-collapse-header {
  border-bottom: 1px solid #e8ecf1;
  padding-left: 0px !important;
}
.filter-collapse-wrapper .ant-collapse-header * {
  color: #1d4477 !important;
}
.filter-collapse-wrapper .ant-collapse-item {
  border-bottom: 0;
}
.filter-collapse-wrapper .ant-collapse-item-active .ant-collapse-arrow > svg {
  transform: rotate(-90deg) !important;
}
.filter-collapse-wrapper .ant-collapse-content {
  border: 0;
}
.filter-collapse-wrapper .ant-collapse-content .filter-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.filter-collapse-wrapper .ant-collapse-content .filter-panel__border {
  flex: 0 0 auto;
  margin-top: 2px;
}
.filter-collapse-wrapper .ant-collapse-content .filter-panel__content {
  flex: 1 1 auto;
  overflow-y: auto;
  margin-top: 10px;
  max-height: 181px !important;
}
.filter-collapse-wrapper .ant-collapse-content .filter-panel__content--static {
  max-height: unset !important;
}
.filter-collapse-wrapper .ant-tree-treenode {
  margin-bottom: 12px;
}
.filter-collapse-wrapper .ant-tree-switcher {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/features/table_filtering/components/filter_collapse_wrapper/filter_collapse_wrapper.less"],"names":[],"mappings":"AAEA;EACE,WAAA;AADF;AAGE;EACE,SAAA;AADJ;AAMM;EACE,gCAAA;EACA,4BAAA;AAJR;AAEM;EAKI,yBAAA;AAJV;AAQM;EACE,gBAAA;AANR;AAQQ;EAEI,oCAAA;AAPZ;AAYM;EACE,SAAA;AAVR;AASM;EAII,aAAA;EACA,sBAAA;EACA,8BAAA;AAVV;AAYU;EACE,cAAA;EACA,eAAA;AAVZ;AAaU;EACE,cAAA;EACA,gBAAA;EACA,gBAAA;EACA,4BAAA;AAXZ;AAcU;EACE,4BAAA;AAZZ;AAmBM;EACE,mBAAA;AAjBR;AAoBM;EACE,aAAA;AAlBR","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.filter-collapse-wrapper {\n  width: 100%;\n\n  &.ant-collapse {\n    border: 0;\n  }\n\n  .ant {\n    &-collapse {\n      &-header {\n        border-bottom: 1px solid @brand-10;\n        padding-left: 0px !important;\n\n        * {\n          color: @brand !important;\n        }\n      }\n\n      &-item {\n        border-bottom: 0;\n\n        &-active .ant-collapse-arrow {\n          > svg {\n            transform: rotate(-90deg) !important;\n          }\n        }\n      }\n\n      &-content {\n        border: 0;\n\n        .filter-panel {\n          display: flex;\n          flex-direction: column;\n          justify-content: space-between;\n\n          &__border {\n            flex: 0 0 auto;\n            margin-top: 2px;\n          }\n\n          &__content {\n            flex: 1 1 auto;\n            overflow-y: auto;\n            margin-top: 10px;\n            max-height: 181px !important;\n          }\n\n          &__content--static {\n            max-height: unset !important;\n          }\n        }\n      }\n    }\n\n    &-tree {\n      &-treenode {\n        margin-bottom: 12px;\n      }\n\n      &-switcher {\n        display: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
