import React, { useEffect } from "react";

import { PROJECT_FORM_VARIANT, ROUTES } from "@src/constants";
import ProjectForm from "@src/features/projects/components/project_form/project_form";
import { useLazyGetProjectQuery } from "@src/services/slices/projectsSlice";
import { useHistory, useParams } from "react-router-dom";

const CreateProjectPage = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [getProject, { data: project, isError: errorDuplicatingProject }] =
    useLazyGetProjectQuery();

  const variant = !id
    ? PROJECT_FORM_VARIANT.CREATE
    : PROJECT_FORM_VARIANT.DUPLICATE;

  useEffect(() => {
    if (variant === PROJECT_FORM_VARIANT.DUPLICATE) {
      getProject(id);
    }
  }, [id]);

  useEffect(() => {
    if (errorDuplicatingProject) {
      history.push("/");
    }
  }, [errorDuplicatingProject]);

  const onSave = () => {
    if (variant === PROJECT_FORM_VARIANT.DUPLICATE) {
      history.push(ROUTES.PROJECT_LEAD.PROJECTS);
    } else {
      history.goBack();
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  return (
    <ProjectForm
      project={project}
      onProjectSaved={onSave}
      onCancel={onCancel}
      variant={variant}
    />
  );
};

export default CreateProjectPage;
