import { AdminProjectOverview } from "@src/features/admin_project_assignments/components/admin_project_overview";

const AdminProjectAssignmentsPage = () => {
  return (
    <div className="project-assignments-container">
      <AdminProjectOverview />
    </div>
  );
};

export default AdminProjectAssignmentsPage;
