import React from "react";

import { Button, Form, Select } from "antd";
import _ from "lodash";
import "./sap_filter.less";

export default function SapFilter({
  tlAvailableFilter,
  fileIntegrationStatus,
  completeUploadResponse,
  setUploadResponse,
  gridApi,
}) {
  const [form] = Form.useForm();

  const onClearFilter = () => {
    if (tlAvailableFilter) {
      form.setFieldsValue({
        tlAvailableInDualis: tlAvailableFilter.map(
          (availablityFilter) => availablityFilter.code
        ),
      });
      form.setFieldsValue({ standardRole: fileIntegrationStatus });
      handleFilter();

      if (gridApi?.current) {
        gridApi.current.setFilterModel(null);
        gridApi.current.onFilterChanged();
      }
    }
  };

  const handleFilter = () => {
    let selectedAvailabilityFilter = form.getFieldValue("tlAvailableInDualis");

    if (!selectedAvailabilityFilter) {
      selectedAvailabilityFilter = tlAvailableFilter.map(
        (availablityFilter) => availablityFilter.code
      );
    } else if (selectedAvailabilityFilter.length === 0) {
      form.setFieldsValue({
        tlAvailableInDualis: tlAvailableFilter.map(
          (availablityFilter) => availablityFilter.code
        ),
      });
      selectedAvailabilityFilter = tlAvailableFilter.map(
        (availablityFilter) => availablityFilter.code
      );
    }

    const availabilityFiltered = _.clone(completeUploadResponse).filter(
      (cur) => {
        if (
          selectedAvailabilityFilter.length === 1 &&
          selectedAvailabilityFilter[0] === "tlAvailable"
        ) {
          return !cur.teamLeadNotInSAPFile;
        } else if (
          selectedAvailabilityFilter.length === 1 &&
          selectedAvailabilityFilter[0] === "tlNotAvailable"
        ) {
          return cur.teamLeadNotInSAPFile;
        }

        return true;
      }
    );

    setUploadResponse(availabilityFiltered);
  };

  return (
    <Form form={form} layout="vertical" size="large">
      <div className="form-inputs">
        <Form.Item
          name="tlAvailableInDualis"
          data-testid="form-item-label"
          label="Team lead availability filter"
          labelAlign="top"
          className="filter-input"
        >
          <Select
            placeholder="Filter by availability"
            data-testid="role-selection"
            showSearch
            mode="multiple"
            onChange={() => handleFilter()}
            style={{ width: 320 }}
            options={tlAvailableFilter?.map((status) => ({
              value: status.code,
              label: status.name,
            }))}
          />
        </Form.Item>
        <Button
          onClick={() => onClearFilter()}
          type="primary"
          data-testid="clear-filter-btn"
          className="clear-filter"
        >
          clear filter
        </Button>
      </div>
    </Form>
  );
}
