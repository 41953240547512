import { CellStyle, ColDef, GridOptions } from "ag-grid-community";

type ColumnType = {
  default: ColDef;
  cellWrapText: ColDef;
};

const cellWrapText: CellStyle = {
  lineHeight: "20px",
  whiteSpace: "normal",
};

export const columnTypes: ColumnType = {
  default: {
    editable: false,
    unSortIcon: true,
    suppressMovable: true,
    suppressHeaderMenuButton: true,
    suppressHeaderFilterButton: true,
  },
  cellWrapText: {
    cellStyle: cellWrapText,
  },
};

export const paginationGridOptions: Partial<GridOptions> = {
  pagination: true,
  paginationPageSize: 10,
  paginationPageSizeSelector: false,
};

export const groupHeaderStyleOptions: Partial<GridOptions> = {
  groupHeaderHeight: 26,
  headerHeight: 26,
};

/* 
this prevents the pinned columns from sliding into the center
there is also a column property called lockPinned but it only
helps with user interactions not when resizing the page
 */
export const preventPinnedColumnJumpingGridOption: Partial<GridOptions> = {
  processUnpinnedColumns: () => [],
};
