import { Button, Tooltip } from "antd";
import React from "react";
import { ReactComponent as FilterIcon } from "@src/assets/icons/filter.svg";

export interface FilterButtonProps {
  disabled?: boolean;
  setOpenFilterDrawer?: (open: boolean) => void;
}

const FilterButton: React.FC<FilterButtonProps> = ({
  disabled = false,
  setOpenFilterDrawer,
}) => {
  return (
    <Tooltip mouseEnterDelay={0.5} placement="top" title={"Filter"}>
      <Button
        variant="filled"
        color="default"
        icon={<FilterIcon />}
        disabled={disabled}
        size="large"
        data-testid="filter-drawer-button"
        onClick={() => setOpenFilterDrawer(true)}
      />
    </Tooltip>
  );
};

export default FilterButton;
