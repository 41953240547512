import React, { ReactNode, useEffect, useState } from "react";

import "./navigation.less";
import { ROLES, ROUTES } from "@src/constants";
import { clearFilter } from "@src/services/requestOverviewSlice";
import { useGetNotificationsCountQuery } from "@src/services/slices/layoutApi";
import { Employee, NotificationsCount, Route } from "@src/types";
import { Button, Tabs } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

interface NavigationProps {
  loggedInUser: Employee;
  setActiveRoute: (activeRoute: string) => void;
  activeRoute: string;
}

const Navigation = ({
  loggedInUser,
  setActiveRoute,
  activeRoute,
}: NavigationProps) => {
  const history = useHistory();
  const [activeKey, setActiveKey] = useState<string>("1");
  const useAppDispatch = useDispatch();

  const location = window.location;

  const { data: actionRequiredCount = [] } = useGetNotificationsCountQuery(
    null,
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    preselectNavigationAfterInit();
  }, [location.pathname]);

  const preselectNavigationAfterInit = async (): Promise<void> => {
    const currentRouteToActivate = async (foundRoute: Route): Promise<void> => {
      let routeToActivate: Route;

      if (foundRoute?.childItems?.length > 0) {
        routeToActivate = foundRoute.childItems.find(
          (childRoute) => childRoute.route === location.pathname
        );
      }

      if (routeToActivate)
        await activeRouteUpdate(routeToActivate.title, routeToActivate.route);
    };

    if (location.pathname === "/") {
      const rolesOfUser: string = loggedInUser.applicationRoles[0];
      const foundRoute: Route = routes.find((route) =>
        route.appRoles.find((role) => rolesOfUser === role)
      );

      await currentRouteToActivate(foundRoute);

      setActiveKey("1");
    } else {
      for (const [index, route] of routes.entries()) {
        const isCurrentRoute: boolean = location.pathname.includes(
          route.rootRoute
        );

        if (!isCurrentRoute) continue;

        await currentRouteToActivate(route);
        //unfortunately the active route hast to be a string
        setActiveKey((index + 1).toString());
      }
    }
  };

  const routes: Route[] = [
    {
      title: "MY PROJECTS",
      rootRoute: "projects",
      disabled: false,
      appRoles: [ROLES.PROJECT_LEAD],
      route: ROUTES.PROJECT_LEAD.PROJECTS,
    },
    {
      title: "DASHBOARD",
      rootRoute: "dashboard",
      disabled: false,
      appRoles: [ROLES.MANAGER],
      route: ROUTES.MANAGER.DASHBOARD,
    },
    {
      title: "STAFFING",
      rootRoute: "staffing",
      disabled: false,
      appRoles: [ROLES.TEAM_LEAD, ROLES.DEPUTY],
      notificationName: "TeamLeadRequestNotificationCount",
      childItems: [
        {
          title: "Requests",
          disabled: false,
          route: ROUTES.TEAM_LEAD.STAFFING_REQUESTS,
        },
        {
          title: "My Team",
          disabled: false,
          route: ROUTES.TEAM_LEAD.TEAM_OVERVIEW,
        },
      ],
    },
    {
      title: "ADMIN",
      rootRoute: "admin",
      disabled: false,
      appRoles: [ROLES.ADMIN],
      childItems: [
        {
          title: "Employee Data",
          disabled: false,
          route: ROUTES.ADMIN.EMPLOYEE_DATA,
        },
        {
          title: "Project Assignment",
          disabled: false,
          route: ROUTES.ADMIN.PROJECT_ASSIGNMENTS,
        },
        {
          title: "Role Assignment",
          disabled: false,
          route: ROUTES.ADMIN.ROLE_ASSIGNMENT,
        },
        {
          title: "Role Assignment v2",
          disabled: false,
          route: ROUTES.ADMIN.ROLE_ASSIGNMENT_V2,
        },
        {
          title: "Region Assignment",
          disabled: false,
          route: ROUTES.ADMIN.REGION_ASSIGNMENT,
        },
        {
          title: "SAP",
          disabled: false,
          route: ROUTES.ADMIN.SAP,
        },
      ],
    },
  ];

  const createNavLabel = (
    notificationName: string,
    title: string
  ): ReactNode => {
    //if no notifications available for a tab then return only title
    if (!notificationName) return title;

    //check if notifications are available
    const countItem: NotificationsCount =
      actionRequiredCount?.length > 0 &&
      actionRequiredCount?.find((a) => a.pageTitle === notificationName);

    if (countItem?.notificationCount === 0) return title;

    return (
      <>
        {title}
        <span className="notificationCount" data-testid="notification-count">
          {countItem?.notificationCount}
        </span>
      </>
    );
  };

  const activeRouteUpdate = async (
    navigationTitle: string,
    route: string
  ): Promise<void> => {
    setActiveRoute(navigationTitle);
    await history.push(route);
  };

  const mainNavigation = async (tabIndex: string): Promise<void> => {
    const foundRoute: Route = routes[parseInt(tabIndex) - 1];
    const hasChildLinks = !!foundRoute?.childItems;

    // clear filter if the user navigates to a different tab than staffing because the filter is only relevant
    // for the staffing tab and is confusing for the team lead when the filter stays because now there a no possibilities
    // to show that there is an active filter. When the new filter is implemented then the filter can stay.
    if (foundRoute.title !== "STAFFING") {
      useAppDispatch(clearFilter());
    }

    if (!hasChildLinks) {
      setActiveRoute(null);
      await history.push(foundRoute.route);
    }
  };

  const hasUserRole = (neededRoles: string[]): boolean => {
    return loggedInUser.applicationRoles.some(
      (role) => !!neededRoles.find((neededRole) => neededRole === role)
    );
  };

  const onKeyChange = (key: string): void => {
    setActiveKey(key);
  };

  return (
    <div className="main-navigation">
      <div className="navigation">
        <Tabs
          defaultActiveKey="1"
          className="tabs-navigation"
          data-testid="tabs-navigation"
          centered={true}
          onTabClick={mainNavigation}
          onChange={onKeyChange}
          activeKey={activeKey}
        >
          {routes.map((link, index) => {
            return (
              hasUserRole(link.appRoles) && (
                <Tabs.TabPane
                  key={index + 1}
                  tab={createNavLabel(link?.notificationName, link.title)}
                >
                  {link?.childItems && (
                    <ul className="list">
                      {link?.childItems.map((child) => {
                        return (
                          <li
                            key={child.route}
                            data-testid="subnavigation-item"
                          >
                            <Button
                              type={
                                activeRoute === child.title ? "primary" : "text"
                              }
                              onClick={() =>
                                activeRouteUpdate(child.title, child.route)
                              }
                              disabled={child.disabled}
                            >
                              {child.title}
                            </Button>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </Tabs.TabPane>
              )
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default Navigation;
