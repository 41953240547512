import React, { useEffect, useLayoutEffect, useState } from "react";

import DeleteFilled from "@ant-design/icons/lib/icons/DeleteFilled";
import EditFilled from "@ant-design/icons/lib/icons/EditFilled";
import { StaffingRequestDetails } from "@src/features/staffing_request_details/components/staffing_request_details";
import { ReactComponent as SettingsIcon } from "@src/assets/icons/settings.svg";
import { Button, Form, InputNumber, Table } from "antd";
import _ from "lodash";
import moment from "moment";
import "./team_member_overview_page.less";
import Cookies from "universal-cookie";

import { columnsSettings, modalMessage } from "./utils/team_member_page_utils";
import { Headline } from "../../components/headline";
import { ConfirmationModal } from "../../components/modals/confirmation_modal";
import * as constants from "../../constants";
import { months, ROLES } from "../../constants";
import {
  DeputyModal,
  InEditTimeBlockerActions,
  TeammemberFilter,
} from "../../features/teams/components";
import CheckUserRoleService from "../../services/checkUserRole";
import { useLazyGetDeputyStandardRolesQuery } from "../../services/slices/deputiesApi";
import {
  useDeleteTimeBlockerMutation,
  useLazyGetTeamAllocationsQuery,
  useLazyGetTeamLeadsStandardRolesQuery,
} from "../../services/slices/teamLeadsApi";
import { useHistory } from "react-router-dom";

const cookies = new Cookies();

const TeamMemberOverviewPage = function () {
  const history = useHistory();
  const [form] = Form.useForm();

  const [teamMembers, setTeamMembers] = useState([]);
  const [allTeamMembers, setAllTeamMembers] = useState([]);
  const [teamMembersWithoutVacancyAndTl, setTeamMembersWithoutVacancyAndTl] =
    useState([]);
  const [yearColumns, setYearColumns] = useState([]);
  const [startMonthIndex, setStartMonthIndex] = useState(0);
  const [displayColumnCount, setDisplayColumnCount] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [gridHeight, setGridHeight] = useState(200);
  const [gridVisibility, setGridVisibility] = useState(false);
  const [timeBlockerInEdit, setTimeBlockerInEdit] = useState(0);
  const [formValues, setFormValues] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [timeBlockerToDelete, setTimeBlockerToDelete] = useState();
  const [deputyModalVisible, setDeputyModalVisible] = useState(false);
  const [allStandardRolesObject, setAllStandardRolesObject] = useState([]);
  const [isDeputy, setIsDeputy] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [expandKeys, setExpandKeys] = useState([]);
  const [
    employeesResponsibleStandardRoles,
    setEmployeesResponsibleStandardRoles,
  ] = useState([]);

  const [deleteTimeBlocker] = useDeleteTimeBlockerMutation();

  const [
    getTeamLeadAllocation,
    { data: teamLeadAllocations, isSuccess: isSuccessTeamLeadAllocation },
  ] = useLazyGetTeamAllocationsQuery();

  const [getTeamLeadStandardRoles] = useLazyGetTeamLeadsStandardRolesQuery();
  const [getDeputyStandardRoles] = useLazyGetDeputyStandardRolesQuery();

  const columns = setColumnSettings();

  useLayoutEffect(() => {
    // eslint-disable-next-line no-undef
    window.addEventListener("resize", setTableSize);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener("resize", setTableSize);
  }, []);

  useEffect(() => {
    const isRequestedPathMatchingRole = CheckUserRoleService.checkRole(history);

    const loggedInUser = cookies.get("loggedInuser");
    setLoggedInUser(loggedInUser);

    initializeStandardRoles(loggedInUser);
    checkIfEmployeeIsDeputy(loggedInUser);

    if (isRequestedPathMatchingRole) {
      getTeamLeadAllocation();
    } else {
      history.push(`/`);
    }
  }, []);

  // will update filter when a new role was assigned to a team member so that the role will be now selectable in the filter
  useEffect(() => {
    updateFilter(allTeamMembers);
  }, [allTeamMembers]);

  useEffect(() => {
    if (isSuccessTeamLeadAllocation && teamLeadAllocations && loggedInUser) {
      const teamMembers = addTimeBlockerRowToTeamMembers(teamLeadAllocations);

      const teamMembersWithoutVacancyAndTl = _.clone(teamMembers).filter(
        (member) =>
          member.employeeId !== null &&
          !member.fullName.includes("Vacancy") &&
          member.employeeId !== loggedInUser.employeeId
      );

      teamMembers.forEach((member) => {
        member.numberOfProposedProjects = member.projects.filter(
          (project) => project.status === constants.REQUEST_STATUS.PROPOSED
        ).length;
      });

      setTeamMembers(teamMembers);
      setTeamMembersWithoutVacancyAndTl(teamMembersWithoutVacancyAndTl);
      setAllTeamMembers(_.clone(teamMembers));
      updateFilter(teamMembers);
      setTableSize();
    }
  }, [isSuccessTeamLeadAllocation, teamLeadAllocations, loggedInUser]);

  useEffect(() => {
    const prepareYearMonthColumns = (startIndex, numberOfMonths) => {
      let yearColumnsLocal = [];

      for (let m = startIndex; m < startIndex + numberOfMonths; m++) {
        let currentYear = moment().add(m, "months").year();
        let currentMonth = moment().add(m, "months").month();

        yearColumnsLocal.push({
          title: (
            <div className="monthHeader">
              <span className="month">{months[currentMonth]}</span>
              <span className="year">{currentYear}</span>
            </div>
          ),
          key: `${currentYear}_${months[currentMonth]}`,
          width: 70,
          editable: true,
          render: (value, record, index) =>
            monthRenderer(index, value, currentYear, currentMonth),
        });
      }

      return yearColumnsLocal;
    };

    const yearMonthColumns = prepareYearMonthColumns(
      startMonthIndex,
      displayColumnCount
    );

    setYearColumns(yearMonthColumns);
    setIsDataLoaded(true);
  }, [teamMembers, startMonthIndex, displayColumnCount]);

  function updateFilter(teamMembers) {
    const allStandardRoleObjects = teamMembers
      .map((member) => ({
        genericRoleId: member.genericRoleId,
        genericRoleName: member.genericRoleName,
      }))
      .filter((member) => member.genericRoleId !== 0);

    const uniqueStandardRoleObjects = _.uniqBy(
      allStandardRoleObjects,
      (standardRoles) => {
        return standardRoles.genericRoleId;
      }
    );

    setAllStandardRolesObject([...uniqueStandardRoleObjects]);
  }

  function initializeStandardRoles(loggedInUser) {
    if (loggedInUser?.applicationRoles?.includes(ROLES.DEPUTY)) {
      getDeputyStandardRoles(loggedInUser.employeeId)
        .unwrap()
        .then((data) => {
          setEmployeesResponsibleStandardRoles((responsibleRoles) => [
            ...responsibleRoles,
            ...data,
          ]);
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});
    }

    if (loggedInUser?.applicationRoles?.includes(ROLES.TEAM_LEAD)) {
      getTeamLeadStandardRoles(loggedInUser.employeeId)
        .unwrap()
        .then((data) => {
          setEmployeesResponsibleStandardRoles((responsibleRoles) => [
            ...responsibleRoles,
            ...data,
          ]);
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});
    }
  }

  function setColumnSettings() {
    const columnProps = {
      timeBlockerInEdit: timeBlockerInEdit,
      addTimeBlocker: addTimeBlocker,
      loggedInUser: loggedInUser,
      startMonthIndex: startMonthIndex,
      navigateMonthBack: navigateMonthBack,
      yearColumns: yearColumns,
      navigateMonthForward: navigateMonthForward,
      displayColumnCount: displayColumnCount,
      expandKeys: expandKeys,
    };

    return columnsSettings(
      columnProps,
      onTableRowExpand,
      showTimeBlockerInEditButtons,
      showTimeBlockerNotInEditButtons,
      employeesResponsibleStandardRoles,
      allTeamMembers,
      setAllTeamMembers
    );
  }

  function navigateMonthForward() {
    setStartMonthIndex((x) => x + 1);
  }

  function navigateMonthBack() {
    setStartMonthIndex((x) => x - 1);
  }

  function addTimeBlockerRowToTeamMembers(teamLeadAllocations) {
    const teamMembersWithProjects = [];

    teamLeadAllocations.forEach((member) => {
      const clonedMember = _.cloneDeep(member);

      const createdProject = {
        vacancy: clonedMember.vaccancy,
        addTimeBlocker: true,
        allocations: [],
        teamMemberId: clonedMember?.employeeId,
        genericRoleName: clonedMember?.genericRoleName,
      };

      clonedMember.projects.push(createdProject);
      teamMembersWithProjects.push(clonedMember);
    });

    return teamMembersWithProjects;
  }

  function setTableSize() {
    // eslint-disable-next-line no-undef
    const colCount = Math.floor((window.innerWidth - 690) / 100);
    setDisplayColumnCount(colCount > 0 ? colCount : 1);
    // eslint-disable-next-line no-undef
    setGridHeight(Math.floor(window.innerHeight - 330));
    setGridVisibility(true);
  }

  const checkIfEmployeeIsDeputy = (loggedInUser) => {
    if (!loggedInUser) return;

    const isLoggedInUserDeputy = !!loggedInUser.applicationRoles.find(
      (ar) => ar === ROLES.DEPUTY
    );

    setIsDeputy(isLoggedInUserDeputy);
  };

  const monthRenderer = (index, value, currentYear, currentMonth) => {
    const key = `${currentYear}_${months[currentMonth]}`;

    if (value.isInEdit) {
      const currentMonthValue = form.getFieldValue(key);

      return (
        <Form.Item name={key}>
          <InputNumber
            className={!currentMonthValue ? "zero-allocation" : ""}
            min={0}
            max={100}
            data-testid={"month-" + index}
            formatter={(value) => (value ? `${value}%` : "0%")}
            parser={(value) => value.replace("%", "")}
          />
        </Form.Item>
      );
    }

    if (value.projects) {
      return displayTotalAllocations(value, currentYear, currentMonth);
    } else {
      return displayProjectAllocations(value, currentYear, currentMonth);
    }
  };

  function displayProjectAllocations(value, currentYear, currentMonth) {
    let currentMonthAllocations = value.allocations.filter(
      (x) => x.year === currentYear && x.month - 1 === currentMonth
    );

    let isProposed = value.status === constants.REQUEST_STATUS.PROPOSED;

    if (currentMonthAllocations.length) {
      let sumAllocations = currentMonthAllocations
        .map(
          (monthAllocation) =>
            monthAllocation.proposedPercentage ||
            monthAllocation.requiredPercentage
        )
        .reduce((a, b) => a + b);

      return (
        sumAllocations > 0 && (
          <div
            className={`allocation-cell ${
              isProposed
                ? "allocation-cell-proposed"
                : "allocation-cell-project"
            }`}
          >
            {sumAllocations + "%"}
          </div>
        )
      );
    }
  }

  function displayTotalAllocations(teamMember, currentYear, currentMonth) {
    let allAllocations = teamMember.projects.map(
      (project) => project.allocations
    );

    if (!allAllocations.length) return;

    //start month allocation from current month and year. Old allocations in the past should not be visible
    let currentMonthAllocations = allAllocations
      .reduce((q, w) => (Array.isArray(q) ? q.concat(w) : [q].concat(w)))
      .filter((x) => x.year === currentYear && x.month - 1 === currentMonth);

    if (currentMonthAllocations.length) {
      let sumAllocations = currentMonthAllocations
        .map((x) => x.proposedPercentage || x.requiredPercentage)
        .reduce((a, b) => a + b);

      let allocation;

      //check if total allocation is less than 120% of total availability
      const isNormalAllocation =
        sumAllocations < teamMember.totalAvailabilityPercentage * 1.2;

      if (sumAllocations > 0 && isNormalAllocation) {
        allocation = calculateSegment(
          sumAllocations,
          teamMember.totalAvailabilityPercentage
        );
      } else if (sumAllocations > 0 && !isNormalAllocation) {
        allocation = "allocation-cell-over-allocation";
      } else {
        allocation = "allocation-cell-no-allocation";
      }

      return (
        <div className={`allocation-cell ${allocation}`}>
          {sumAllocations + "%"}
        </div>
      );
    }
  }

  function calculateSegment(givenPercentage, totalAvailabilityPercentage) {
    if (givenPercentage <= (totalAvailabilityPercentage * 2) / 5) {
      return "normal-segment";
    } else if (givenPercentage <= (totalAvailabilityPercentage * 3) / 5) {
      return "medium-segment";
    } else if (givenPercentage <= (totalAvailabilityPercentage * 4) / 5) {
      return "high-segment";
    } else {
      return "max-segment";
    }
  }

  function addTimeBlocker(project) {
    const updatedTeamMembers = teamMembers.map((member) => {
      if (member.employeeId === project.teamMemberId) {
        member.projects.splice(-1, 0, project);
      }
      return member;
    });

    setTeamMembers(updatedTeamMembers);
    setTimeBlockerInEdit(-1);
  }

  function onTableRowExpand(record) {
    let keys = [...expandKeys];

    const expanded = keys.includes(record.employeeId);

    if (!expanded) {
      keys.push(record.employeeId);
    } else {
      keys.splice(keys.indexOf(record.employeeId), 1);
    }

    setExpandKeys(keys);
  }

  const editTimeBlocker = async (record) => {
    record.isInEdit = true;
    record.isNewTimeBlocker = false;
    setTimeBlockerInEdit(record.projectRoleRequestId);

    const formValues = {
      "Team member": { isTouched: false, value: record.specificRole },
    };

    const initialValues = { "Team member": record.specificRole };

    record.allocations.forEach((allocation) => {
      let monthReference = `${allocation.year}_${months[allocation.month - 1]}`;
      initialValues[monthReference] = allocation.requiredPercentage;
      formValues[monthReference] = {
        isTouched: false,
        value: allocation.requiredPercentage,
      };
    });

    form.setFieldsValue(initialValues);
    setFormValues(formValues);

    forceTableRefresh(record);
  };

  const showModal = (record) => {
    setTimeBlockerToDelete(record);
    setModalVisible(true);
  };

  const resetTimeBlockerInEdit = (record) => {
    record.isInEdit = false;
    setTimeBlockerInEdit(0);
    form.resetFields();
    setFormValues({});
    forceTableRefresh(record);
  };

  const resetTimeBlocker = (record) => {
    resetTimeBlockerInEdit(record);

    if (!record.isNewTimeBlocker) return;

    const updatedTeamMembers = teamMembers.map((member) => {
      if (member.employeeId === record.teamMemberId) {
        member.projects.splice(-2, 1);
      }
      return member;
    });

    setTeamMembers(updatedTeamMembers);
  };

  // Andt table is somehow not able to refresh table when edit btn is clicked only when you update
  // a copy of the members that's why it is not enough only to set record.isInEdit you have also to create a copy of
  // team members and then update the copy
  const forceTableRefresh = (record) => {
    const newDataSource = [...teamMembers];

    for (const member of teamMembers) {
      let project = member.projects.find(
        (pr) => pr.projectRoleRequestId === record.projectRoleRequestId
      );

      if (!project) continue;

      project = record;
    }

    setTeamMembers(newDataSource);
  };

  const onDelete = (timeBlockerToDelete) => {
    deleteTimeBlocker(timeBlockerToDelete.projectRoleRequestId)
      .unwrap()
      .then(() => {
        const updatedTeamMembers = teamMembers.map((member) => {
          const recordIndexToBeDeleted = member.projects.findIndex(
            (project) =>
              project.projectRoleRequestId ===
              timeBlockerToDelete.projectRoleRequestId
          );

          if (recordIndexToBeDeleted !== -1) {
            member.projects.splice(recordIndexToBeDeleted, 1);
          }

          return member;
        });

        setTeamMembers(updatedTeamMembers);
        setModalVisible(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  };

  function showTimeBlockerNotInEditButtons(record) {
    return (
      <div className="edit-delete">
        <Button
          data-testid="delete-time-blocker"
          onClick={() => showModal(record)}
          icon={<DeleteFilled />}
        />
        <Button
          data-testid="edit-time-blocker"
          onClick={() => editTimeBlocker(record)}
          disabled={
            timeBlockerInEdit !== 0 &&
            timeBlockerInEdit !== record.projectRoleRequestId
          }
          icon={<EditFilled />}
        />

        {modalVisible && (
          <ConfirmationModal
            action={() => onDelete(timeBlockerToDelete)}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            modalMessage={modalMessage}
          />
        )}
      </div>
    );
  }

  function showTimeBlockerInEditButtons(record) {
    return (
      <InEditTimeBlockerActions
        resetTimeBlocker={resetTimeBlocker}
        record={record}
        formValues={formValues}
        resetTimeBlockerInEdit={resetTimeBlockerInEdit}
      />
    );
  }

  const captureFormChange = (changedValue, allValues) => {
    //capturing field touch explicitly, as the form can tell touched fields only for active cells
    const changedKey = Object.keys(changedValue)[0];
    let updatedValue = Object.values(changedValue)[0];

    updatedValue =
      changedKey !== ROLES.TEAM_MEMBER
        ? Math.floor(updatedValue)
        : updatedValue;

    allValues[changedKey] = updatedValue;
    form.setFieldsValue(allValues);
    formValues[changedKey] = { isFieldTouched: true, value: updatedValue };
    setFormValues({ ...formValues });
  };

  return (
    <>
      <div className="header-section">
        <Headline title="Team overview" />
        {!isDeputy && (
          <Button type="link" onClick={() => setDeputyModalVisible(true)}>
            Settings <SettingsIcon />
          </Button>
        )}

        {deputyModalVisible && (
          <DeputyModal
            modalVisible={deputyModalVisible}
            setModalVisible={setDeputyModalVisible}
            teamMembers={teamMembersWithoutVacancyAndTl}
            loggedInUser={loggedInUser}
          />
        )}
      </div>

      {isDataLoaded && gridVisibility && (
        <>
          <TeammemberFilter
            allTeamMembers={allTeamMembers}
            setTeamMembers={setTeamMembers}
            teamMembersWithoutVacancies={teamMembersWithoutVacancyAndTl}
            standardRoles={allStandardRolesObject}
          />

          <Form form={form} onValuesChange={captureFormChange}>
            <Table
              bordered
              rowKey="employeeId"
              className="teamOverviewTable"
              dataSource={teamMembers}
              columns={columns}
              rowClassName="editable-row"
              pagination={false}
              size="small"
              scroll={{ x: "max-content", y: gridHeight + "px" }}
              expandable={{
                expandIconColumnIndex: -1,
                childrenColumnName: "projects",
                expandedRowKeys: expandKeys,
              }}
              indentSize="150px"
            />
          </Form>
        </>
      )}
      <StaffingRequestDetails />
    </>
  );
};
export default TeamMemberOverviewPage;
