import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { HTTP_METHODS } from "@src/constants";
import { Employee } from "@src/types";
import {
  CreateRoleRequestPayload,
  RoleRequest,
  UpdateRoleRequestPayload,
} from "@src/types/role_request_types";
import { message } from "antd";

import { baseApiSlice } from "./baseApiSlice";

export const projectsApi = baseApiSlice.injectEndpoints({
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    /* GET endpoints */
    ...getEndpoints(builder),

    /* POST endpoints */
    ...postEndpoints(builder),

    /* PUT endpoints */
    ...putEndpoints(builder),
  }),
});

function getEndpoints(builder: EndpointBuilder<any, any, any>) {
  return {
    /**
     * Get role requests by project id
     */
    getRoleRequestsByProjectId: builder.query<RoleRequest[], string>({
      query: (projectId) => `/v2/projects/${projectId}/role-requests`,
      transformErrorResponse: async () => {
        message.error("Failed to get role requests by project id", 5);
      },
      providesTags: ["RoleRequests"],
    }),
  };
}

function postEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod = HTTP_METHODS.POST;

  return {
    /**
     * Save role request by project id
     */
    saveRequestV2: builder.mutation<
      RoleRequest,
      {
        projectId: string;
        request: CreateRoleRequestPayload;
      }
    >({
      query: ({ projectId, request }) => ({
        url: `/v2/projects/${projectId}/role-requests`,
        method: baseMethod,
        body: request,
      }),
      transformErrorResponse: async () => {
        message.error("Error saving role request for project", 5);
      },
    }),

    /**
     * Get project editors by project id
     */
    getProjectEditorsByProjectId: builder.query<Employee[], string>({
      query: (projectId) => `/v2/projects/${projectId}/editors`,
      transformErrorResponse: async () => {
        message.error("Failed to get project editors by project id", 5);
      },
      providesTags: ["ProjectEditors"],
    }),
  };
}

function putEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod = HTTP_METHODS.PUT;

  return {
    /**
     * Update a role request for a project
     * @param projectId - project identifier
     * @param roleRequestId - role request identifier
     * @param roleRequest - role request
     */
    updateRoleRequest: builder.mutation<
      RoleRequest,
      {
        projectId: string;
        roleRequestId: string;
        requestPayload: UpdateRoleRequestPayload;
      }
    >({
      query: ({ projectId, roleRequestId, requestPayload }) => ({
        url: `/v2/projects/${projectId}/role-requests/${roleRequestId}`,
        method: baseMethod,
        body: requestPayload,
      }),
      transformErrorResponse: async () => {
        message.error("Error updating project role request", 5);
      },
    }),

    updateProjectEditors: builder.mutation<
      void,
      { projectId: string; projectEditorIds: number[] }
    >({
      query: ({ projectId, projectEditorIds }) => ({
        url: `/v2/projects/${projectId}/editors`,
        method: baseMethod,
        body: projectEditorIds,
      }),
      transformErrorResponse: async () => {
        message.error("Error updating project editors", 5);
      },
      invalidatesTags: ["ProjectEditors"],
    }),
  };
}

export const {
  /* GET endpoints */
  useGetRoleRequestsByProjectIdQuery,
  useGetProjectEditorsByProjectIdQuery,

  /* POST endpoints */
  useSaveRequestV2Mutation,

  /* PUT endpoints */
  useUpdateRoleRequestMutation,
  useUpdateProjectEditorsMutation,
} = projectsApi;
