import { AG_GRID_PINNED_TYPE } from "@src/constants";
import {
  ProcessCellForExportParams,
  ProcessHeaderForExportParams,
} from "ag-grid-community/dist/types/core/interfaces/exportParams";
import { ExcelExportParams } from "ag-grid-community/dist/types/core/interfaces/iExcelCreator";
import dayjs from "dayjs";
import moment from "moment";

/**
 * Get the ag-grid parameters for the excel export
 *
 * @param {string[]} allocationDateKeys
 * @param {string[]} mainColumnKeys
 * @param {boolean} downloadFiltered
 * @param {string} projectName
 */
export function getAgGridParameter(
  allocationDateKeys: string[],
  mainColumnKeys: string[],
  downloadFiltered: boolean,
  projectName: string
): ExcelExportParams {
  return {
    columnKeys: [...mainColumnKeys, ...allocationDateKeys],
    sheetName: `Resource Plan ${validateSheetName(projectName)}`,
    fileName: getFileName(projectName),
    exportedRows: downloadFiltered ? "filteredAndSorted" : "all",
    skipColumnGroupHeaders: true,
    skipPinnedTop: true,
    processHeaderCallback: (params: ProcessHeaderForExportParams) => {
      const isAllocationHeaderField =
        params.column.getPinned() !== AG_GRID_PINNED_TYPE.LEFT;

      // if it is an allocation field, we need to format the header to show the month and year eg. Jan-21
      if (isAllocationHeaderField) {
        return dayjs(params.column.getColId(), "M-YYYY").format("MMM-YY");
      }

      // if it is not an allocation field, we need to get the display name of the column
      return params.api.getDisplayNameForColumn(params.column, null);
    },
    processCellCallback: (params: ProcessCellForExportParams) => {
      const isAllocationField =
        params.column.getPinned() !== AG_GRID_PINNED_TYPE.LEFT;

      if (isAllocationField && params?.value) {
        return parseInt(params.value) / 100;
      }

      return params.value;
    },
  };
}

/**
 * Validate the sheet name to make sure it does not contain any invalid characters
 *
 * @param {string} projectName
 */
function validateSheetName(projectName: string): string {
  //sheet name can not contain these characters \:?/*[] otherwise excel will have a problem exporting the file
  return projectName.replace(/[:?/*[\]]/g, " ");
}

/**
 * Get the file name for the excel file
 *
 * @param {string} projectName
 */
function getFileName(projectName: string): string {
  const fileName = `Project - Resource Planning - ${projectName} 
    - ${moment(new Date()).format("YYYY-MM-DD")}`;

  return fileName.trim().replace(/[^a-zA-Z0-9]+/g, "_");
}
