import React, { useState } from "react";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useGetBasicRolesQuery } from "@src/services/slices/basicRolesApi";
import { useGetTeamLeadsQuery } from "@src/services/slices/employeesSlice";
import { useGetRoleSpecificationsQuery } from "@src/services/slices/roleSpecificationsApi";
import { useGetStandardRolesQuery } from "@src/services/slices/standardRolesApi";
import {
  BasicRole,
  Employee,
  RoleSpecification,
  StandardRole,
  StandardRoleForm,
} from "@src/types";
import { Form, Input, Modal, Select, Switch } from "antd";
import "./standard_role_modal.less";

import { RoleSpecificationOption } from "../../utils/standard_role_assignment_grid_options";

interface StandardRoleModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  onSaveCallback?: (standardRole: StandardRole) => void;
  defaultStandardRole?: StandardRole;
}

const StandardRoleModal: React.FC<StandardRoleModalProps> = ({
  showModal,
  setShowModal,
  onSaveCallback,
  defaultStandardRole,
}) => {
  const { data: basicRoles } = useGetBasicRolesQuery();
  const { data: roleSpecifications } = useGetRoleSpecificationsQuery();
  const { data: standardRoles } = useGetStandardRolesQuery();
  const { data: teamLeads } = useGetTeamLeadsQuery();

  const [form] = Form.useForm<StandardRoleForm>();

  const [roleSpecificationOptions, setRoleSpecificationOptions] =
    useState<RoleSpecificationOption[]>(roleSpecifications);

  const [isBasicRoleSelected, setIsBasicRoleSelected] = useState<boolean>(
    !!defaultStandardRole?.basicRole
  );

  const initialValues: StandardRoleForm = defaultStandardRole && {
    basicRoleId: defaultStandardRole.basicRole.basicRoleId,
    roleSpecificationId:
      defaultStandardRole.roleSpecification?.roleSpecificationId,
    teamLeadId: defaultStandardRole.teamLead?.employeeId,
    description: defaultStandardRole.description,
    visibility: defaultStandardRole.onlyVisibleForAdmin,
  };

  /* -------- click events --------  */

  /**
   * click function of the save button. submits the form
   */
  function onSave() {
    form.submit();
  }

  /**
   * click function of the cancel button. make the modal disappear
   */
  function onCancel() {
    setShowModal(false);
  }

  /* -------- form events -------- */

  /**
   * onFinish function of the form.
   * will map the payload and call the onSaveCallback function
   */
  function onFinish() {
    const payload: Partial<StandardRole> = getPayload();
    onSaveCallback(payload as StandardRole);
  }

  /**
   * only necessary for basic role changes
   * checks if standards roles with the role specification already exist
   * sets the basic role state to enable the role specification selection
   *
   * @param {StandardRoleForm} changedValues contains FormItems that has changed
   */
  function onValuesChange(changedValues: StandardRoleForm) {
    const changedField = Object.keys(changedValues)[0];
    if (changedField !== "basicRoleId") return;
    checkForAlreadyAssignedRoleSpecifications(changedValues.basicRoleId);
    setIsBasicRoleSelected(true);
  }

  /* -------- helper functions -------- */

  /**
   * checks for all role specifications if standard roles with the given basicRole and
   * role specification exist.
   * if one exists, it will put a disable property to the role specification
   * * @param {number} basicRoleId id of the selected basic role
   */
  function checkForAlreadyAssignedRoleSpecifications(basicRoleId: number) {
    const newRoleSpecOptions: RoleSpecificationOption[] =
      roleSpecifications.map((roleSpecification: RoleSpecification) => {
        if (
          standardRoles.some(
            (standardRole: StandardRole) =>
              standardRole.basicRole.basicRoleId === basicRoleId &&
              standardRole.roleSpecification?.roleSpecificationId ===
                roleSpecification.roleSpecificationId
          )
        ) {
          return { ...roleSpecification, disabled: true };
        }
        return roleSpecification;
      });
    setRoleSpecificationOptions(newRoleSpecOptions);
  }

  /**
   * maps the FormItem values to the payload
   * @returns the payload
   */
  function getPayload(): Partial<StandardRole> {
    return {
      standardRoleId: defaultStandardRole?.standardRoleId,
      basicRole: basicRoles.find(
        (basicRole: BasicRole) =>
          form.getFieldValue("basicRoleId") === basicRole.basicRoleId
      ),
      roleSpecification: roleSpecifications.find(
        (roleSpecification: RoleSpecification) =>
          form.getFieldValue("roleSpecificationId") ===
          roleSpecification.roleSpecificationId
      ),
      teamLead: teamLeads.find(
        (teamLead: Employee) =>
          form.getFieldValue("teamLeadId") === teamLead.employeeId
      ),
      description: form.getFieldValue("description"),
      onlyVisibleForAdmin: form.getFieldValue("visibility"),
    };
  }

  return (
    <Modal
      open={showModal}
      closable={false}
      title={defaultStandardRole ? "Edit Role" : "Assign Role"}
      okText={defaultStandardRole ? "Save" : "Add Role"}
      okButtonProps={{ icon: <CheckOutlined />, size: "large" }}
      onOk={onSave}
      cancelButtonProps={{ icon: <CloseOutlined />, size: "large" }}
      onCancel={onCancel}
      width="470px"
      className="standard-role-modal"
      maskClosable={false}
    >
      <Form
        layout="vertical"
        initialValues={initialValues}
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name="basicRoleId"
          label="Basic Role"
          rules={[
            {
              required: true,
              message: "basic role required",
            },
          ]}
        >
          <Select
            placeholder="Select basic role"
            disabled={!!defaultStandardRole}
            showSearch
            optionFilterProp="label"
            options={basicRoles?.map((basicRole: BasicRole) => ({
              value: basicRole.basicRoleId,
              label: basicRole.basicRoleName,
            }))}
          />
        </Form.Item>

        <Form.Item name="roleSpecificationId" label="Specification">
          <Select
            data-testid="role-specification-selection"
            placeholder="Select role specification"
            disabled={!!defaultStandardRole || !isBasicRoleSelected}
            allowClear
            showSearch
            optionFilterProp="label"
            options={roleSpecificationOptions?.map(
              (roleSpecification: RoleSpecificationOption) => ({
                value: roleSpecification.roleSpecificationId,
                label: roleSpecification.roleSpecificationName,
                disabled: roleSpecification.disabled,
              })
            )}
          />
        </Form.Item>

        <Form.Item name="teamLeadId" label="Team Lead">
          <Select
            placeholder="Select team lead"
            showSearch
            optionFilterProp="label"
            options={teamLeads?.map((teamLead: Employee) => ({
              value: teamLead.employeeId,
              label: teamLead.fullName,
            }))}
          />
        </Form.Item>

        <Form.Item
          name="description"
          label="Role Description"
          className="standard-role-modal__description"
        >
          <Input.TextArea
            placeholder="Type description"
            style={{ resize: "none" }}
          />
        </Form.Item>

        <Form.Item
          name="visibility"
          valuePropName="checked"
          label="Lock this role (only admin can see it)"
          className="ant-form-item__label--right"
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StandardRoleModal;
