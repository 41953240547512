// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.requested-role-renderer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.requested-role-renderer__standard-role-wrapper {
  display: flex;
  justify-content: center;
}
.requested-role-renderer__standard-role {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.requested-role-renderer__updated-section {
  padding-top: 8px;
  width: 15px;
}
.requested-role-renderer__updated-dot {
  height: 8px;
  width: 8px;
  border: 1px solid #1d4477;
  border-radius: 50%;
  display: flex;
}
.requested-role-renderer__button {
  white-space: unset;
  text-align: left;
  padding: 0;
  height: 100%;
  font-size: 14px;
  font-family: RWESansWeb-Regular, sans-serif;
}
.requested-role-renderer__button--bold {
  font-family: RWESansWeb-Medium, sans-serif;
}
.requested-role-renderer__role-specification {
  font-size: 10px;
  color: #3b3d42;
  position: relative;
  top: -4px;
}
`, "",{"version":3,"sources":["webpack://./src/custom_renderer/requested_role_renderer/requested_role_renderer.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AADF;AAGE;EACE,aAAA;EACA,uBAAA;AADJ;AAIE;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;AAFJ;AAKE;EACE,gBAAA;EACA,WAAA;AAHJ;AAME;EACE,WAAA;EACA,UAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;AAJJ;AAOE;EACE,kBAAA;EACA,gBAAA;EACA,UAAA;EACA,YAAA;EACA,eAAA;EACA,2CAAA;AALJ;AAOI;EACE,0CAAA;AALN;AASE;EACE,eAAA;EACA,cAAA;EACA,kBAAA;EACA,SAAA;AAPJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.requested-role-renderer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  &__standard-role-wrapper {\n    display: flex;\n    justify-content: center;\n  }\n\n  &__standard-role {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n  }\n\n  &__updated-section {\n    padding-top: 8px;\n    width: 15px;\n  }\n\n  &__updated-dot {\n    height: 8px;\n    width: 8px;\n    border: 1px solid @brand;\n    border-radius: 50%;\n    display: flex;\n  }\n\n  &__button {\n    white-space: unset;\n    text-align: left;\n    padding: 0;\n    height: 100%;\n    font-size: 14px;\n    font-family: RWESansWeb-Regular, sans-serif;\n\n    &--bold {\n      font-family: RWESansWeb-Medium, sans-serif;\n    }\n  }\n\n  &__role-specification {\n    font-size: 10px;\n    color: @font-00;\n    position: relative;\n    top: -4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
