// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.standard-role-list .user-icon {
  width: 12px;
  height: 12px;
  fill: #52555c;
}
.standard-role-list .ant-list-item {
  padding: 15px 25px !important;
  border: 0;
}
.standard-role-list .ant-list-item:nth-child(odd) {
  background: #fafafa;
}
.standard-role-list .ant-list-item-meta-title {
  font-family: RWESansWeb-Medium;
  font-size: 14px !important;
  color: #1d4477 !important;
  margin: 0 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 230px;
}
.standard-role-list .ant-list-item-meta-description {
  font-size: 12px !important;
  color: #52555c !important;
}
`, "",{"version":3,"sources":["webpack://./src/features/resource_plan_v2/components/standard_role_list/standard_role_list.less"],"names":[],"mappings":"AAEA;EASI,WAAA;EACA,YAAA;EACA,aAAA;AATJ;AAFA;EAeI,6BAAA;EACA,SAAA;AAVJ;AAYI;EACE,mBAAA;AAVN;AAaI;EACE,8BAAA;EACA,0BAAA;EACA,yBAAA;EACA,oBAAA;EAxBF,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,YAAA;AAcJ;AAWI;EACE,0BAAA;EACA,yBAAA;AATN","sourcesContent":["@import \"@src/stylings/variables\";\n\n.standard-role-list {\n  .truncate-text() {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    width: 230px;\n  }\n\n  .user-icon {\n    width: 12px;\n    height: 12px;\n    fill: @font-01;\n  }\n\n  .ant-list-item {\n    padding: 15px 25px !important;\n    border: 0;\n\n    &:nth-child(odd) {\n      background: @sand-20;\n    }\n\n    &-meta-title {\n      font-family: RWESansWeb-Medium;\n      font-size: 14px !important;\n      color: @brand !important;\n      margin: 0 !important;\n      .truncate-text();\n    }\n\n    &-meta-description {\n      font-size: 12px !important;\n      color: @font-01 !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
