/* eslint-disable no-undef */

/**
 * this is basically the same code as in the baseQuery.js but
 * without the createFetchBaseQuery and with a createApi call.
 * when every api (slice) stops using create api and uses injectEndpoints instead,
 * we can remove the baseQuery.js file
 */

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Employee } from "@src/types";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const { REACT_APP_SSO_ENABLE } = process.env;

const baseUrl: string =
  window.location.hostname === "localhost"
    ? `http://localhost:8081/api`
    : `${window.location.origin}/api`;

function prepareHeaders(headers: Headers): Headers | void {
  return JSON.parse(REACT_APP_SSO_ENABLE) === true
    ? enabledSSO(headers)
    : disabledSSO(headers);
}

function disabledSSO(headers: Headers): Headers {
  const impersonationUser: string = cookies.get("ImpersonationUser");

  if (impersonationUser) {
    headers.set("HTTP2_HEADER_ACCESS_CONTROL_ALLOW_ORIGIN", "*");
    headers.set("ImpersonationUser", impersonationUser);
  }

  headers.set("Authorization", null);
  return headers;
}

function enabledSSO(headers: Headers) {
  const token: string = localStorage.getItem("token");

  if (token) {
    headers.set("Authorization", "Bearer " + token);
    headers.set("HTTP2_HEADER_ACCESS_CONTROL_ALLOW_ORIGIN", "*");

    const impersonationUser: string = cookies.get("ImpersonationUser");

    if (impersonationUser) {
      headers.set("ImpersonationUser", impersonationUser);
    } else {
      const empObj: Employee = cookies.get("loggedInuser");

      if (empObj) {
        cookies.set("ImpersonationUser", empObj.employeeId, {
          path: "/",
        });
        headers.set("ImpersonationUser", impersonationUser);
      }
    }
  }
}

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}`,
    prepareHeaders: (headers: Headers) => prepareHeaders(headers),
  }),
  endpoints: () => ({}),
  reducerPath: "baseApiSlice",
  tagTypes: [
    "BasicRoles",
    "RoleSpecifications",
    "StandardRoles",
    "Employees",
    "Requests",
    "AdminStandardRoles",
    "Countries",
    "Projects",
    "ProjectDetails",
    "Notifications",
    "RoleRequests",
    "ProjectEditors",
  ],
});
