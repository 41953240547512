import { useAuthentication } from "@src/auth/useAuthentication";
import { useGetEmployeeImpersonationsQuery } from "@src/services/slices/employeesSlice";
import { Employee } from "@src/types";
import { Select } from "antd";
import Cookies from "universal-cookie";
import "./start_screen_page.less";

const cookies = new Cookies();

const StartScreenPage = () => {
  const { data: empList } = useGetEmployeeImpersonationsQuery();
  const { isAuthenticating } = useAuthentication();

  const onSelectedMemberChange = (value: number) => {
    cookies.set("ImpersonationUser", value, { path: "/" });
    cookies.remove("loggedInuser", { path: "/" });
    window.location.reload();
  };

  if (isAuthenticating) return <h1>Loading...</h1>;

  return (
    <div className="start-screen-page">
      {empList?.length > 0 ? (
        <Select
          showSearch
          optionFilterProp="label"
          onChange={onSelectedMemberChange}
          data-testid="teamMember"
          placeholder="Select login to impersonate"
          options={empList.map((employee: Employee) => ({
            value: employee.employeeId,
            label: `${employee.fullName} | ${employee.applicationRoles[0]}`,
            "data-testid": employee.employeeId,
          }))}
        />
      ) : (
        <div>No team member found. Please enable sso login.</div>
      )}
      <hr />
    </div>
  );
};

export default StartScreenPage;
