import React, { ReactNode, useEffect, useState } from "react";

import {
  ArrowLeftOutlined,
  CalendarOutlined,
  MailOutlined,
} from "@ant-design/icons";
import * as constants from "@src/constants";
import ProjectDetailsCard from "@src/features/projects/components/project_details_card/project_details_card";
import { RequestReplies } from "@src/features/resource_plan/components/request_replies";
import { ResourcePlan } from "@src/features/resource_plan/components/resource_plan";
import CheckUserRoleService from "@src/services/checkUserRole";
import { clearFilters, setProjectId } from "@src/services/resourcePlanSlice";
import {
  useGetRequestsRepliesQuery,
  useLazyGetProjectQuery,
  useLazyGetRequestsQuery,
  useLazyGetRequestsRepliesQuery,
} from "@src/services/slices/projectsSlice";
import { RootState, useAppDispatch, useAppSelector } from "@src/setupStore";
import { RequestReplies as RequestRepliesType } from "@src/types";
import { Button, message, Tabs } from "antd";
import "./project_page.less";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";

const { TabPane } = Tabs;

interface ParentRequest {
  projectRoleRequestId: number;
}

/**
 * @deprecated this is the old project page and will be removed later
 */
const ProjectPage: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [requestReplies, setRequestReplies] =
    useState<RequestRepliesType[]>(null);
  const [totalActionNeeded, setTotalTotalActionNeeded] = useState<number>(0);

  const [fetchProject, { data: project }] = useLazyGetProjectQuery();
  const [refetchRequestsAndReplies] = useLazyGetRequestsRepliesQuery();
  const [refetchRequests] = useLazyGetRequestsQuery();

  const {
    data: requestRepliesObject,
    isSuccess,
    isError,
  } = useGetRequestsRepliesQuery(id);

  const dispatch = useAppDispatch();

  const { projectId } = useAppSelector(
    (state: RootState) => state.resourcePlanSlice
  );

  useEffect(() => {
    const isRequestedPathMatchingRole = CheckUserRoleService.checkRole(history);

    if (isRequestedPathMatchingRole) {
      fetchProject(id);
    }

    if (projectId !== id) {
      dispatch(clearFilters());
      dispatch(setProjectId(id));
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setRequestReplies(_.cloneDeep(requestRepliesObject));
      setRepliesDetails(_.cloneDeep(requestRepliesObject));
    }
  }, [isSuccess, requestRepliesObject]);

  useEffect(() => {
    if (isError) {
      message.error("Unable to get requests replies");
    }
  }, [isError]);

  function setRepliesDetails(requestRepliesParam: RequestRepliesType[]) {
    if (requestRepliesParam) {
      const sentRequests: RequestRepliesType[] =
        filterSentRequests(requestRepliesParam);

      setRequestReplies(sentRequests);

      const count: number = sentRequests.filter(
        (reply) =>
          reply.status === constants.REQUEST_STATUS.PROPOSED ||
          reply.status === constants.REQUEST_STATUS.DECLINED
      ).length;

      setTotalTotalActionNeeded(count);
    }
  }

  function getParentChildRequestsMap(
    requestRepliesParam: RequestRepliesType[]
  ): Map<number, ParentRequest> {
    const parentChildIdMap = new Map<number, ParentRequest>();

    requestRepliesParam.forEach((req) => {
      if (req.childId && req.projectRoleRequestId !== req.childId) {
        parentChildIdMap[req.childId] = {
          projectRoleRequestId: req.projectRoleRequestId,
        };
      }
    });
    return parentChildIdMap;
  }

  function filterSentRequests(
    requestRepliesParam: RequestRepliesType[]
  ): RequestRepliesType[] {
    const sentRequests: RequestRepliesType[] = [];
    const parentChildIdMap: Map<number, ParentRequest> =
      getParentChildRequestsMap(requestRepliesParam);

    requestRepliesParam.forEach((request: RequestRepliesType) => {
      if (
        !request.childId ||
        request.projectRoleRequestId === request.childId
      ) {
        request["parentRequestId"] =
          request.childId && parentChildIdMap[request.childId]
            ? parentChildIdMap[request.childId].projectRoleRequestId
            : request.projectRoleRequestId;
        sentRequests.push(request);
      }
    });
    return sentRequests;
  }

  let contentToDisplay: ReactNode = null;
  if (project && requestReplies) {
    contentToDisplay = (
      <>
        <ProjectDetailsCard project={project} />
        <Tabs
          type="card"
          size="large"
          onChange={(tabKey: string) => {
            if (tabKey === "2") {
              refetchRequestsAndReplies(id);
            } else {
              refetchRequests(id);
            }
          }}
        >
          <TabPane
            tab={
              <span>
                <CalendarOutlined />
                Resource plan
              </span>
            }
            key="1"
          >
            <ResourcePlan
              id={id}
              startDate={project.startDate}
              endDate={project.endDate}
              project={project}
            />
          </TabPane>
          <TabPane
            tab={
              <div>
                <span>
                  <MailOutlined />
                  Requests and replies
                </span>
                {totalActionNeeded > 0 && (
                  <span className="notificationCount">{totalActionNeeded}</span>
                )}
              </div>
            }
            key="2"
          >
            <RequestReplies
              startDate={project.startDate}
              endDate={project.endDate}
              requestReplies={requestReplies}
              project={project}
            />
          </TabPane>
        </Tabs>
      </>
    );
  }

  return (
    <div className="project-page">
      <div className="relativeNavigationBar">
        <Button onClick={() => history.push("/projects")}>
          <ArrowLeftOutlined /> Return to projects
        </Button>
      </div>
      {contentToDisplay}
    </div>
  );
};

export default ProjectPage;
