import React, { useEffect, useMemo, useRef } from "react";

import { AG_GRID_PINNED_TYPE } from "@src/constants";
import {
  getColumnDefs,
  getProposeTeamMemberTimeRendererGridOptions,
} from "@src/features/staffing_request_details/utils/propose_allocation_renderer_grid_options";
import {
  setConfirmationButtonDisableStatus,
  setProposeTeamMemberAllocation,
} from "@src/services/nextStepModalSlice";
import { useGetTeamLeadRequestDetailsQuery } from "@src/services/slices/teamLeadsApi";
import { RootState, useAppSelector } from "@src/setupStore";
import {
  AllocationRowData,
  TeamLeadAllocationRowData,
} from "@src/types/role_request_types";
import { ColDef } from "ag-grid-community";
import {
  DetailGridInfo,
  GridApi,
} from "ag-grid-community/dist/types/core/api/gridApi";
import {
  CellValueChangedEvent,
  GridReadyEvent,
} from "ag-grid-community/dist/types/core/events";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import "./propose_allocation_renderer.less";
import { App } from "antd";
import _ from "lodash";
import { useDispatch } from "react-redux";

import {
  filterMapForUpdatedValues,
  mapRequestToProposeAllocationsRowData,
  toggleErrorFlagInContext,
} from "../../utils/propose_allocations_utils";

interface ProposeAllocationRendererProps {
  props: CustomCellRendererProps;
}

const ProposeAllocationRenderer: React.FC<ProposeAllocationRendererProps> = ({
  props,
}) => {
  const { message } = App.useApp();

  const gridRef = useRef(null);
  const gridApi = useRef<GridApi | null>(null);
  const rowId: string = props.node?.id;

  const { selectedRequestId } = useAppSelector(
    (state: RootState) => state.requestOverviewSlice
  );

  const { data: request } = useGetTeamLeadRequestDetailsQuery(
    selectedRequestId,
    {
      skip: !selectedRequestId,
    }
  );

  const currentRequest = request.childRequest ?? request;

  const workloadEndDate: Date = _.last(
    currentRequest.roleAllocationDetails
  )?.date;

  const columnDefs = useMemo<ColDef[]>(() => {
    return getColumnDefs(workloadEndDate);
  }, [workloadEndDate]);

  const rowData = useMemo<TeamLeadAllocationRowData[]>(() => {
    return mapRequestToProposeAllocationsRowData(currentRequest);
  }, [currentRequest]);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      if (!props.api.isDestroyed()) {
        props.api.removeDetailGridInfo(rowId);
      }
    };
  }, []);

  /**
   * AG Grid onGridReady function
   * assigns the GridApi to a ref
   *
   * @param {GridReadyEvent} event AG Grid event
   */
  function onGridReady(event: GridReadyEvent): void {
    gridApi.current = event.api;
    const gridInfo: DetailGridInfo = {
      id: rowId,
      api: event.api,
    };

    props.api.addDetailGridInfo(rowId, gridInfo);
  }

  /**
   * AG Grid onCellValueChanged event
   *
   * Toggles an error if the new value is invalid or remove it when valid.
   *
   * It handes the enabling and disabling of the confirmation button and adds the proposed
   * allocations to the store.
   *
   * Checks if any value in the table is invalid. If yes, confirmation button will be disabled.
   * If not, the confirmation button will be enabled and the updated allocation will be stored.
   *
   * @param {CellValueChangedEvent} event AG Grid event parameter
   */
  function onCellValueChanged(
    event: CellValueChangedEvent<TeamLeadAllocationRowData>
  ) {
    toggleErrorFlagInContext(event, message);

    const hasAnyInvalidValue: boolean = event.context.errorColIds.length > 0;

    dispatch(setConfirmationButtonDisableStatus(hasAnyInvalidValue));

    if (!hasAnyInvalidValue) {
      const updatedAllocations: Map<string, AllocationRowData> =
        filterMapForUpdatedValues(event.data.allocations);

      dispatch(setProposeTeamMemberAllocation(updatedAllocations));
    }
  }

  return (
    <div className="propose-allocation-renderer">
      {props.pinned === AG_GRID_PINNED_TYPE.LEFT ? (
        <div className="propose-allocation-renderer__propose-time-label">
          Proposed time
        </div>
      ) : (
        <div className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            gridOptions={getProposeTeamMemberTimeRendererGridOptions()}
            rowData={rowData}
            onGridReady={onGridReady}
            onCellValueChanged={onCellValueChanged}
            columnDefs={columnDefs}
          />
        </div>
      )}
    </div>
  );
};

export default ProposeAllocationRenderer;
