import React from "react";

import "./staffing_request_overview_tab.less";
import { ProjectInformationCard } from "@src/features/staffing_request_details/components/project_information_card";
import { StaffingRequestInformationCard } from "@src/features/staffing_request_details/components/staffing_request_information_card";
import { StaffingRequestWorkloadTable } from "@src/features/staffing_request_details/components/staffing_request_workload_table";
import { RequestOverviewDetails } from "@src/types";
import { Spin } from "antd";

interface StaffingRequestOverviewTabProps {
  request: RequestOverviewDetails;
  status: string;
  isLoading: boolean;
}

const StaffingRequestOverviewTab: React.FC<StaffingRequestOverviewTabProps> = ({
  request,
  status,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <div className="staffing-request-overview">
        <Spin data-testid="loading-spinner" />
      </div>
    );
  }

  const renderWorkloadTable = !request.isOutdated && (
    <StaffingRequestWorkloadTable
      data-testid="workload-table"
      request={request}
    />
  );

  return (
    <div className="staffing-request-overview">
      <div className="staffing-request-overview__card">
        <ProjectInformationCard request={request} status={status} />
        <StaffingRequestInformationCard request={request} />
      </div>
      {renderWorkloadTable}
    </div>
  );
};
export default StaffingRequestOverviewTab;
