import React from "react";

import "./role_request_base_modal.less";
import { RoleRequestModalHeader } from "@src/components/role_request_modal_header";
import { CustomTabItem } from "@src/types/antd_types";
import { Modal, Tabs } from "antd";

interface RoleRequestBaseModalProps {
  modalVisible: boolean;
  requestedRoleId: number;
  requestedRoleStatus: string;
  requestedRoleName: string;
  tabs: CustomTabItem[];
  actions: React.ReactNode;
  activeTabKey: string;
  handleClose: () => void;
  handleTabChange: (key: string) => void;
  isRequestOutdated: boolean;
}

const RoleRequestBaseModal: React.FC<RoleRequestBaseModalProps> = ({
  modalVisible,
  requestedRoleName,
  requestedRoleStatus,
  requestedRoleId,
  tabs,
  actions,
  activeTabKey,
  handleClose,
  handleTabChange,
  isRequestOutdated,
}) => {
  return (
    <Modal
      open={modalVisible}
      centered
      width={1018}
      closable={false}
      maskClosable={false}
      className="role-request-modal"
      onClose={handleClose}
      onCancel={handleClose}
      title={
        <RoleRequestModalHeader
          requestedRoleStatus={requestedRoleStatus}
          requestedRoleId={requestedRoleId}
          requestedRoleName={requestedRoleName}
          isRequestOutdated={isRequestOutdated}
        />
      }
      footer={actions}
    >
      <Tabs
        className="role-request-modal__modal-tabs"
        type="card"
        activeKey={activeTabKey}
        onChange={handleTabChange}
        items={tabs.filter(
          (tab: CustomTabItem) => !tab.isHidden?.(requestedRoleStatus)
        )}
      />
    </Modal>
  );
};

export default RoleRequestBaseModal;
