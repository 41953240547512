import React from "react";

import fixedbottom from "@src/assets/icons/fixedbottom-tech.svg";
import floating from "@src/assets/icons/floating-tech.svg";
import hydrogen from "@src/assets/icons/hydrogen.svg";
import GridItem from "@src/components/grid_label/grid_label";
import { ProjectPhaseChip } from "@src/components/project_phase_chip";
import { Status } from "@src/components/status";
import { RequestOverviewDetails } from "@src/types";
import { formatDate, getWorkloadStartDate } from "@src/utils/helper";
import { Card } from "antd";
import "./project_information_card.less";

interface ProjectInformationCardProps {
  request: RequestOverviewDetails;
  status: string;
}

const ProjectInformationCard: React.FC<ProjectInformationCardProps> = ({
  request,
  status,
}) => {
  const scopeDescription: string =
    request?.childRequest?.scopeDescription ?? request?.scopeDescription;
  const specificRole: string =
    request?.childRequest?.specificRole ?? request?.specificRole;
  const projectIdentifier: string =
    request?.childRequest?.projectIdentifier ?? request?.projectIdentifier;

  const technologyIcons = {
    Floating: floating,
    "Fixed bottom": fixedbottom,
    Hydrogen: hydrogen,
  };

  function getTechnologyName(technologies: string): string {
    if (!technologies) return "";
    return technologies
      .split(", ")
      .filter((tech) => tech !== "Hydrogen")
      .join(", ");
  }

  function getTechnologyIcon(technologies: string): string {
    if (!technologies) return "";
    if (technologies.includes("Hydrogen")) return hydrogen;
    const technologyName = getTechnologyName(technologies);
    return technologyIcons[technologyName] || "";
  }

  return (
    <Card title={request.projectName} className="project-information-card">
      <div className="project-information-grid">
        <GridItem label="Phase">
          <>
            <ProjectPhaseChip
              projectPhase={request.projectPhase}
              compact={false}
            />
            {request.stageGateDate && (
              <span>{`(End: ${formatDate(request.stageGateDate)})`}</span>
            )}
          </>
        </GridItem>
        <GridItem label="Region / Country">
          {`${request.region || "N.A."} / ${request.country}`}
        </GridItem>
        <GridItem label="Scope Descriptions">{scopeDescription}</GridItem>
        <GridItem label="Status">
          <Status status={status} />
        </GridItem>
        <GridItem label="Technology">
          <div className="project-information-card-table-technology">
            {getTechnologyIcon(request.technologies) && (
              <img
                src={getTechnologyIcon(request.technologies)}
                alt="project technology"
                className="project-information-card-table-technology-icon"
              />
            )}
            <span className="project-information-card-table-technology-name">
              {getTechnologyName(request.technologies)}
            </span>
          </div>
        </GridItem>
        <GridItem label="Project Identifier">{projectIdentifier}</GridItem>
        <GridItem label="Project Lead">{request.projectLead}</GridItem>
        <GridItem label="Workload Start">
          {getWorkloadStartDate(request)}
        </GridItem>
        <GridItem label="Specific Role">{specificRole}</GridItem>
      </div>
    </Card>
  );
};

export default ProjectInformationCard;
