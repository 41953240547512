import React from "react";

import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import "./search_input.less";
import { SizeType } from "antd/es/config-provider/SizeContext";

interface SearchInputProps {
  className?: string;
  disabled?: boolean;
  defaultValue?: string;
  size?: SizeType;
  onChange?: (value: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  disabled,
  defaultValue,
  size = "middle",
  onChange,
}) => {
  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    onChange && onChange(e.target.value);
  }

  return (
    <Input
      prefix={<SearchOutlined />}
      placeholder="Search"
      size={size}
      defaultValue={defaultValue}
      onChange={handleInputChange}
      disabled={disabled}
      data-testid="filter-group-input"
      className="search-input-filter"
    />
  );
};

export default SearchInput;
