/**
 * @file provides helper functions for setting an error flag in the context of the allocation AG Grid table and a function that checks if a value is valid.
 * It also contains a valueGetter and valueSetter for updating allocation cells.
 * Additionally it provides some cellClassRules to mark updated cells and error cells.
 *
 *
 * Row Data need to have the following properties:
 * @property {string} label
 * @property {Map<string, {percentage: number, updatedPercentage?: number }>} allocations
 * -> the key of the map represents the colId if the allocation colDef
 *
 * The context will have the following properties:
 * @property {string[]} errorColIds
 * The type of the Context is also provided by this file.
 */

import { AllocationRowData } from "@src/types/role_request_types";
import {
  CellClassParams,
  CellClassRules,
  ColDef,
  FillOperationParams,
  GridOptions,
  ValueGetterParams,
  ValueSetterParams,
} from "ag-grid-community";
import { MessageInstance } from "antd/es/message/interface";
import _ from "lodash";

/**
 * Type of the error context
 */
export interface AllocationErrorContext {
  errorColIds?: string[];
}

/**
 * Validation of the value.
 * Valid values: only numerical values, non-floating numbers and numbers between 0 and 100
 *
 * @param value the value to be checked
 * @returns true if the value is valid, false if the value is not valid
 */
export function isValueValid(value: number): boolean {
  return Number.isInteger(value) && value <= 100 && value >= 0;
}

/**
 * Adds the colId to the errorColIds context
 */
export function addErrorFlag(
  context: AllocationErrorContext,
  colId: string,
  message?: MessageInstance
): void {
  context.errorColIds = _.union(context.errorColIds, [colId]);
  message?.error("Please add a number between 0 and 100");
}

/**
 * Removes the colId from the errorColIds context
 */
export function removeErrorFlag(
  context: AllocationErrorContext,
  colId: string
): void {
  _.pull(context.errorColIds, colId);
}

/**
 * The valueGetter for the editing of allocation cells.
 * By year and month it will build the key of the map to get the right data.
 *
 * @param {ValueGetterParams} params AG Grid params. This function reads the data of the grid
 * @param {number} year year that is considered in the colDef
 * @param {number} month month index (1-12) that is considered in the colDef
 * @returns the value of the data that should be shown in the column
 */
export function editAllocationValueGetter(
  params: ValueGetterParams,
  year: number,
  month: number
): number {
  const foundAllocation: AllocationRowData = params.data.allocations?.get(
    `${month}-${year}`
  );

  return (
    foundAllocation?.updatedPercentage ?? (foundAllocation?.percentage || 0)
  );
}

/**
 * The valueSetter for the editing of allocation cells. (it will trigger the onCellValueChanged event in the table)
 * By year and month it will build the key of the map to get the right data.
 * When there is no data yet in the current colDef, it creates a new entry in the data map.
 *
 * @param { ValueSetterParams } params AG Grid params. This function reads the data of the grid, the new value and the old value
 * @param {number} year year that is considered in the colDef
 * @param {number} month month index (1-12) that is considered in the colDef
 * @returns true when a new value was set, false if not
 */
export function editAllocationValueSetter(
  params: ValueSetterParams,
  year: number,
  month: number
): boolean {
  if (params.newValue === params.oldValue) return false;

  const allocationMap: Map<string, AllocationRowData> = _.cloneDeep(
    params.data.allocations
  );

  const foundAllocation: AllocationRowData = allocationMap.get(
    `${month}-${year}`
  );
  if (foundAllocation) {
    foundAllocation.updatedPercentage = params.newValue;
  } else {
    const newAllocation: AllocationRowData = {
      percentage: 0,
      updatedPercentage: params.newValue,
    };
    allocationMap.set(`${month}-${year}`, newAllocation);
  }

  params.data.allocations = allocationMap;
  return true;
}

/**
 * The cellClassRules when cell is updated or has an error.
 *
 * The Row Data need to have the property "allocations" which contains a Map ((key = colId, value: allocations percentage and updatedPercentage))
 */
export const editAllocationCellClassRules: CellClassRules = {
  "allocation-cell--updated": (params: CellClassParams): boolean => {
    const colId: string = params.colDef.colId;
    const initialAllocationData: AllocationRowData =
      params.data.allocations.get(colId);
    const initialValue: number = initialAllocationData?.percentage || 0;

    const isValid: boolean = isValueValid(Number(params.value));
    const isUpdated: boolean = params.value !== initialValue;

    return isValid && isUpdated;
  },
  "allocation-cell--error": (params: CellClassParams): boolean => {
    return !isValueValid(Number(params.value));
  },
};

/**
 * Column Definitions that can be added to a colDef for editing allocation cells
 */
export const editableColumnDef: ColDef = {
  editable: true,
  cellEditor: "agNumberCellEditor",
};

/**
 * Grid Options that can be added to a table that uses allocation editing
 */
export const editableGridOptions: GridOptions = {
  cellSelection: {
    handle: {
      mode: "fill",
      setFillValue: (params: FillOperationParams) => {
        const colId = params.column.getColId();
        const regex = /^\d{1,2}-\d{4}$/; // matches "mm-yyyy" or "m-yyyy"
        const isAllocationCell = colId.match(regex);

        const values = params.values;

        return !isAllocationCell && params.direction === "left"
          ? params.currentCellValue
          : values[values.length - 1];
      },
    },
  },
};
