// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role-request-creation-drawer .creation-wrapper {
  overflow: auto;
  height: 90%;
}
.role-request-creation-drawer .creation-wrapper .no-standard-roles-found {
  display: flex;
  justify-content: center;
  height: 100%;
  font-family: RWESansWeb-Medium;
}
.role-request-creation-drawer .ant-drawer-body {
  overflow: hidden !important;
  padding: 0 !important;
}
.role-request-creation-drawer .ant-form-item {
  margin: 25px;
}
.role-request-creation-drawer .ant-popover-inner {
  height: 100%;
  width: 400px;
}
`, "",{"version":3,"sources":["webpack://./src/features/resource_plan_v2/components/role_request_creation_drawer/role_request_creation_drawer.less"],"names":[],"mappings":"AAEA;EAEI,cAAA;EACA,WAAA;AAFJ;AADA;EAMM,aAAA;EACA,uBAAA;EACA,YAAA;EACA,8BAAA;AAFN;AAOI;EACE,2BAAA;EACA,qBAAA;AALN;AAQI;EACE,YAAA;AANN;AASI;EACE,YAAA;EACA,YAAA;AAPN","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.role-request-creation-drawer {\n  .creation-wrapper {\n    overflow: auto;\n    height: 90%;\n\n    .no-standard-roles-found {\n      display: flex;\n      justify-content: center;\n      height: 100%;\n      font-family: RWESansWeb-Medium;\n    }\n  }\n\n  .ant {\n    &-drawer-body {\n      overflow: hidden !important;\n      padding: 0 !important;\n    }\n\n    &-form-item {\n      margin: 25px;\n    }\n\n    &-popover-inner {\n      height: 100%;\n      width: 400px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
