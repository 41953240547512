import { useEffect, useState } from "react";

import { IMsalContext, useMsal } from "@azure/msal-react";
import * as constants from "@src/constants";
import { isValidRoute } from "@src/router/routeUtils";
import { useLazyGetLoggedInUserQuery } from "@src/services/slices/usersSlice";
import { Employee } from "@src/types";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";

interface UseAuthenticationReturn {
  hasAccess: boolean;
  isAuthenticating: boolean;
  loggedInUser: Employee;
  reasonPhrase: string[];
}

const cookies = new Cookies();
const { REACT_APP_SSO_ENABLE } = process.env;

/**
 * Custom hook to handle authentication and user access control.
 * @returns {UseAuthenticationReturn} Object containing the user's access status and SSO enablement status.
 */
export const useAuthentication = (): UseAuthenticationReturn => {
  const history = useHistory();
  const location = useLocation();
  const ENABLE_SSO = REACT_APP_SSO_ENABLE === "true";
  const [loggedInUser, setLoggedInUser] = useState<Employee>();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [hasAccess, setHasAccess] = useState(true);
  const [reasonPhrase, setReasonPhrase] = useState<string[]>([]);
  const { instance }: IMsalContext = useMsal();

  /*RTK Queries*/
  const [getLoggedInUser] = useLazyGetLoggedInUserQuery();

  useEffect(() => {
    setIsAuthenticating(true);
    const employeeCookie: Employee = cookies.get("loggedInuser");
    if (employeeCookie) {
      setLoggedInUser(employeeCookie);
      navigateToLoggedInScreen(employeeCookie);
    } else if (!ENABLE_SSO) {
      setIsAuthenticating(false);
      history.push("/");
    }

    fetchEmployeeInformation();
  }, [location, history]);

  /**
   * Fetches employee information from the API.
   * @async
   */
  async function fetchEmployeeInformation() {
    try {
      const empObj: Employee = cookies.get("loggedInuser");
      const hasUserFunctionGroupAndIsInDB = !!empObj?.applicationRoles;
      const isUserOnlyTeamMember =
        hasUserFunctionGroupAndIsInDB &&
        empObj.applicationRoles.length <= 1 &&
        empObj.applicationRoles[0] === constants.ROLES.TEAM_MEMBER;

      const hasUserNoRoles = empObj?.applicationRoles?.length === 0;
      const ImpersonationUser = cookies.get("ImpersonationUser");
      const currentReason = [...reasonPhrase];

      if (ENABLE_SSO && instance.getActiveAccount()) {
        await handleSSOUser(
          currentReason,
          hasUserNoRoles,
          isUserOnlyTeamMember
        );
      } else if (!ENABLE_SSO && ImpersonationUser) {
        await handleNonSSOUser(
          currentReason,
          hasUserFunctionGroupAndIsInDB,
          hasUserNoRoles,
          isUserOnlyTeamMember
        );
      }
    } catch (e) {
      console.log(e, "Error: failed to get user");
    }
  }

  /**
   * Handles authentication and access control for SSO users.
   * @param {string[]} currentReason - The current reasons for denying access.
   * @param {boolean} hasUserNoRoles - Indicates if the user has no roles.
   * @param {boolean} isUserOnlyTeamMember - Indicates if the user is only a team member.
   * @async
   */
  async function handleSSOUser(
    currentReason: string[],
    hasUserNoRoles: boolean,
    isUserOnlyTeamMember: boolean
  ) {
    try {
      const res = await getLoggedInUser().unwrap();
      if (hasUserNoRoles || isUserOnlyTeamMember) {
        setHasAccess(false);
        setIsAuthenticating(false);
        currentReason.push(
          "User has no roles (Admin, Team lead, Project lead) to access Dualis"
        );
        setReasonPhrase(currentReason);
      }
      onLogin(res);
    } catch (e) {
      console.log(e, "Error: failed to get user");
      currentReason.push("User is not listed in Database");
      setReasonPhrase(currentReason);
      setHasAccess(false);
      setIsAuthenticating(false);
    }
  }

  /**
   * Handles authentication and access control for non-SSO users.
   * @param {string[]} currentReason - The current reasons for denying access.
   * @param {boolean} hasUserFunctionGroupAndIsInDB - Indicates if the user has function groups and is in the database.
   * @param {boolean} hasUserNoRoles - Indicates if the user has no roles.
   * @param {boolean} isUserOnlyTeamMember - Indicates if the user is only a team member.
   * @async
   */
  async function handleNonSSOUser(
    currentReason: string[],
    hasUserFunctionGroupAndIsInDB: boolean,
    hasUserNoRoles: boolean,
    isUserOnlyTeamMember: boolean
  ) {
    if (!hasUserFunctionGroupAndIsInDB) {
      currentReason.push("User is not listed in Database");
      setReasonPhrase(currentReason);
      setHasAccess(false);
      setIsAuthenticating(false);
    }

    if (hasUserNoRoles || isUserOnlyTeamMember) {
      currentReason.push(
        "User has no roles (Admin, Team lead, Project lead) to access Dualis"
      );
      setReasonPhrase(currentReason);
      setHasAccess(false);
      setIsAuthenticating(false);
    }

    const res = await getLoggedInUser().unwrap();
    onLogin(res);
  }

  /**
   * Sets the logged-in user in cookies and navigates to the appropriate landing page.
   * @param {object} loggedInEmployee - The logged-in employee's data.
   */
  function onLogin(loggedInEmployee: Employee) {
    cookies.addChangeListener(onCookiesChange);
    cookies.set("loggedInuser", loggedInEmployee, { path: "/" });
  }

  function onCookiesChange() {
    const loggedInEmployee: Employee = cookies.get("loggedInuser");
    if (loggedInEmployee) {
      setLoggedInUser(loggedInEmployee);
      navigateToLoggedInScreen(loggedInEmployee);
    }
  }

  /**
   * Navigates to the appropriate landing page based on the user's roles.
   */
  function navigateToLoggedInScreen(employeeCookie: Employee) {
    // Only navigate if the user is on the start screen page
    if (!isValidRoute(history?.location?.pathname)) {
      let landingPage = "/";

      const userLandingPagesMap = {
        [constants.ROLES.PROJECT_LEAD]: constants.ROUTES.PROJECT_LEAD.PROJECTS,
        [constants.ROLES.DEPUTY]: constants.ROUTES.TEAM_LEAD.STAFFING_REQUESTS,
        [constants.ROLES.TEAM_LEAD]:
          constants.ROUTES.TEAM_LEAD.STAFFING_REQUESTS,
        [constants.ROLES.ADMIN]: constants.ROUTES.ADMIN.EMPLOYEE_DATA,
        [constants.ROLES.MANAGER]: constants.ROUTES.MANAGER.DASHBOARD,
      };

      if (employeeCookie.applicationRoles) {
        const loggedInUserLandingPage =
          userLandingPagesMap[
            employeeCookie.applicationRoles.filter(
              (role: string) => role !== constants.ROLES.TEAM_MEMBER
            )[0]
          ];
        if (loggedInUserLandingPage) {
          landingPage = loggedInUserLandingPage;
        }

        history.push(landingPage);
      }
    }
    setIsAuthenticating(false);
  }

  return {
    hasAccess,
    reasonPhrase,
    loggedInUser,
    isAuthenticating,
  };
};
