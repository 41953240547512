import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { HTTP_METHODS } from "@src/constants";
import { baseApiSlice } from "@src/services/slices/baseApiSlice";
import { Country } from "@src/types";
import { message } from "antd";

export const countriesApi = baseApiSlice.injectEndpoints({
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    /* GET endpoints */
    ...getEndpoints(builder),

    /* PUT endpoints */
    ...putEndpoints(builder),
  }),
});

function getEndpoints(builder: EndpointBuilder<any, any, any>) {
  return {
    /**
     * Get all countries
     */
    getCountries: builder.query<Country[], void>({
      query: () => "countries",
      transformErrorResponse: async () => {
        message.error("Unable to load countries", 5);
      },
      providesTags: ["Countries"],
    }),
  };
}

function putEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod: string = HTTP_METHODS.PUT;

  return {
    /**
     * Update a country with the given data
     */
    updateCountry: builder.mutation<void, Country>({
      query: (country) => ({
        url: `countries/${country.countryId}`,
        method: baseMethod,
        body: country,
      }),
      transformErrorResponse: async () => {
        message.error("Error updating country", 5);
      },
      invalidatesTags: ["Countries"],
    }),
  };
}

export const {
  /* GET endpoints */
  useGetCountriesQuery,
  /* PUT endpoints */
  useUpdateCountryMutation,
} = countriesApi;
