import { Headline } from "@src/components/headline";
import { BasicRoleManagement } from "@src/features/role_assignment/components/basic_role_management";
import { RoleSpecificationManagement } from "@src/features/role_assignment/components/role_specification_management";
import { StandardRoleAssignment } from "@src/features/role_assignment/components/standard_role_assignment";
import { Tabs } from "antd";
import "./role_assignment_page.less";

const RoleAssignmentPage = () => {
  const tabs = [
    {
      key: "1",
      label: "Role Assignment",
      children: <StandardRoleAssignment />,
    },
    {
      key: "2",
      label: "Basic Roles",
      children: <BasicRoleManagement />,
    },
    {
      key: "3",
      label: "Specifications",
      children: <RoleSpecificationManagement />,
    },
  ];

  return (
    <div className="role_assignment_page">
      <Headline title="Role Assignment" />
      <span data-testid="label">
        Edit basic roles, specifications and assign them
      </span>
      <Tabs type="card" size="large" items={tabs} />
    </div>
  );
};

export default RoleAssignmentPage;
