import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SolutionType } from "@src/constants";
import { NextStepModalState } from "@src/types";
import { AllocationRowData } from "@src/types/role_request_types";

const initialState: NextStepModalState = {
  nextStepModalVisible: false,
  proposedSolutionType: undefined,
  confirmationButtonDisabled: true,
  proposeTeamMember: {
    selectedTeamMemberId: null,
    proposedAllocations: null,
  },
  selectedVacancy: {
    selectedVacancyId: null,
    proposedAllocations: null,
  },
  forward: {
    forwardedTeamLeadId: null,
  },
  decline: {
    proposedAllocations: null,
  },
};

const nextStepModalSlice = createSlice({
  name: "nextStepModal",
  initialState: initialState,
  reducers: {
    /** general modal state **/
    setNextStepModalVisible: (
      state: NextStepModalState,
      modalVisible: PayloadAction<boolean>
    ) => {
      state.nextStepModalVisible = modalVisible.payload;
    },

    setConfirmationButtonDisableStatus: (
      state: NextStepModalState,
      buttonDisableState: PayloadAction<boolean>
    ) => {
      state.confirmationButtonDisabled = buttonDisableState.payload;
    },

    setProposedSolutionType: (
      state: NextStepModalState,
      proposedSolutionType: PayloadAction<SolutionType>
    ) => {
      state.proposedSolutionType = proposedSolutionType.payload;
    },

    /** propose team member **/
    setProposedTeamMemberId: (
      state: NextStepModalState,
      selectedTeamMemberId: PayloadAction<number>
    ) => {
      state.proposeTeamMember.selectedTeamMemberId =
        selectedTeamMemberId.payload;
    },

    setProposeTeamMemberAllocation: (
      state: NextStepModalState,
      proposedAllocations: PayloadAction<Map<string, AllocationRowData>>
    ) => {
      state.proposeTeamMember.proposedAllocations = proposedAllocations.payload;
    },

    /** propose vacancy **/
    setSelectedVacancyId: (
      state: NextStepModalState,
      selectedVacancyId: PayloadAction<number>
    ) => {
      state.selectedVacancy.selectedVacancyId = selectedVacancyId.payload;
    },

    setProposeVacancyAllocations: (
      state: NextStepModalState,
      proposedAllocations: PayloadAction<Map<string, AllocationRowData>>
    ) => {
      state.selectedVacancy.proposedAllocations = proposedAllocations.payload;
    },

    /** forward to team lead **/
    setForwardedTeamLeadId: (
      state: NextStepModalState,
      forwardedTeamLeadId: PayloadAction<number>
    ) => {
      state.forward.forwardedTeamLeadId = forwardedTeamLeadId.payload;
    },

    /** decline **/
    setDeclineAllocations: (
      state: NextStepModalState,
      proposedAllocations: PayloadAction<Map<string, AllocationRowData>>
    ) => {
      state.decline.proposedAllocations = proposedAllocations.payload;
    },

    /** reset state **/
    resetSolutionState: (state: NextStepModalState) => {
      state.nextStepModalVisible = false;
      state.confirmationButtonDisabled =
        initialState.confirmationButtonDisabled;
      state.proposeTeamMember = initialState.proposeTeamMember;
      state.selectedVacancy = initialState.selectedVacancy;
      state.forward = initialState.forward;
      state.decline = initialState.decline;
    },

    clearNextStepModalState: () => initialState,
  },
});

export const {
  setNextStepModalVisible,
  setConfirmationButtonDisableStatus,
  setProposedSolutionType,
  setProposedTeamMemberId,
  setProposeTeamMemberAllocation,
  setSelectedVacancyId,
  setProposeVacancyAllocations,
  setForwardedTeamLeadId,
  setDeclineAllocations,
  resetSolutionState,
  clearNextStepModalState,
} = nextStepModalSlice.actions;

export default nextStepModalSlice;
