import React from "react";

import { paginationGridOptions } from "@src/utils/aggrid_utils";
import { Form, Select } from "antd";
import "./project_lead_selection_table_config.less";

export const projectleadSelectionRenderer = (
  projectElement,
  onChange,
  projectleads
) => {
  const selection = (
    <Form layout="vertical">
      <Form.Item
        name={`teamlead${projectElement.rowIndex}`}
        size="large"
        className="project-lead-selection"
      >
        <Select
          showSearch
          placeholder="Please select a project lead"
          style={{ width: 290 }}
          data-testid="project-lead-selection"
          onChange={(selectedProjectLeadId) =>
            onChange(selectedProjectLeadId, projectElement)
          }
          optionFilterProp="label"
          options={projectleads.map((lead) => ({
            value: lead.employeeId,
            label: lead.fullName,
          }))}
        />
      </Form.Item>
    </Form>
  );

  return selection;
};

export const gridOptions = {
  ...paginationGridOptions,
  paginationPageSize: 5,
  suppressContextMenu: true,
};

export const columns = [
  {
    field: "name",
    headerName: "Project name",
    width: 318,
    filter: "agTextColumnFilter",
  },
  {
    field: "projectManager",
    headerName: "Project manager",
    width: 318,
    filter: "agTextColumnFilter",
    cellRenderer: "projectleadSelectionRenderer",
  },
];
