import React from "react";

import { DownloadOutlined } from "@ant-design/icons";
import { ColDef } from "ag-grid-community";
import { App, Button } from "antd";
import _ from "lodash";
import moment from "moment/moment";

/**
 * @deprecated component. This will be deleted when the resource plan v2 will be deployed.
 */
const ResourcePlanDownloadButton = ({
  sendRequestsGridApi,
  openRequestsGridApi,
  project,
}) => {
  const { modal } = App.useApp();

  // get all send requests column definitions to have all necessary columns needed for export because open requests
  // has fewer columns then send requests we need to have them also to verify that all columns are in the right
  // order compared to the open requests columns
  const sendRequestsColumnDefs: ColDef[] | undefined = sendRequestsGridApi
    ?.getColumnDefs()
    .filter((col: ColDef) => col?.pinned === "left");

  // get all open requests allocations
  const openRequestsAllocationColumnDefs: ColDef[] | undefined =
    openRequestsGridApi
      ?.getColumnDefs()
      .filter((col: ColDef) => col.field === "allocation");

  /**
   * Will add all allocations from open requests to the export of the send requests.
   *
   * @param openRequestsAllocationColumnDefs
   * @param cells
   * @param request
   */
  const addOpenRequestsAllocationsColumns = (
    openRequestsAllocationColumnDefs,
    cells,
    request
  ) => {
    for (const openRequestsAllocationColumnDef of openRequestsAllocationColumnDefs) {
      const foundYear =
        request.yearlyAllocations[openRequestsAllocationColumnDef.headerName];

      let value = 0;

      for (const openRequestChildren of openRequestsAllocationColumnDef.children) {
        if (foundYear) {
          const splitColId = openRequestChildren.colId.split("_");
          const month = +splitColId[1];
          const monthAllocation = foundYear[month];

          if (monthAllocation && monthAllocation !== 0) {
            value = monthAllocation / 100;
          }
        }

        const cell = {
          data: {
            value: value,
            type: "number",
          },
        };

        cells.push(cell);
      }
    }
  };

  /**
   * Will add main requests column like request id, standard role, joint venture, Project Identifier, Specific Role, Scope and Status.
   * The allocations will be generated in addOpenRequestsAllocationsColumns
   *
   * @param sendRequestsColumnDefs
   * @param cells
   * @param request
   */
  const addMainOpenRequestsColumns = (
    sendRequestsColumnDefs,
    cells,
    request
  ) => {
    for (const sendRequestsColumnDef of sendRequestsColumnDefs) {
      let type = "String";

      let value = request[sendRequestsColumnDef.colId];
      switch (sendRequestsColumnDef.colId) {
        case "jointVenture":
          value = request?.jointVenture?.toString();
          break;
        case "totalAvailability":
          type = "number";
          break;
        case "technologies":
          value = _.sortBy(project.technologies).join(", ");
          break;
        case "parentRequestId":
          type = "number";
          value = request?.projectRoleRequestId;
          break;
      }
      const cell = {
        data: {
          value: value,
          type: type,
        },
      };

      cells.push(cell);
    }
  };

  /**
   * Will generate excel rows for export. Depends on if the user filtered or not. If the user filtered than
   * It will only export the filtered and sorted allocation rows.
   *
   * @param downloadFiltered
   * @returns {*[]}
   */
  const generateExcelExportRows = (downloadFiltered: boolean) => {
    const response = [];

    // define the iteration type. If download was filtered and the user agreed in downloading filtered and sorted requests
    // then it should take the iteration method forEachNodeAfterFilterAndSort otherwise the forEachNode
    // to iterate over all nodes and not only the filtered.
    const forEachType = downloadFiltered
      ? "forEachNodeAfterFilterAndSort"
      : "forEachNode";

    openRequestsGridApi[forEachType]((node) => {
      const cells = [];

      //add main information
      addMainOpenRequestsColumns(sendRequestsColumnDefs, cells, node.data);

      //add allocation data
      addOpenRequestsAllocationsColumns(
        openRequestsAllocationColumnDefs,
        cells,
        node.data
      );

      response.push({ cells: cells });
    });

    return response;
  };

  /**
   * Should extract col ids from grouped columns
   *
   * @param columns
   */
  const extractColumnIdsFromGroupedRows = (columns) => {
    let columnIds = [];

    columns.forEach((col) => {
      if (col.children) {
        columnIds = columnIds.concat(
          extractColumnIdsFromGroupedRows(col.children)
        );
      } else {
        columnIds.push(col.colId);
      }
    });

    return columnIds;
  };

  /**
   * Should get all defined necessary excel parameter.
   *
   * @param downloadFiltered
   * @returns {{fileName: string, sheetName: string, allColumns: boolean, appendContent: *[], exportedRows: (string), processCellCallback(*): (*)}|*|number}
   */
  const getExcelConfigParameter = (downloadFiltered: boolean) => {
    const allocationDateKeys = extractColumnIdsFromGroupedRows(
      openRequestsAllocationColumnDefs
    );
    const mainColumnKeys = sendRequestsColumnDefs.map(
      (sendRequestsColumnDef) => sendRequestsColumnDef.colId
    );

    const fileName = `Project - Resource Planning - ${project.name} 
    - ${moment(new Date()).format("YYYY-MM-DD")}`;

    // Remove leading and trailing spaces from the fileName and replace any sequence of non-alphanumeric characters
    // with a single underscore for Excel filename compatibility.
    const validFileName = fileName.trim().replace(/[^a-zA-Z0-9]+/g, "_");

    //sheet name can not contain these characters \:?/*[] otherwise excel will have a problem exporting the file
    const validSheetName = project.name.replace(/[:?/*[\]]/g, " ");

    const parameter = {
      processHeaderCallback: (params) => {
        if (allocationDateKeys.includes(params.column.colId)) {
          const headername = moment(
            params.column.colId.replace("_", "-")
          ).format("MMM-YY");
          return headername;
        }
        return params.api.getDisplayNameForColumn(params.column, null);
      },
      processCellCallback: (params) => {
        if (params.column.pinned !== "left" && params?.value) {
          return parseInt(params.value) / 100;
        }

        return params.value;
      },
      // it is necessary to define the columnKeys otherwise you have strictly to define the same order in the
      // array, which is also shown in the table frontend. Also checkbox will be shown as an empty column.
      // With this approach it is possible to define a different approach and skip check box column
      columnKeys: [...mainColumnKeys, ...allocationDateKeys],
      sheetName: `Resource Plan ${validSheetName}`,
      fileName: validFileName,
      exportedRows: downloadFiltered ? "filteredAndSorted" : "all",
      appendContent: generateExcelExportRows(downloadFiltered),
      skipColumnGroupHeaders: true,
      skipPinnedTop: true,
    };

    return parameter;
  };

  /**
   * Will finally export the resource plan.
   *
   * @param downloadFiltered
   */
  const exportResourcePlan = (downloadFiltered: boolean) => {
    sendRequestsGridApi?.exportDataAsExcel(
      getExcelConfigParameter(downloadFiltered)
    );
  };

  /**
   * Confirmation modal the user will see when he has something filtered and declined to download the filtered values.
   */
  const downloadCompleteDataConfirmationModal = () => {
    modal.confirm({
      autoFocusButton: null,
      content: "All the data will be downloaded. Do you want to continue?",
      onOk() {
        exportResourcePlan(false);
      },
    });
  };

  /**
   * Confirmation modal the user will see when he filtered the table. The user has the possibility to download filtered
   * or to cancel and download complete data. After the user cancels the downloadCompleteDataConfirmationModal will open.
   */
  const downloadFilteredDataConfirmationModal = () => {
    modal.confirm({
      autoFocusButton: null,
      content: "Would you like to download the filtered data?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        exportResourcePlan(true);
      },
      onCancel() {
        downloadCompleteDataConfirmationModal();
      },
    });
  };

  /**
   * After users clicks on the download button a check will be done if the user filtered one of the tables or not.
   * If the user filtered a table then the download filtered data confirmation modal will be shown
   * otherwise the complete data will be downloaded when the user did not filter
   */
  const checkIfFilterAppliedAndDownloadExcel = () => {
    if (
      sendRequestsGridApi.isAnyFilterPresent() ||
      openRequestsGridApi.isAnyFilterPresent()
    ) {
      downloadFilteredDataConfirmationModal();
    } else {
      exportResourcePlan(false);
    }
  };

  return (
    <Button
      onClick={checkIfFilterAppliedAndDownloadExcel}
      size="large"
      type="primary"
      icon={<DownloadOutlined />}
    >
      Download as .xlsx
    </Button>
  );
};

export default ResourcePlanDownloadButton;
