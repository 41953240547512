import React, { useEffect, useState } from "react";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import "./table_settings_button.less";
import {
  getPropertyValuesOfData,
  mapColumnsToTreeData,
} from "@src/features/table_filtering/utils/filter_utils";
import { TableSettingsConfig } from "@src/types";
import { TreeNode } from "@src/types/antd_types";
import { areArraysIdentical } from "@src/utils/helper";
import { Button, Modal, Tooltip, Tree } from "antd";
import { ReactComponent as SettingsIcon } from "@src/assets/icons/settings.svg";

export interface TableSettingsButtonProps {
  disabled?: boolean;
  tableSettings?: TableSettingsConfig;
}

const TableSettingsButton: React.FC<TableSettingsButtonProps> = ({
  disabled = false,
  tableSettings,
}) => {
  const [defaultHiddenColumns, setDefaultHiddenColumns] = useState<string[]>(
    tableSettings.defaultHiddenColumns
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
  const [compactMode, setCompactMode] = useState<boolean>(false);
  const [lastSelectedColumns, setLastSelectedColumns] = useState<string[]>([]);

  function configureDisplayableColumns(columns: TreeNode[]) {
    const visibleColumns = columns.filter(
      (column) => !tableSettings?.columnsToHide?.includes(column.key)
    );

    // Set disabled property for locked and hidden columns
    visibleColumns.forEach((column) => {
      const isLocked = tableSettings?.lockedColumns?.includes(column.key);
      if (isLocked) {
        column.disabled = true;
      }
    });
    return visibleColumns;
  }

  const columnTreeData: TreeNode[] = configureDisplayableColumns(
    mapColumnsToTreeData(
      tableSettings?.columns,
      "headerName",
      tableSettings?.compactViewSettings?.compactedColumns
    )
  );

  /**
   * Checks if a column is visible based on the current table settings.
   *
   * @param {string} key - The header name of the column to check visibility for.
   * @returns {boolean} - Returns true if the column is visible, otherwise false.
   */
  function checkIfColumnIsVisible(key: string): boolean {
    const column = tableSettings?.columns.find(
      (column) => column.headerName === key
    );

    const defaultHiddenColumns = tableSettings?.compactViewSettings
      ? tableSettings?.defaultHiddenColumns?.filter((col) =>
          compactMode
            ? !tableSettings.compactViewSettings.compactedColumns.includes(col)
            : !tableSettings.compactViewSettings.expandedColumns.includes(col)
        )
      : tableSettings?.defaultHiddenColumns;

    return column?.hide !== true && !defaultHiddenColumns?.includes(key);
  }

  /**
   * Load default column keys to state if filter is not applied
   */
  useEffect(() => {
    if (!tableSettings) {
      return;
    }

    const keys: string[] = getPropertyValuesOfData(columnTreeData, "key");
    const checkedKeys =
      tableSettings?.selectedColumns?.length > 0
        ? tableSettings.selectedColumns
        : keys.filter((key: string) => checkIfColumnIsVisible(key));

    // Only update state if checkedKeys have changed
    setCheckedKeys(checkedKeys);
    setLastSelectedColumns(checkedKeys);
    tableSettings.onChange(checkedKeys);
  }, [JSON.stringify(tableSettings)]);

  /**
   * When tabs are changed the default hidden columns should be reset to the new ones
   */
  useEffect(() => {
    if (
      tableSettings.defaultHiddenColumns &&
      !areArraysIdentical(
        tableSettings.defaultHiddenColumns,
        defaultHiddenColumns
      )
    ) {
      setDefaultHiddenColumns(tableSettings.defaultHiddenColumns);
      const keys = getPropertyValuesOfData(columnTreeData, "key");
      const checkedKeys = keys.filter((key) => checkIfColumnIsVisible(key));
      setCheckedKeys(checkedKeys);
      setLastSelectedColumns(checkedKeys);
      tableSettings.onChange(checkedKeys);
    }
  }, [JSON.stringify(tableSettings.defaultHiddenColumns)]);

  useEffect(() => {
    if (tableSettings.compactViewSettings) {
      setCompactMode(tableSettings.compactViewSettings.defaultCompacted);
    }
  }, [JSON.stringify(tableSettings.compactViewSettings)]);

  function handleSettingsClick() {
    setIsModalOpen(true);
  }

  function handleOk() {
    setLastSelectedColumns(checkedKeys);
    tableSettings.onChange(checkedKeys);
    setIsModalOpen(false);
  }

  function handleCancel() {
    setCheckedKeys(lastSelectedColumns);
    setIsModalOpen(false);
  }

  function onCheck(values: string[]) {
    setCheckedKeys(values);
  }

  return (
    <>
      <Tooltip mouseEnterDelay={0.5} placement="top" title={"Table settings"}>
        <Button
          variant="filled"
          color="default"
          htmlType="submit"
          icon={<SettingsIcon />}
          disabled={disabled}
          onClick={handleSettingsClick}
          size="large"
          data-testid="settings-button"
        />
      </Tooltip>
      <Modal
        title="Table Configuration"
        width={400}
        closable={false}
        maskClosable={false}
        open={isModalOpen}
        onOk={handleOk}
        okText="Save"
        okButtonProps={{
          icon: <CheckOutlined />,
          size: "large",
        }}
        onCancel={handleCancel}
        cancelButtonProps={{
          icon: <CloseOutlined />,
          size: "large",
        }}
        data-testid="table-settings-modal"
        className="table-settings-modal"
      >
        <h5 className="table-settings-headline">Active Table Cols</h5>
        <div className="divider" />
        <Tree
          checkable
          defaultExpandAll
          virtual={false}
          motion={false}
          onCheck={onCheck}
          checkedKeys={checkedKeys}
          treeData={columnTreeData}
          selectable={false}
          data-testid="table-cols-tree"
          className="table-cols-checkbox-list"
        />
      </Modal>
    </>
  );
};

export default TableSettingsButton;
