import { COLORS, ROLES } from "@src/constants";
import RequestedRoleRenderer from "@src/custom_renderer/requested_role_renderer/requested_role_renderer";
import { StatusRenderer } from "@src/custom_renderer/status_renderer";
import { FrameworkComponents } from "@src/types/aggrid_types";
import { ResourcePlanTableRowData } from "@src/types/role_request_types";
import {
  columnTypes,
  paginationGridOptions,
  preventPinnedColumnJumpingGridOption,
} from "@src/utils/aggrid_utils";
import { getAllocationColumnDefs } from "@src/utils/allocation_column_def_utils";
import {
  editableColumnDef,
  editableGridOptions,
  editAllocationCellClassRules,
  editAllocationValueGetter,
  editAllocationValueSetter,
} from "@src/utils/edit_allocation_utils";
import {
  ColDef,
  GetQuickFilterTextParams,
  GetRowIdParams,
  GridOptions,
  ValueGetterParams,
  ValueSetterParams,
} from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { ExcelStyle } from "ag-grid-community/dist/types/core/interfaces/iExcelCreator";

const excelStyles: ExcelStyle[] = [
  {
    id: "header",
    alignment: {
      vertical: "Center",
    },
    interior: {
      color: COLORS.SAND_40,
      pattern: "Solid",
    },
    font: {
      bold: true,
      size: 13,
    },
    borders: {
      borderBottom: {
        color: COLORS.SAND_70,
        lineStyle: "Continuous",
        weight: 1,
      },
      borderRight: {
        color: COLORS.SAND_70,
        lineStyle: "Continuous",
        weight: 1,
      },
    },
  },
];

export function getGridOptions(): GridOptions {
  return {
    ...editableGridOptions,
    ...paginationGridOptions,
    ...preventPinnedColumnJumpingGridOption,
    getRowId: (params: GetRowIdParams<ResourcePlanTableRowData>) => {
      return params.data.roleRequestId.toString();
    },
    rowHeight: 50,
    domLayout: "autoHeight",
    suppressContextMenu: true,
    groupHeaderHeight: 26,
    headerHeight: 26,
    components: getComponents(),
    excelStyles: excelStyles,
    defaultExcelExportParams: {
      freezeRows: "headers",
    },
    statusBar: {
      statusPanels: [{ statusPanel: "agFilteredRowCountComponent" }],
    },
    defaultColDef: {
      ...columnTypes.default,
      resizable: false,
    },
    context: {
      view: ROLES.PROJECT_LEAD,
    },
    rowSelection: {
      mode: "multiRow",
    },
    selectionColumnDef: {
      pinned: "left",
    },
  };
}

function getComponents(): FrameworkComponents {
  return {
    requestedRoleRenderer: (props: CustomCellRendererProps) => {
      return <RequestedRoleRenderer props={props} />;
    },
    statusRenderer: (props: CustomCellRendererProps) => {
      return <StatusRenderer props={props} />;
    },
  };
}

/**
 * Get column definitions for the resource plan table
 * @param endDate
 */
export function getColumnDefs(
  endDate: Date
): ColDef<ResourcePlanTableRowData>[] {
  return [
    {
      field: "roleRequestId",
      headerName: "Request id",
      pinned: "left",
      hide: true,
    },
    {
      field: "technologies",
      headerName: "Technology",
      pinned: "left",
      hide: true,
    },
    {
      field: "standardRoleName",
      headerName: "Requested Role",
      cellRenderer: "requestedRoleRenderer",
      pinned: "left",
      filter: "agSetColumnFilter",
      suppressFillHandle: true,
      minWidth: 370,
      maxWidth: 400,
      getQuickFilterText: (
        params: GetQuickFilterTextParams<ResourcePlanTableRowData>
      ) => {
        return `${params.data.roleRequestId} ${params.data.standardRoleName}`;
      },
    },
    {
      field: "jointVenture",
      headerName: "JV",
      pinned: "left",
      suppressFillHandle: true,
      hide: true,
    },
    {
      field: "projectIdentifier",
      headerName: "Project Identifier",
      pinned: "left",
      suppressFillHandle: true,
      minWidth: 128,
      maxWidth: 150,
    },
    {
      field: "specificRole",
      headerName: "Specific Role",
      pinned: "left",
      suppressFillHandle: true,
      minWidth: 128,
      maxWidth: 150,
    },
    {
      field: "scope",
      headerName: "Scope",
      pinned: "left",
      suppressFillHandle: true,
      minWidth: 128,
      maxWidth: 150,
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: "statusRenderer",
      pinned: "left",
      filter: "agSetColumnFilter",
      suppressFillHandle: true,
      minWidth: 128,
      maxWidth: 150,
    },
    ...getTeamMemberColumnDefs(),
    ...getTeamLeadColumns(),
    ...getAllocationColumnDefs(new Date(), endDate, getAllocationColDef),
  ];
}

/**
 *  Get team member column definitions. These columns are hidden by default and are used for exporting the data to excel.
 */
function getTeamMemberColumnDefs(): ColDef<ResourcePlanTableRowData>[] {
  return [
    {
      field: "assignedEmployee.fullName",
      headerName: "Team member",
      pinned: "left",
      hide: true,
    },
    {
      field: "assignedEmployee.contractType",
      headerName: "Contract type",
      pinned: "left",
      hide: true,
    },
    {
      field: "assignedEmployee.totalAvailablePercentage",
      headerName: "Contract availability",
      pinned: "left",
      hide: true,
    },
    {
      field: "assignedEmployee.rcdNumber",
      headerName: "RCD Team member",
      pinned: "left",
      hide: true,
    },
    {
      field: "assignedEmployee.uiNumber",
      headerName: "UI Team member",
      pinned: "left",
      hide: true,
    },
    {
      field: "assignedEmployee.departmentCode",
      headerName: "Team member Org-Code",
      pinned: "left",
      hide: true,
    },
  ];
}

/**
 * Get team lead column definitions. These columns are hidden by default and are used for exporting the data to excel.
 */
function getTeamLeadColumns(): ColDef<ResourcePlanTableRowData>[] {
  return [
    {
      field: "teamLead.fullName",
      headerName: "Team lead",
      pinned: "left",
      hide: true,
    },
    {
      field: "teamLead.rcdNumber",
      headerName: "RCD Team lead",
      pinned: "left",
      hide: true,
    },
    {
      field: "teamLead.uiNumber",
      headerName: "UI Team lead",
      pinned: "left",
      hide: true,
    },
    {
      field: "teamLead.departmentCode",
      headerName: "Team lead Org-Code",
      pinned: "left",
      hide: true,
    },
    {
      field: "teamLead.costCenter",
      headerName: "Cost center",
      pinned: "left",
      hide: true,
    },
  ];
}

function getAllocationColDef(year: number, month: number): ColDef {
  return {
    ...editableColumnDef,
    cellClassRules: editAllocationCellClassRules,
    valueSetter: (params: ValueSetterParams<ResourcePlanTableRowData>) =>
      editAllocationValueSetter(params, year, month),
    valueGetter: (params: ValueGetterParams<ResourcePlanTableRowData>) =>
      editAllocationValueGetter(params, year, month),
  };
}
