import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseTableFilters, BaseTableState } from "@src/types";
import { TabItem } from "@src/types/antd_types";
import { RoleRequest } from "@src/types/role_request_types";

export enum RESOURCE_PLAN_FILTER_TYPES_V2 {
  STANDARD_ROLE_NAMES = "standardRoleName",
  STATUS = "status",
  JOINT_VENTURE = "jointVenture",
}

export interface ResourcePlanFilters extends BaseTableFilters {
  [RESOURCE_PLAN_FILTER_TYPES_V2.STANDARD_ROLE_NAMES]: string[];
  [RESOURCE_PLAN_FILTER_TYPES_V2.STATUS]: string[];
}

export type ResourcePlanTableFiltersV2 = BaseTableState<
  RoleRequest,
  ResourcePlanFilters
>;

export interface ResourcePlanStateV2 {
  projectId: string;
  resourcePlanTableState: ResourcePlanTableFiltersV2;
}

const initialState: ResourcePlanStateV2 = {
  projectId: "",
  resourcePlanTableState: {
    quickFilter: "",
    filterResult: [],
    jointVentureFilter: false,
    customTableFilters: {
      standardRoleName: [],
      status: [],
    },
    columns: [],
    currentTabConfig: null,
    isFilterApplied: false,
  },
};

/**
 * This function is used to check if the filter is applied
 *
 * @param {ResourcePlanStateV2} state - The state of the table.
 * @returns {boolean} true if the filter is applied, false otherwise
 */
function checkIfFilterApplied(state: ResourcePlanStateV2): boolean {
  const { quickFilter, customTableFilters, jointVentureFilter } =
    state.resourcePlanTableState;
  const customFilters = Object.values(customTableFilters).flat();
  return quickFilter !== "" || customFilters.length > 0 || jointVentureFilter;
}

const resourcePlanSliceV2 = createSlice({
  name: "resourcePlanSliceV2",
  initialState,
  reducers: {
    /**
     * Sets the project id state to the new state.
     *
     * @param {ResourcePlanStateV2} state - The request overview filter state with all values.
     * @param {PayloadAction<string>} projectId - The project id string.
     */
    setProjectId: (
      state: ResourcePlanStateV2,
      projectId: PayloadAction<string>
    ): void => {
      state.projectId = projectId.payload;
    },

    /**
     * Sets the quick filter state to the new state.
     *
     * @param {ResourcePlanStateV2} state - The resource plan filter state with all values.
     * @param {PayloadAction<string>} quickSearch - The quick search string.
     */
    setQuickFilter: (
      state: ResourcePlanStateV2,
      quickSearch: PayloadAction<string>
    ): void => {
      state.resourcePlanTableState.quickFilter = quickSearch.payload;
      state.resourcePlanTableState.isFilterApplied =
        checkIfFilterApplied(state);
    },

    /**
     * Sets the custom table filter state to the new state.
     *
     * @param {ResourcePlanStateV2} state - The resource plan filter state with all values.
     * @param {PayloadAction<ResourcePlanFilters>} customTableFilters - The custom table filters object with all filters.
     */
    setCustomTableFilter: (
      state: ResourcePlanStateV2,
      customTableFilters: PayloadAction<ResourcePlanFilters>
    ): void => {
      state.resourcePlanTableState.customTableFilters =
        customTableFilters.payload;
      state.resourcePlanTableState.isFilterApplied =
        checkIfFilterApplied(state);
    },

    /**
     * Sets the active tab to the new state.
     *
     * @param state       The resource plan filter state with all values.
     * @param activeTab   The active tab to be set.
     */
    setActiveTab: (
      state: ResourcePlanStateV2,
      activeTab: PayloadAction<string>
    ): void => {
      state.resourcePlanTableState.currentTabConfig.key = activeTab.payload;
    },

    /**
     * Sets the joint Venture filter state to the new state
     *
     * @param {ResourcePlanStateV2} state - The resource plan filter state with all values.
     * @param {PayloadAction<boolean>} jointVenture - The joint venture filter value.
     */
    setJointVentureFilter: (
      state: ResourcePlanStateV2,
      jointVenture: PayloadAction<boolean>
    ): void => {
      state.resourcePlanTableState.jointVentureFilter = jointVenture.payload;
      state.resourcePlanTableState.isFilterApplied =
        checkIfFilterApplied(state);
    },

    /**
     * Sets the columns to the new state.
     *
     * @param {ResourcePlanStateV2} state - The resource plan filter state with all values.
     * @param {PayloadAction<string[]>} columns - The columns to be set.
     */
    setColumns: (
      state: ResourcePlanStateV2,
      columns: PayloadAction<string[]>
    ): void => {
      state.resourcePlanTableState.columns = columns.payload;
    },

    /**
     * Sets the current tab config to the new state.
     *
     * @param {ResourcePlanStateV2} state - The resource plan filter state with all values.
     * @param {PayloadAction<TabItem>} tabConfig - The config for the Tab
     */
    setCurrentTabConfig: (
      state: ResourcePlanStateV2,
      tabConfig: PayloadAction<TabItem>
    ): void => {
      state.resourcePlanTableState.currentTabConfig = tabConfig.payload;
    },

    /**
     * Clears a specific property from the custom table filter.
     *
     * @param {ResourcePlanStateV2} state - The resource plan filter state with all values.
     * @param {Object} action - Contains the key and value to be cleared.
     * @returns {ResourcePlanStateV2} - Updated state with the specified filter value removed.
     */
    clearCustomTableFilterProperty: (
      state: ResourcePlanStateV2,
      action: { payload: { key: string; value: string } }
    ): ResourcePlanStateV2 => {
      const { key, value } = action.payload;
      return {
        ...state,
        resourcePlanTableState: {
          ...state.resourcePlanTableState,
          customTableFilters: {
            ...state.resourcePlanTableState.customTableFilters,
            [key]: state.resourcePlanTableState.customTableFilters[key]?.filter(
              (item: string) => item !== value
            ),
          },
        },
      };
    },

    /**
     * Clears a joint venture value from the custom table filter.
     *
     * @param {ResourcePlanStateV2} state - The resource plan filter state with all values.
     * @returns {ResourcePlanStateV2} - Updated state with the specified filter value removed.
     */
    clearJointVentureFilter: (
      state: ResourcePlanStateV2
    ): ResourcePlanStateV2 => {
      return {
        ...state,
        resourcePlanTableState: {
          ...state.resourcePlanTableState,
          jointVentureFilter: false,
        },
      };
    },

    /**
     * Clears the filter state to the initial state.
     *
     * @param {ResourcePlanStateV2} state - The resource plan filter state with all values.
     * @returns {ResourcePlanStateV2} - Updated state with the specified filter value removed.
     */
    clearFilter: (state: ResourcePlanStateV2): ResourcePlanStateV2 => {
      return {
        ...state,
        resourcePlanTableState: {
          ...initialState.resourcePlanTableState,
          columns: state.resourcePlanTableState.columns, // preserve the columns
          quickFilter: state.resourcePlanTableState.quickFilter, // preserve the quick filter
          currentTabConfig: state.resourcePlanTableState.currentTabConfig, // preserve the current tab config
        },
      };
    },

    /**
     * Clears the filter state but preserves the project id.
     *
     * @param {ResourcePlanStateV2} state - The resource plan filter state with all values.
     * @returns {ResourcePlanStateV2} - Updated state with the specified filter value removed.
     */
    clearFilterSaveProjectId: (
      state: ResourcePlanStateV2
    ): ResourcePlanStateV2 => {
      return {
        ...initialState,
        projectId: state.projectId, // preserve the project id
        resourcePlanTableState: {
          ...initialState.resourcePlanTableState,
          currentTabConfig: state.resourcePlanTableState.currentTabConfig, // preserve the current tab config
        },
      };
    },
  },
});

export const {
  setProjectId,
  setQuickFilter,
  setCustomTableFilter,
  setJointVentureFilter,
  setColumns,
  setCurrentTabConfig,
  setActiveTab,
  clearCustomTableFilterProperty,
  clearJointVentureFilter,
  clearFilter,
  clearFilterSaveProjectId,
} = resourcePlanSliceV2.actions;

export default resourcePlanSliceV2;
