import { App, Button, Tooltip } from "antd";
import React from "react";
import { ReactComponent as DownloadIcon } from "@src/assets/icons/download.svg";

export interface IDownloadButton {
  path: string;
  disabled?: boolean;
  className?: string;
  method?: string;
  completeData?: any;
  filteredData?: any;
}

const DownloadButton = ({
  path,
  disabled,
  className,
  method = "get",
  completeData,
  filteredData,
}: IDownloadButton) => {
  const { modal } = App.useApp();

  const { REACT_APP_SERVER_URL, NODE_ENV } = process.env;
  const serverUrl =
    NODE_ENV === "development"
      ? REACT_APP_SERVER_URL + "api"
      : window.location.protocol + "//" + window.location.host + "/api";

  function downloadData(dataToDownload) {
    const token = localStorage.getItem("token");
    let fileName;

    fetch(`${serverUrl}${path}`, {
      method: method || "get",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToDownload),
    })
      .then((res) => {
        fileName = res.headers.get("Content-Disposition");
        return res.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        return Promise.reject(new Error(`Something went wrong: ${err}`));
      });
  }

  const confirmDownload = () => {
    modal.confirm({
      autoFocusButton: null,
      content: "All the data will be downloaded. Do you want to continue?",
      onOk() {
        downloadData(completeData);
      },
    });
  };

  const exportFilteredData = () => {
    if (
      filteredData?.length > 0 &&
      filteredData?.length !== completeData?.length
    ) {
      modal.confirm({
        autoFocusButton: null,
        content: "Would you like to download the filtered data?",
        okText: "Yes",
        cancelText: "No",
        onOk() {
          downloadData(filteredData);
        },
        onCancel() {
          confirmDownload();
        },
      });
    } else {
      downloadData(completeData);
    }
  };

  return (
    <Tooltip mouseEnterDelay={0.5} placement="top" title={"Download as .xlsx"}>
      <Button
        color="default"
        variant="filled"
        htmlType="submit"
        icon={<DownloadIcon />}
        disabled={disabled}
        onClick={exportFilteredData}
        size="large"
        data-testid="download-button"
      />
    </Tooltip>
  );
};

export default DownloadButton;
