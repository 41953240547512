import React, { useState } from "react";
import { useGetProjectLeadsQuery } from "@src/services/slices/employeesSlice";
import {
  useGetProjectEditorsByProjectIdQuery,
  useUpdateProjectEditorsMutation,
} from "@src/services/slices/projectsApi";
import { Employee } from "@src/types";
import { Button, Form, message, Modal, Select, Skeleton, Tooltip } from "antd";
import "./editor_button.less";
import _ from "lodash";
import { ReactComponent as UserIcon } from "@src/assets/icons/profile.svg";

interface EditorButtonProps {
  disabled?: boolean;
  projectId: string;
  isUserProjectManager: boolean;
}

const EditorButton: React.FC<EditorButtonProps> = ({
  disabled,
  projectId,
  isUserProjectManager,
}) => {
  const { data: projectLeads, isSuccess } = useGetProjectLeadsQuery();
  const { data: projectEditors } =
    useGetProjectEditorsByProjectIdQuery(projectId);

  const [updateProjectEditor] = useUpdateProjectEditorsMutation();

  const [form] = Form.useForm();

  const [editorUpdated, setEditorUpdated] = useState<boolean>(false);
  const [showEditorModal, setShowEditorModal] = useState<boolean>(false);

  const editorsIds: number[] = projectEditors?.map((editor: Employee) => {
    return editor.employeeId;
  });

  /**
   * Save project editors
   */
  async function onUpdateEditors(): Promise<void> {
    const projectEditorIds: number[] = form.getFieldValue("editors");

    await onUpdateProjectEditor(projectId, projectEditorIds);

    setShowEditorModal(false);
    setEditorUpdated(false);
  }

  /**
   * Update project editor
   *
   * @param {string} projectId The project id to update the editors for
   * @param {string[]} projectEditorIds The project editor ids to update
   */
  async function onUpdateProjectEditor(
    projectId: string,
    projectEditorIds: number[]
  ): Promise<void> {
    try {
      await updateProjectEditor({ projectId, projectEditorIds }).unwrap();
      message.success(
        "Editor(s) successfully updated project editors of the project."
      );
    } catch (err) {
      console.log("Error updating project editor");
    }
  }

  /**
   * On change event
   */
  function onSelectionChanged(): void {
    const selectedEditorIds: number[] = form.getFieldValue("editors").sort();
    const initialEditorIds: number[] = editorsIds.sort();
    const areEditorsUpdated = _.isEqual(initialEditorIds, selectedEditorIds);

    setEditorUpdated(!areEditorsUpdated);
  }

  return (
    <div className="editor-button">
      <Tooltip
        mouseEnterDelay={0.5}
        placement="top"
        title={
          !isUserProjectManager
            ? "Only Project Manager is allowed to edit Editors. Please contact them for changes."
            : "Editor Settings"
        }
      >
        <Button
          icon={<UserIcon />}
          size="large"
          color="default"
          variant="filled"
          onClick={() => setShowEditorModal(true)}
          disabled={disabled}
        />
      </Tooltip>
      <Modal
        open={showEditorModal}
        centered
        maskClosable={false}
        closable={false}
        title="Project Editors"
        okText="Save Editors"
        okButtonProps={{ disabled: !isSuccess || !editorUpdated }}
        onOk={onUpdateEditors}
        onCancel={() => {
          form.setFieldValue("editors", editorsIds);
          setShowEditorModal(false);
          setEditorUpdated(false);
        }}
      >
        {!isSuccess ? (
          <Skeleton.Input active={true} size="large" block={true} />
        ) : (
          <Form
            form={form}
            layout="vertical"
            initialValues={{ editors: editorsIds }}
          >
            <Form.Item label="Editor(s)" name="editors">
              <Select
                placeholder="please select a editor"
                showSearch
                allowClear
                onChange={onSelectionChanged}
                className="editor-button__editor-select"
                mode="multiple"
                optionFilterProp="label"
                options={projectLeads?.map((teamLead: Employee) => ({
                  value: teamLead.employeeId,
                  label: teamLead.fullName,
                }))}
              />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
};

export default EditorButton;
