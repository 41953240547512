import { AllocationDetails, RequestOverviewDetails } from "@src/types";
import {
  Allocation,
  StaffingRequestTableData,
} from "@src/types/role_request_types";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";

/**
 * Calculates the start and end date that will be used for the allocation column definitions
 * Takes the child request, if it exists.
 *
 * @param {RequestOverviewDetails} request the request that will be considered
 * @returns start and end date of the request
 */
export function getWorkloadDates(request: RequestOverviewDetails): {
  startDate: Date | null;
  endDate: Date | null;
} {
  const currentRequest = request.childRequest ?? request;

  const workloadStartDate: Date = currentRequest.roleAllocationDetails[0]?.date;
  const workloadEndDate: Date = _.last(
    currentRequest.roleAllocationDetails
  )?.date;

  const endDate = getEndDate(workloadStartDate, workloadEndDate);

  return {
    startDate: workloadStartDate ? dayjs(workloadStartDate).toDate() : null,
    endDate: endDate ? dayjs(endDate).toDate() : null,
  };
}

/**
 * ensuring the end date for the table is at least 10 months in the future of the workload start date.
 *
 * @param workloadStartDate allocations first date
 * @param workloadEndDate allocations last date
 * @returns The adjusted project end date as a JavaScript Date object.
 */
export function getEndDate(
  workloadStartDate: Date,
  workloadEndDate: Date
): Date {
  const startDate: Date =
    !workloadStartDate || dayjs(workloadStartDate).isBefore(dayjs())
      ? new Date()
      : workloadStartDate;

  // Ensure end date is at least 10 months after the start date
  const minEndDateDayjs: Dayjs = dayjs(startDate)
    .add(9, "months")
    .endOf("month");

  return !workloadEndDate || dayjs(workloadEndDate).isBefore(minEndDateDayjs)
    ? minEndDateDayjs.toDate()
    : workloadEndDate;
}

function getAllocations(request: RequestOverviewDetails): Allocation[] {
  const parentAllocations = request.roleAllocationDetails;
  const childAllocations = request.childRequest?.roleAllocationDetails;

  const allocations: Allocation[] = [];
  if (childAllocations) {
    const childBasedAllocations: Allocation[] = buildChildBasedAllocations(
      childAllocations,
      parentAllocations
    );
    // For each parent month not covered by a child allocation, create a "removed" entry.
    const missingAllocations: Allocation[] = buildRemovedAllocations(
      childBasedAllocations,
      parentAllocations
    );
    // Combine both sets of allocations
    allocations.push(...childBasedAllocations, ...missingAllocations);
  } else if (parentAllocations) {
    allocations.push(...buildParentBasedAllocations(parentAllocations));
  }

  return allocations;
}

/**
 * Generates row data from yearly allocations.
 *
 * @param request - The request object containing yearly allocations and proposed allocations.
 * @param isRequested - A boolean indicating whether the request is in a requested state.
 * @returns An array of allocation objects with dates and percentages.
 */
export function mapRowData(
  request: RequestOverviewDetails,
  isRequested: boolean
): StaffingRequestTableData[] {
  if (
    !request.roleAllocationDetails ||
    request.roleAllocationDetails.length === 0
  ) {
    return [];
  }

  const allocations: Allocation[] = getAllocations(request);
  // Create a map for quick lookup of requiredPercentage by date
  const allocationMap = new Map(
    allocations.map((allocation: Allocation) => [
      allocation.date.toISOString(),
      allocation.requiredPercentage,
    ])
  );

  const proposedAllocations: Allocation[] = Object.entries(
    request.proposedYearlyAllocations
  ).flatMap(([year, months]) =>
    Object.entries(months).map(([month, proposedPercentage]) => {
      const date = dayjs(`${year}-${month}-01`).toDate();
      return {
        date,
        proposedPercentage,
        proposalMatches:
          allocationMap.has(date.toISOString()) &&
          allocationMap.get(date.toISOString()) === proposedPercentage,
      };
    })
  );

  const rowData: StaffingRequestTableData[] = [
    {
      label: "Requested Workload",
      allocations,
    },
  ];

  if (!isRequested) {
    rowData.push({
      label: "Proposed",
      proposedName: request?.assignedTeamMember,
      allocations: proposedAllocations,
    });
  }

  return rowData;
}

/**
 * Builds the array of parent based allocations.
 *
 * @param parentAllocations the parent allocations
 * @returns {Allocation[]}
 */
function buildParentBasedAllocations(
  parentAllocations: AllocationDetails[]
): Allocation[] {
  return parentAllocations.map((parent: AllocationDetails) => {
    const parentDate: Dayjs = dayjs(parent.date);
    return toAllocation(parent.requiredPercentage, undefined, parentDate);
  });
}

/**
 * Builds the array of child based allocations.
 * The child allocations are mapped to the parent allocations based on the date.
 *
 * @param childAllocations the child allocations
 * @param parentAllocations the parent allocations
 * @returns {Allocation[]}
 */
function buildChildBasedAllocations(
  childAllocations: AllocationDetails[],
  parentAllocations: AllocationDetails[]
): Allocation[] {
  return childAllocations.map((child) => {
    const childDate: Dayjs = dayjs(child.date);
    const parentRequiredPercentage: number =
      findMathingAllocation(parentAllocations, childDate)?.requiredPercentage ??
      0;

    const allocation: Allocation = toAllocation(
      parentRequiredPercentage,
      undefined,
      childDate
    );

    // If child's required differs from parent's, track it
    if (child.requiredPercentage !== parentRequiredPercentage) {
      allocation.newRequiredPercentage = child.requiredPercentage;
    }

    return allocation;
  });
}

/**
 * Builds the array of removed allocations.
 * If a child allocation is not found in the parent allocations, it is treated as removed.
 *
 * @param childBasedAllocations the child allocations
 * @param parentAllocations the parent allocations
 * @returns {Allocation[]}
 */
function buildRemovedAllocations(
  childBasedAllocations: Allocation[],
  parentAllocations: AllocationDetails[]
): Allocation[] {
  const missingAllocations: Allocation[] = [];

  parentAllocations.forEach((parent: AllocationDetails) => {
    const parentDate: Dayjs = dayjs(parent.date);
    const hasChild: boolean = childBasedAllocations.some(
      (child: Allocation) => dayjs(child.date).isSame(parentDate, "month") // checks year+month
    );

    // If no matching child, treat as removed
    if (!hasChild) {
      const missingAllocation: Allocation = toAllocation(
        parent.requiredPercentage,
        0,
        parentDate
      );
      missingAllocations.push(missingAllocation);
    }
  });

  return missingAllocations;
}

/**
 * Finds the matching allocation in the allocations array.
 * If no matching allocation is found, undefined is returned.
 *
 * @param allocations the allocations array
 * @param date the date to find the allocation for
 * @returns {AllocationDetails | undefined}
 */
function findMathingAllocation(
  allocations: AllocationDetails[],
  date: Dayjs
): AllocationDetails | undefined {
  return allocations.find(
    (allocation: AllocationDetails) =>
      dayjs(allocation.date).year() === date.year() &&
      dayjs(allocation.date).month() === date.month()
  );
}

/**
 * Converts the requiredPercentage, newRequiredPercentage and date to an Allocation object.
 * If newRequiredPercentage is not provided, it is set to undefined.
 *
 * @param requiredPercentage the required percentage
 * @param newRequiredPercentage the new required percentage
 * @param date the date
 * @returns {Allocation}
 */
function toAllocation(
  requiredPercentage: number,
  newRequiredPercentage: number | undefined,
  date: Dayjs
): Allocation {
  const isNewRequiredANumber: boolean =
    typeof newRequiredPercentage === "number" && newRequiredPercentage >= 0;
  return {
    date: dayjs(`${date.year()}-${date.month() + 1}-01`).toDate(),
    requiredPercentage,
    ...(isNewRequiredANumber && { newRequiredPercentage }),
  };
}
