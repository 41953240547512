// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.propose-team-member-overview-table {
  width: 100%;
  height: 375px;
}
.propose-team-member-overview-table .ag-header-span-height {
  left: 0 !important;
}
.propose-team-member-overview-table .ag-center-cols-container .ag-row-selected .ag-cell {
  border-right: 1px solid #cbccce !important;
}
.propose-team-member-overview-table .ag-row-selected {
  border-top: 1px solid #cbccce !important;
  border-bottom: 1px solid #cbccce !important;
  background-color: #eeeeef !important;
}
.propose-team-member-overview-table .ag-row-selected:not(.ag-row-even) {
  background-color: #eeeeef !important;
}
.propose-team-member-overview-table .ag-pinned-left-floating-top .ag-cell {
  color: #1d4477;
  font-family: RWESansWeb-Medium;
}
`, "",{"version":3,"sources":["webpack://./src/features/staffing_request_details/components/propose_team_member_overview_table/propose_team_member_overview_table.less"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;AADF;AAII;EACE,kBAAA;AAFN;AAKI;EAGM,0CAAA;AALV;AAWM;EACE,wCAAA;EACA,2CAAA;EACA,oCAAA;AATR;AAYM;EACE,oCAAA;AAVR;AAcI;EAEI,cAAA;EACA,8BAAA;AAbR","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.propose-team-member-overview-table {\n  width: 100%;\n  height: 375px;\n\n  .ag {\n    &-header-span-height {\n      left: 0 !important;\n    }\n\n    &-center-cols-container {\n      .ag-row-selected {\n        .ag-cell {\n          border-right: 1px solid @silver-veil !important;\n        }\n      }\n    }\n\n    &-row {\n      &-selected {\n        border-top: 1px solid @silver-veil !important;\n        border-bottom: 1px solid @silver-veil !important;\n        background-color: @dark-grey-10 !important;\n      }\n\n      &-selected:not(.ag-row-even) {\n        background-color: @dark-grey-10 !important;\n      }\n    }\n\n    &-pinned-left-floating-top {\n      .ag-cell {\n        color: @brand;\n        font-family: RWESansWeb-Medium;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
