import React from "react";

import { ROUTES } from "@src/constants";
import useFeatureToggle from "@src/hooks/use_feature_toggle";
import { AdminProjectAssignmentsPage } from "@src/pages/admin/admin_project_assignments_page";
import { AdminRoleAssignmentPage } from "@src/pages/admin/admin_role_assignment_page";
import { EmployeeDataPage } from "@src/pages/admin/employee_data_page";
import { ImportResourcePlanPage } from "@src/pages/admin/import_resource_plan_page";
import { RegionAssignmentPage } from "@src/pages/admin/region_assignment_page";
import { RoleAssignmentPage } from "@src/pages/admin/role_assignment_page";
import { SapCsvUploadPage } from "@src/pages/admin/sap_csv_upload_page";
import { CreateProjectPage } from "@src/pages/create_project_page";
import { DashBoardPage } from "@src/pages/dashboard";
import { ProjectPage } from "@src/pages/project_page";
import { ProjectPageV2 } from "@src/pages/project_page_v2";
import { ProjectsOverviewPage } from "@src/pages/projects_overview_page";
import { StaffingRequestOverviewPage } from "@src/pages/staffing_requests_overview_page";
import { StartScreenPage } from "@src/pages/start_screen_page";
import { TeamLeadSuggestTeamMemberPage } from "@src/pages/team_lead_suggest_team_member_page";
import { TeamMemberOverviewPage } from "@src/pages/team_member_overview_page";
import { Route, Switch } from "react-router-dom";

const Router: React.FC = () => {
  const isPLMergedTableEnabled: boolean = useFeatureToggle("plRedesign");

  return (
    <Switch>
      <Route path="/" exact>
        <StartScreenPage />
      </Route>
      <Route path={`${ROUTES.PROJECT_LEAD.PROJECTS_CREATE}/:id?`} exact>
        <CreateProjectPage />
      </Route>
      <Route path={`${ROUTES.PROJECT_LEAD.PROJECTS}/:id`} exact>
        {isPLMergedTableEnabled ? <ProjectPageV2 /> : <ProjectPage />}
      </Route>
      <Route path={ROUTES.PROJECT_LEAD.PROJECTS} exact>
        <ProjectsOverviewPage />
      </Route>
      <Route path={ROUTES.ADMIN.REGION_ASSIGNMENT}>
        <RegionAssignmentPage />
      </Route>
      <Route path={ROUTES.MANAGER.DASHBOARD}>
        <DashBoardPage />
      </Route>
      <Route path={ROUTES.ADMIN.ROLE_ASSIGNMENT_V2}>
        <RoleAssignmentPage />
      </Route>
      <Route path={ROUTES.ADMIN.ROLE_ASSIGNMENT}>
        <AdminRoleAssignmentPage />
      </Route>
      <Route path={`${ROUTES.ADMIN.PROJECT_ASSIGNMENTS_IMPORT}`}>
        <ImportResourcePlanPage />
      </Route>
      <Route path={ROUTES.ADMIN.PROJECT_ASSIGNMENTS}>
        <AdminProjectAssignmentsPage />
      </Route>
      <Route path={ROUTES.ADMIN.EMPLOYEE_DATA}>
        <EmployeeDataPage />
      </Route>
      <Route path={ROUTES.ADMIN.SAP}>
        <SapCsvUploadPage />
      </Route>
      <Route path={`${ROUTES.TEAM_LEAD.STAFFING_REQUESTS_DETAILS}/:id`}>
        <TeamLeadSuggestTeamMemberPage />
      </Route>
      <Route path={ROUTES.TEAM_LEAD.STAFFING_REQUESTS}>
        <StaffingRequestOverviewPage />
      </Route>
      <Route path={ROUTES.TEAM_LEAD.TEAM_OVERVIEW}>
        <TeamMemberOverviewPage />
      </Route>
      <Route path="*">
        <StartScreenPage />
      </Route>
    </Switch>
  );
};

export default Router;
