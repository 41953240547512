import React from "react";

import { PlusOutlined } from "@ant-design/icons";
import { ReactComponent as UserIcon } from "@src/assets/icons/profile.svg";
import { setActiveTab } from "@src/services/resourcePlanSliceV2";
import { useSaveRequestV2Mutation } from "@src/services/slices/projectsApi";
import { useAppDispatch } from "@src/setupStore";
import { StandardRole } from "@src/types";
import "./standard_role_list.less";
import { GridApi } from "ag-grid-community";
import { App, Button, List, Tooltip, Popover } from "antd";
import { mapRoleRequestData } from "@src/features/resource_plan_v2/utils/resource_plan_table_utils";
import { CreateRoleRequestPayload } from "@src/types/role_request_types";

interface StandardRoleListProps {
  standardRoles: StandardRole[];
  projectId: string;
  gridApi: GridApi;
  onClose: () => void;
}

const StandardRoleList: React.FC<StandardRoleListProps> = ({
  standardRoles,
  projectId,
  gridApi,
  onClose,
}) => {
  const { message } = App.useApp();

  const [loading, setLoading] = React.useState<boolean>(false);

  const [saveRequest] = useSaveRequestV2Mutation();
  const dispatch = useAppDispatch();

  /**
   * Check if team lead is assigned to the role
   * @param standardRole  Standard role to check if team lead is assigned
   */
  function isTeamLeadAssigned(standardRole: StandardRole): boolean {
    return !!standardRole.teamLeadFullName;
  }

  /**
   * Add role request as a draft
   * @param roleRequest   Standard role to be added as a role request
   */
  async function addRole(roleRequest: StandardRole): Promise<void> {
    if (loading) return;
    setLoading(true);

    const payload: CreateRoleRequestPayload = {
      genericRoleId: roleRequest.standardRoleId,
    };

    try {
      // switch to drafts tab
      dispatch(setActiveTab("drafts"));

      const roleRequest = await saveRequest({
        projectId: projectId,
        request: payload,
      }).unwrap();

      const mappedRoleRequest = mapRoleRequestData([roleRequest]);
      gridApi.applyTransaction({ add: mappedRoleRequest, addIndex: 0 });
      gridApi.flashCells({
        rowNodes: [gridApi.getDisplayedRowAtIndex(0)],
        flashDuration: 2000,
      });

      message.success("Request added successfully");
      onClose();
      setLoading(false);
    } catch (error) {
      console.error("Error saving request", error);
    }
  }

  return (
    <List
      className="standard-role-list"
      itemLayout="horizontal"
      dataSource={standardRoles}
      loading={loading}
      renderItem={(standardRole: StandardRole) => (
        <List.Item
          actions={[
            <Tooltip
              key={standardRole.standardRoleId}
              title={
                !standardRole?.teamLeadFullName &&
                "No team lead assigned to the role, contact administrator for further details"
              }
            >
              <Button
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                disabled={!isTeamLeadAssigned(standardRole)}
                onClick={() => addRole(standardRole)}
              />
            </Tooltip>,
          ]}
        >
          <Popover
            key={standardRole.standardRoleId}
            placement="left"
            className="standard-role-item"
            title={standardRole.standardRoleName}
            overlayStyle={{ maxWidth: "500px", whiteSpace: "pre-line" }}
            content={standardRole?.description || "No description"}
          >
            <List.Item.Meta
              title={standardRole.basicRole?.basicRoleName}
              description={
                <>
                  <div>
                    {standardRole.roleSpecification?.roleSpecificationName}
                  </div>
                  <div>
                    <UserIcon className="user-icon" />{" "}
                    {standardRole?.teamLeadFullName ?? "No Team Lead assigned"}
                  </div>
                </>
              }
            />
          </Popover>
        </List.Item>
      )}
    />
  );
};

export default StandardRoleList;
