import React, { useEffect, useState } from "react";

import "./split_selection.less";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useGetStandardRolesQuery } from "@src/services/slices/standardRolesApi";
import { setCurrentAssignedRequestsToSplit } from "@src/services/splitRoleSlice";
import { RootState, useAppDispatch, useAppSelector } from "@src/setupStore";
import {
  RoleSpecification,
  SpecificationSplits,
  StandardRole,
} from "@src/types";
import { ErrorField } from "@src/types/antd_types";
import {
  Button,
  Col,
  Form,
  FormInstance,
  FormListFieldData,
  InputNumber,
  Row,
  Select,
} from "antd";
import { DefaultOptionType } from "antd/es/select";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

interface SplitSelectionProps {
  specificationForm: FormInstance<SpecificationSplits>;
  onFinish: (values: SpecificationSplits) => void;
  newSelectedSpecifications?: RoleSpecification[];
  basicRoleId: number;
}

export const SplitSelection: React.FC<SplitSelectionProps> = ({
  specificationForm,
  onFinish,
  newSelectedSpecifications,
  basicRoleId,
}) => {
  const dispatch = useAppDispatch();
  const [splitTotalForm] = Form.useForm<{ splitTotal: number }>();

  /** store **/
  const { data: standardRoles } = useGetStandardRolesQuery();

  const { currentAssignedRequestsToSplit } = useAppSelector(
    (state: RootState) => state.splitRoleSlice
  );

  /** use state **/
  const [addFunc, setAddFunc] = useState<
    ((defaultValue?: any, insertIndex?: number) => void) | null
  >(null);
  const [removeFunc, setRemoveFunc] = useState<
    ((defaultValue?: any, insertIndex?: number) => void) | null
  >(null);
  const [formErrors, setFormErrors] = useState<ErrorField[]>([]);
  const [subtractDisabled, setSubtractDisabled] = useState<boolean>(true);
  const [addDisabled, setAddDisabled] = useState<boolean>(false);

  const MIN_SPLITS = 1;
  const MAX_SPLITS = 25;

  useEffect(() => {
    // is only necessary to get the initial split after the user navigated back.
    // This will be updated in the next ticket to use the state from rtk
    const totalSelectedSplits: number =
      specificationForm?.getFieldValue("splits").length;

    if (totalSelectedSplits) {
      splitTotalForm.setFieldValue("splitTotal", totalSelectedSplits);

      if (totalSelectedSplits > MIN_SPLITS) {
        setSubtractDisabled(false);
      }

      if (totalSelectedSplits < MAX_SPLITS) {
        setAddDisabled(false);
      }
    }
  }, [specificationForm]);

  /**
   * When the user did not select a value in the specification selection than an error will be thrown,
   * and it is not possible to navigate to the next view
   *
   * @param {ValidateErrorEntity} errorInfo   The error info object the current errors when finishing form fails.
   */
  function onFinishFailed(errorInfo: ValidateErrorEntity): void {
    setFormErrors(errorInfo.errorFields);
  }

  /**
   * Removes the errors from form fields if a value was selected by the user otherwise
   * it is not possible to navigate to next view
   *
   * @param {SpecificationSplits} allValues specification form values to check if there is an error or not.
   */
  function handleValuesChange(allValues: SpecificationSplits): void {
    const updatedErrors: ErrorField[] = formErrors.filter(
      (error: ErrorField) => {
        return !allValues[error.name[0]];
      }
    );

    setFormErrors(updatedErrors);
  }

  /**
   *  Subtracting a specification split selection from the form list of specifications and decreasing
   *  the total number of splits by one.
   */
  function subtractSplitTotal(): void {
    const currentFieldsLength: number =
      specificationForm?.getFieldValue("splits")?.length;

    removeFunc(currentFieldsLength - 1);
    setAddDisabled(false);

    const lengthAfterSubtracted: number =
      specificationForm?.getFieldValue("splits")?.length;
    if (lengthAfterSubtracted === MIN_SPLITS) {
      setSubtractDisabled(true);
    }

    splitTotalForm.setFieldsValue({
      splitTotal: lengthAfterSubtracted,
    });
  }

  /**
   *  Adds a new specification split selection into the form list of specifications
   *  and increasing the total number of splits by one
   */
  function addSplitTotal(): void {
    addFunc();
    setSubtractDisabled(false);

    const lengthAfterAdded: number =
      specificationForm?.getFieldValue("splits")?.length;
    if (lengthAfterAdded === MAX_SPLITS) {
      setAddDisabled(true);
    }

    splitTotalForm.setFieldsValue({
      splitTotal: lengthAfterAdded,
    });
  }

  /**
   * checks if the current selected role specification was already selected before
   *
   * @param {number} currentSelectedId the id of the role specification that is selected in the specific selection
   * @returns true if the role specification was already selected before; false if not
   */
  function checkRoleSpecificationAlreadySelected(
    currentSelectedId: number
  ): boolean {
    const allSelectedIds: number[] = specificationForm?.getFieldValue("splits");
    return allSelectedIds?.some(
      (roleSpecificationId: number) => roleSpecificationId === currentSelectedId
    );
  }

  /**
   * When the user updates the selection a validation should be done to check if there are already assigned requests from step 2.
   * This can happen when the user assigns requests from step 2 to a new specification and clicks back.
   * The check is necessary to overwrite the new selected specification in the store so that the
   * requests are kept and now assigned to the new selected specification.
   *
   * @param {string}  selectedValue           The new selected specification.
   * @param {number}  selectionFormIndex      The selected selection form index to identify the selection.
   */
  function onSelectionChange(
    selectedValue: string,
    selectionFormIndex: number
  ): void {
    const lastSelectedValue: RoleSpecification =
      newSelectedSpecifications[selectionFormIndex];

    let currentAssignedRequestsToSplitCopy: Record<string, string[]> = {
      ...currentAssignedRequestsToSplit,
    };

    if (lastSelectedValue) {
      // check if requests where already assigned to the split
      const assignedRequestsToSplitElement: string[] =
        currentAssignedRequestsToSplitCopy[
          lastSelectedValue.roleSpecificationId
        ];

      // when for the selected split a request was assigned and afterwards changed
      // then it should be updated to the new selected.
      if (assignedRequestsToSplitElement) {
        delete currentAssignedRequestsToSplitCopy[
          lastSelectedValue.roleSpecificationId
        ];
        currentAssignedRequestsToSplitCopy = {
          ...currentAssignedRequestsToSplitCopy,
          [selectedValue]: assignedRequestsToSplitElement,
        };

        dispatch(
          setCurrentAssignedRequestsToSplit(currentAssignedRequestsToSplitCopy)
        );
      }
    }
  }

  function getRoleSpecificationOptions(): DefaultOptionType[] {
    return standardRoles
      .filter(
        (standardRole: StandardRole) =>
          standardRole.basicRole.basicRoleId === basicRoleId &&
          standardRole.roleSpecification
      )
      ?.map((standardRole: StandardRole) => ({
        value: standardRole.roleSpecification.roleSpecificationId,
        label: standardRole.roleSpecification?.roleSpecificationName,
        disabled:
          checkRoleSpecificationAlreadySelected(
            standardRole.roleSpecification.roleSpecificationId
          ) || !standardRole.teamLead,
      }));
  }

  return (
    <>
      <span>
        Enter here how many splits you would like to divide the role and enter
        the specification of every split
      </span>
      <div className="split-section">
        <Form
          form={splitTotalForm}
          layout="vertical"
          initialValues={{ splitTotal: 1 }}
        >
          <div className="split-section__number-section">
            <Button
              type="primary"
              onClick={subtractSplitTotal}
              disabled={subtractDisabled}
              data-testid="remove-split"
              icon={<MinusOutlined />}
            />
            <Form.Item
              name="splitTotal"
              label="Splits"
              className="split-section__form-item"
            >
              <InputNumber
                className="split-section__number-input"
                disabled
                data-testid="split-total-input"
              />
            </Form.Item>
            <Button
              type="primary"
              onClick={addSplitTotal}
              disabled={addDisabled}
              data-testid="add-split"
              icon={<PlusOutlined />}
            />
          </div>
        </Form>

        <div className="split-section__specification-selection">
          <Form
            form={specificationForm}
            layout="vertical"
            onFinishFailed={onFinishFailed}
            onValuesChange={handleValuesChange}
            onFinish={onFinish}
            initialValues={{ splits: [undefined] }}
          >
            <Row gutter={[16, 30]}>
              <Form.List name="splits">
                {(fields: FormListFieldData[], { add, remove }, { errors }) => {
                  if (!addFunc) setAddFunc(() => add);
                  if (!removeFunc) setRemoveFunc(() => remove);

                  return (
                    <>
                      {fields.map((field: FormListFieldData, index: number) => (
                        <Col span={12} key={field.key}>
                          <Form.Item
                            label={"Specification of split " + (index + 1)}
                            required={false}
                          >
                            <Form.Item
                              {...field}
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select a split.",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                onChange={(value: string) =>
                                  onSelectionChange(value, index)
                                }
                                optionFilterProp="label"
                                options={getRoleSpecificationOptions()}
                                placeholder="Please select a Specification"
                                data-testid="specification-selection"
                              />
                            </Form.Item>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        </Col>
                      ))}
                    </>
                  );
                }}
              </Form.List>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default SplitSelection;
