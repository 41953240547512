import React, { useMemo, useRef } from "react";

import { NoRowsOverlay } from "@src/components/overlays/no_rows_overlay";
import {
  setConfirmationButtonDisableStatus,
  setProposeVacancyAllocations,
  setSelectedVacancyId,
} from "@src/services/nextStepModalSlice";
import { useGetVacanciesQuery } from "@src/services/slices/teamLeadsApi";
import { RootState, useAppSelector } from "@src/setupStore";
import { Employee, RequestOverviewDetails } from "@src/types";
import {
  AllocationRowData,
  TeamLeadAllocationRowData,
} from "@src/types/role_request_types";
import { AllocationErrorContext } from "@src/utils/edit_allocation_utils";
import {
  CellValueChangedEvent,
  ColDef,
  ColumnVisibleEvent,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { App, Form, Select } from "antd";
import "./vacancy_selection.less";
import _ from "lodash";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";

import {
  getColumnDefs,
  getGridOptions,
} from "../../utils/propose_allocation_grid_options";
import {
  filterMapForUpdatedValues,
  mapRequestToSuggestWorkloadRowData,
  toggleErrorFlagInContext,
} from "../../utils/propose_allocations_utils";
import { getVacancyOptionLabel } from "../../utils/propose_solution_utils";

const cookies = new Cookies();

interface VacancySelectionProps {
  request: RequestOverviewDetails;
}

const VacancySelection: React.FC<VacancySelectionProps> = ({ request }) => {
  const { message } = App.useApp();

  const loggedInUser: Employee = cookies.get("loggedInuser");

  const gridApi = useRef<GridApi | null>(null);

  const dispatch = useDispatch();

  const { selectedVacancy } = useAppSelector(
    (state: RootState) => state.nextStepModalSlice
  );

  //rtk queries
  const { data: vacancies } = useGetVacanciesQuery(loggedInUser?.employeeId, {
    skip: !loggedInUser?.employeeId,
  });

  const workloadEndDate: Date = _.last(request.roleAllocationDetails)?.date;

  const columnDefs = useMemo<ColDef[]>(() => {
    return getColumnDefs(workloadEndDate);
  }, [workloadEndDate]);

  const rowData = useMemo<TeamLeadAllocationRowData[]>(() => {
    return mapRequestToSuggestWorkloadRowData(request);
  }, [request]);

  /**
   * Function to handle the vacancy change event
   *
   * @param {number} selectedVacancyId The selected vacancy id
   */
  function onVacancyChange(selectedVacancyId: number) {
    const gridContext: AllocationErrorContext =
      gridApi.current.getGridOption("context");
    const hasAnyInvalidValue: boolean = gridContext?.errorColIds.length > 0;
    const shouldDisableConfirmationButton: boolean =
      !selectedVacancyId || hasAnyInvalidValue;

    dispatch(setSelectedVacancyId(selectedVacancyId));
    dispatch(
      setConfirmationButtonDisableStatus(shouldDisableConfirmationButton)
    );
  }

  /**
   * AG Grid Ready event
   *
   * @param {GridReadyEvent} event - AG Grid event parameter
   */
  function onGridReady(event: GridReadyEvent): void {
    gridApi.current = event.api;
  }

  /**
   * AG Grid onCellValueChanged event
   *
   * Toggles an error if the new value is invalid or remove it when valid.
   *
   * It handles the enabling and disabling of the confirmation button and adds the proposed
   * allocations to the store.
   *
   * Checks if any value in the table is invalid. If yes, confirmation button will be disabled.
   * If not, the confirmation button will be enabled and the updated allocation will be stored.
   *
   * @param {CellValueChangedEvent} event AG Grid event parameter
   */
  function onCellValueChanged(event: CellValueChangedEvent) {
    toggleErrorFlagInContext(event, message);

    const hasAnyInvalidValue: boolean = event.context.errorColIds.length > 0;

    const shouldDisableConfirmationButton: boolean =
      hasAnyInvalidValue || !selectedVacancy.selectedVacancyId;
    dispatch(
      setConfirmationButtonDisableStatus(shouldDisableConfirmationButton)
    );

    if (!hasAnyInvalidValue) {
      const updatedAllocations: Map<string, AllocationRowData> =
        filterMapForUpdatedValues(event.data.allocations);

      dispatch(setProposeVacancyAllocations(updatedAllocations));
    }
  }

  return (
    <div className="vacancy-selection">
      <div className="vacancy-selection__input">
        <Form layout="vertical">
          <Form.Item label="Vacancy" data-testid="suggest-vacancy-selection">
            <Select
              showSearch
              allowClear
              onChange={onVacancyChange}
              placeholder="Select vacancy"
              optionFilterProp="label"
              options={vacancies?.map((vacancy: Employee) => ({
                value: vacancy.employeeId,
                label: getVacancyOptionLabel(vacancy),
              }))}
            />
          </Form.Item>
        </Form>
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          rowData={rowData}
          gridOptions={getGridOptions()}
          onGridReady={onGridReady}
          onColumnVisible={(event: ColumnVisibleEvent) =>
            event.api.sizeColumnsToFit()
          }
          onCellValueChanged={onCellValueChanged}
          columnDefs={columnDefs}
        />
      </div>
    </div>
  );
};

export default VacancySelection;
