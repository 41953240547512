import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";

export const extendDaysjsPlugins = () => {
  dayjs.extend(isBetween);
  dayjs.extend(customParseFormat);
  dayjs.extend(utc);
};
