import React from "react";
import { AG_GRID_PINNED_TYPE } from "@src/constants";
import { getAgGridParameter } from "@src/features/resource_plan_v2/utils/resource_plan_download_utils";
import { Project } from "@src/types";
import { ColDef, GridApi } from "ag-grid-community";
import { ColGroupDef } from "ag-grid-community/dist/types/core/entities/colDef";
import { ExcelExportParams } from "ag-grid-community/dist/types/core/interfaces/iExcelCreator";
import { App, Button, Tooltip } from "antd";
import _ from "lodash";
import { RootState, useAppSelector } from "@src/setupStore";
import { ReactComponent as DownloadIcon } from "@src/assets/icons/download.svg";

interface ResourcePlanDownloadButtonV2Props {
  gridApi: GridApi;
  project: Project;
  disabled?: boolean;
}

const ResourcePlanDownloadButtonV2: React.FC<
  ResourcePlanDownloadButtonV2Props
> = ({ gridApi, project, disabled }) => {
  const { modal } = App.useApp();

  const {
    resourcePlanTableState: { isFilterApplied },
  } = useAppSelector((state: RootState) => state.resourcePlanSliceV2);

  /**
   * Get the allocation field ids as string
   */
  function getAllocationFieldIds(): string[] {
    const allocationColumns: ColDef[] = gridApi
      .getColumnDefs()
      .filter((col: ColDef) => col.headerClass === "year-header-cell");

    return _.flatMap(allocationColumns, (field: ColGroupDef) =>
      _.map(field.children, "colId")
    );
  }

  /**
   * Get the non allocation fields as string
   */
  function getNonAllocationFieldIds(): string[] {
    return gridApi
      .getColumnDefs()
      .filter((col: ColDef) => col?.pinned === AG_GRID_PINNED_TYPE.LEFT)
      .map((colDef: ColDef) => colDef.colId);
  }

  /**
   * Get the excel config parameter for the download
   *
   * @param {boolean} downloadFiltered
   */
  function getExcelConfigParameter(
    downloadFiltered: boolean
  ): ExcelExportParams {
    return getAgGridParameter(
      getAllocationFieldIds(),
      getNonAllocationFieldIds(),
      downloadFiltered,
      project.name
    );
  }

  /**
   * After users clicks on the download button a check will be done if the user filtered one of the tables or not.
   * If the user filtered a table then the download filtered data confirmation modal will be shown
   * otherwise the complete data will be downloaded when the user did not filter
   */
  function checkIfFilterAppliedAndDownloadExcel(): void {
    // get the is filter applied from the store an when the filter was applied than check the table filter;

    const isFilteredByUser: boolean =
      gridApi?.isAnyFilterPresent() && isFilterApplied;

    if (isFilteredByUser) {
      // show confirmation modal if the user has filtered the table and wants to download the filtered data
      modal.confirm({
        autoFocusButton: null,
        content: "Would you like to download the filtered data?",
        okText: "Yes",
        cancelText: "No",
        onOk() {
          gridApi?.exportDataAsExcel(getExcelConfigParameter(true));
        },
        onCancel() {
          modal.confirm({
            autoFocusButton: null,
            okText: "Yes",
            cancelText: "No",
            content:
              "All the data will be downloaded. Do you want to continue?",
            onOk() {
              gridApi?.exportDataAsExcel(getExcelConfigParameter(false));
            },
          });
        },
      });
    } else {
      // download complete data if the user has not filtered the table
      gridApi?.exportDataAsExcel(getExcelConfigParameter(false));
    }
  }

  return (
    <Tooltip mouseEnterDelay={0.5} placement="top" title="Download as .xlsx">
      <Button
        onClick={checkIfFilterAppliedAndDownloadExcel}
        size="large"
        color="default"
        variant="filled"
        disabled={disabled}
        icon={<DownloadIcon />}
      />
    </Tooltip>
  );
};

export default ResourcePlanDownloadButtonV2;
