import React, { useEffect, useState } from "react";

import "./role_assignment_modal.less";
import { ChangeTeamLeadAfterRoleChangeModal } from "@src/components/change_team_lead_after_role_change_modal";
import { ConfirmationModal } from "@src/components/modals/confirmation_modal";
import { ROLES } from "@src/constants";
import { InfoLabel } from "@src/features/role_assignment_old/components/info-label";
import { useGetDeputyStandardRolesQuery } from "@src/services/slices/deputiesApi";
import {
  useAssignRolesMutation,
  useUpdateEmployeeMutation,
} from "@src/services/slices/employeesSlice";
import {
  useGetStandardRolesQuery,
  useLazyGetProjectRoleRequestsOfStandardRoleQuery,
} from "@src/services/slices/standardRolesApi";
import { useGetTeamLeadsStandardRolesQuery } from "@src/services/slices/teamLeadsApi";
import { RoleReassignModalMessage } from "@src/utils/helper";
import {
  Button,
  Checkbox,
  Form,
  Modal,
  Select,
  Typography,
  message,
} from "antd";
import Cookies from "universal-cookie/cjs";

import {
  createPayload,
  getAccountRolePopOverContent,
  getTeamLeadPopOverContent,
  INPUT_FIELDS,
} from "../../utils/roles_assignment_utils";

const { Title } = Typography;

function RoleAssignmentModal({
  user,
  employeesData,
  setRoleAssignmentModalVisible,
  roleAssignmentModalVisible,
}) {
  const [form] = Form.useForm();
  const cookies = new Cookies();

  /** use states **/
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [showTeamLeadReassignment, setShowTeamLeadReassignment] =
    useState(false);
  const [
    selectedRequestWithTeamMemberToTransfer,
    setSelectedRequestWithTeamMemberToTransfer,
  ] = useState([]);

  const [modalMessage, setModalMessage] = useState(null);

  const [
    teamMembersAssignedToChangedRequest,
    setTeamMembersAssignedToChangedRequest,
  ] = useState([]);

  const [formValues, setFormValues] = useState({
    teamLead: user.applicationRoles.includes(ROLES.TEAM_LEAD),
    teamMember: user.applicationRoles.includes(ROLES.TEAM_MEMBER),
    admin: user.applicationRoles.includes(ROLES.ADMIN),
    manager: user.applicationRoles.includes(ROLES.MANAGER),
    projectLead: user.applicationRoles.includes(ROLES.PROJECT_LEAD),
    deputy: user.applicationRoles.includes(ROLES.DEPUTY),
  });

  /** RTK Queries **/
  const [getProjectRoleRequests] =
    useLazyGetProjectRoleRequestsOfStandardRoleQuery();
  const [assignRoles] = useAssignRolesMutation();
  const [updateEmployee] = useUpdateEmployeeMutation();
  const { data: initialDeputyStandardRoles } = useGetDeputyStandardRolesQuery(
    user?.employeeId
  );
  const { data: selectableStandardRolesForDeputy } =
    useGetTeamLeadsStandardRolesQuery(user?.teamLeadId);
  const { data: standardRoles } = useGetStandardRolesQuery();

  useEffect(() => {
    if (standardRoles) {
      initializeTeamLead(standardRoles);
    }
  }, [standardRoles]);

  useEffect(() => {
    if (initialDeputyStandardRoles) {
      initializeDeputyStandardRoles();
    }
  }, [initialDeputyStandardRoles]);

  function initializeDeputyStandardRoles() {
    form.setFieldsValue({
      deputyStandardRoles: initialDeputyStandardRoles?.map(
        (deputy) => deputy.standardRoleName
      ),
    });
  }

  function initializeTeamLead(standardRoles) {
    const standardRoleNames = standardRoles
      .filter((standardRole) =>
        user.primaryRoles.includes(standardRole.standardRoleId)
      )
      ?.map((standardRole) => standardRole.standardRoleName);

    form.setFieldsValue({
      teamLeadStandardRoles: standardRoleNames,
      teamMemberStandardRole: user.genericRole,
    });
  }

  function isPrimaryRolesChanged() {
    const selectedTeamLeadStandardRoles = form.getFieldValue(
      INPUT_FIELDS.TEAM_LEAD_STANDARD_ROLES
    );

    let showPopUp =
      selectedTeamLeadStandardRoles &&
      selectedTeamLeadStandardRoles.length !== user.primaryRoles.length;

    if (showPopUp) {
      let filterRows = user.primaryRoles.filter(
        (role) =>
          selectedTeamLeadStandardRoles.indexOf(role.standardRoleName) === -1
      );
      showPopUp = filterRows && filterRows.length > 0 ? true : showPopUp;
    }

    return showPopUp;
  }

  async function processRoleReassignment() {
    const selectedTeamLeadStandardRoles = form.getFieldValue(
      INPUT_FIELDS.TEAM_LEAD_STANDARD_ROLES
    );

    let showPopUp = isPrimaryRolesChanged();

    if (showPopUp) {
      setConfirmationModal(true);

      let standardRoleObjectsList = findStandardRoleObjectByName(
        selectedTeamLeadStandardRoles
      );

      checkIfEmployeesAreAssignedToRole(standardRoleObjectsList);
      const modalMessage = await RoleReassignModalMessage(
        user,
        standardRoleObjectsList
      );
      setModalMessage(modalMessage);
    } else {
      processSave();
    }
  }

  async function checkIfEmployeesAreAssignedToRole(standardRoleObjectsList) {
    const requestWithAssignedTeamMember = [];

    for (const standardRole of standardRoleObjectsList) {
      getProjectRoleRequests({
        standardRoleId: standardRole.standardRoleId,
        showChildRequest: true,
      })
        .unwrap()
        .then((projectRoleRequests) => {
          const requestsAssignedToTeamMember = projectRoleRequests.filter(
            (role) =>
              role.assignedTeammemberId !== null &&
              role.primaryTeamleadEmployeeId !== user.employeeId
          );

          if (requestsAssignedToTeamMember.length !== 0) {
            for (const requestAssignedToATeamMember of requestsAssignedToTeamMember) {
              const isEmployeeWithRequestAlreadyInList =
                !!requestWithAssignedTeamMember.find(
                  (r) =>
                    r.assignedTeammemberId ===
                    requestAssignedToATeamMember.assignedTeammemberId
                );

              if (!isEmployeeWithRequestAlreadyInList) {
                requestWithAssignedTeamMember.push(
                  requestAssignedToATeamMember
                );
              }
            }
            setTeamMembersAssignedToChangedRequest(
              requestWithAssignedTeamMember
            );
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});
    }
  }

  async function processSave() {
    if (selectedRequestWithTeamMemberToTransfer.length !== 0) {
      await processReassignTeamMembers();
    }

    await processReassignRoles();
  }

  async function processReassignRoles() {
    const updatedUserDetails = createPayload(
      form.getFieldValue(),
      user.employeeId,
      standardRoles
    );

    assignRoles(updatedUserDetails)
      .unwrap()
      .then(() => {
        const empObj = cookies.get("loggedInuser");

        if (empObj.employeeId === updatedUserDetails.employeeId) {
          empObj.applicationRoles = updatedUserDetails.applicationRoles;
          cookies.set("loggedInuser", empObj);
        }

        setRoleAssignmentModalVisible(false);
        setShowTeamLeadReassignment(false);

        message.success("Successfully assigned roles.", 5);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  async function processReassignTeamMembers() {
    for (const requestWithAssignedTeamMember of selectedRequestWithTeamMemberToTransfer) {
      const employeeDto = employeesData.find(
        (employee) =>
          employee.employeeId ===
          requestWithAssignedTeamMember.assignedTeammemberId
      );

      employeeDto.teamLeadEmployeeDTO = user;
      employeeDto.teamLeadId = user.employeeId;

      updateEmployee(employeeDto)
        .unwrap()
        .then(() => {
          message.success(
            `Successfully reassigned ${requestWithAssignedTeamMember.assignedTeamMember}`
          );
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});
    }
  }

  function handleCancel() {
    setRoleAssignmentModalVisible(false);
    setTeamMembersAssignedToChangedRequest([]);
    setShowTeamLeadReassignment(false);
  }

  function toggleRoleSelection(selectedApplicationRole) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [selectedApplicationRole]: form.getFieldValue(selectedApplicationRole),
    }));
  }

  function findStandardRoleObjectByName(standardRoleNames) {
    const rolesToBeFiltered = [];

    standardRoles.forEach((standardRole) => {
      if (standardRoleNames.includes(standardRole.standardRoleName))
        rolesToBeFiltered.push(standardRole);
    });

    return rolesToBeFiltered;
  }

  function configureTeamLeadReassignment() {
    if (teamMembersAssignedToChangedRequest.length !== 0) {
      setShowTeamLeadReassignment(true);
    } else {
      processSave();
    }
  }

  return (
    <>
      {roleAssignmentModalVisible && standardRoles && (
        <Modal
          data-testid="assign-role-model"
          width="1200px"
          closable={false}
          title={
            showTeamLeadReassignment
              ? `Employee assignment for ${user.fullName}`
              : `Role assignment for ${user.fullName}`
          }
          maskClosable={false}
          centered
          className="role-assignment-modal-old"
          open={roleAssignmentModalVisible}
          bodyStyle={{ width: "1200px" }}
          onCancel={handleCancel}
          footer={
            <div className="btnSection">
              <Button key="back" onClick={handleCancel} data-testid="cancel">
                Cancel
              </Button>

              {showTeamLeadReassignment && (
                <Button
                  onClick={() => {
                    setTeamMembersAssignedToChangedRequest([]);
                    setShowTeamLeadReassignment(false);
                  }}
                >
                  back
                </Button>
              )}

              <Button
                type="primary"
                onClick={() => {
                  return showTeamLeadReassignment
                    ? processSave()
                    : processRoleReassignment();
                }}
              >
                {form?.getFieldValue(INPUT_FIELDS.TEAM_LEAD_STANDARD_ROLES)
                  ?.length !== 0 && !showTeamLeadReassignment
                  ? "next"
                  : "save"}
              </Button>
            </div>
          }
        >
          {!showTeamLeadReassignment ? (
            <div className="container">
              {confirmationModal && modalMessage && (
                <ConfirmationModal
                  action={configureTeamLeadReassignment}
                  modalVisible={confirmationModal}
                  setModalVisible={setConfirmationModal}
                  modalMessage={modalMessage}
                />
              )}

              <Title level={4}>
                <InfoLabel
                  isDisabled={!formValues.teamLead}
                  infoLabelContent={"Account role"}
                  showPopOver={true}
                  popOverTitle={"Account roles"}
                  popOverContent={getAccountRolePopOverContent()}
                />
              </Title>
              <Form form={form} initialValues={formValues} layout={"vertical"}>
                <Form.Item name="manager" valuePropName="checked">
                  <Checkbox
                    onChange={() => toggleRoleSelection(INPUT_FIELDS.MANAGER)}
                  >
                    Manager
                  </Checkbox>
                </Form.Item>
                <Form.Item name="admin" valuePropName="checked">
                  <Checkbox
                    onChange={() => toggleRoleSelection(INPUT_FIELDS.ADMIN)}
                  >
                    Admin
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  name="deputy"
                  valuePropName="checked"
                  className="application-role"
                >
                  <Checkbox
                    onChange={() => toggleRoleSelection(INPUT_FIELDS.DEPUTY)}
                    disabled={selectableStandardRolesForDeputy?.length === 0}
                  >
                    Deputy
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  className="indented info-label__top"
                  name="deputyStandardRoles"
                  label={
                    <InfoLabel
                      isDisabled={!formValues.deputy}
                      showPopOver={
                        selectableStandardRolesForDeputy?.length === 0
                      }
                      infoLabelContent={
                        "Please select all standard roles the deputy is responsible for"
                      }
                      popOverTitle={"Deputy standard roles"}
                      popOverContent={
                        "Not possible to assign deputy role because team lead has no roles or no team lead assigned to employee."
                      }
                    />
                  }
                >
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder={`Select standard roles "${user.fullName}" is responsible for`}
                    disabled={
                      selectableStandardRolesForDeputy?.length === 0 ||
                      !formValues.deputy
                    }
                    options={selectableStandardRolesForDeputy?.map(
                      (standardRole) => ({
                        value: standardRole.standardRoleName,
                        label: standardRole.standardRoleName,
                      })
                    )}
                  />
                </Form.Item>
                <Form.Item
                  name="teamLead"
                  valuePropName="checked"
                  className="application-role"
                >
                  <Checkbox
                    onChange={() => toggleRoleSelection(INPUT_FIELDS.TEAM_LEAD)}
                  >
                    Team lead
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  className="indented info-label__top"
                  name="teamLeadStandardRoles"
                  label={
                    <InfoLabel
                      isDisabled={!formValues.teamLead}
                      infoLabelContent={
                        "Please select all standard roles this user is responsible for"
                      }
                      showPopOver={true}
                      popOverTitle={"Team lead standard roles"}
                      popOverContent={getTeamLeadPopOverContent()}
                    />
                  }
                >
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder={`Select standard roles "${user.fullName}" is responsible for`}
                    disabled={!formValues.teamLead}
                    options={standardRoles.map((standardRole) => ({
                      value: standardRole.standardRoleName,
                      label: standardRole.standardRoleName,
                    }))}
                  />
                </Form.Item>
                <Form.Item
                  valuePropName="checked"
                  name="teamMember"
                  className="application-role"
                >
                  <Checkbox
                    onChange={() =>
                      toggleRoleSelection(INPUT_FIELDS.TEAM_MEMBER)
                    }
                  >
                    Team member
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  className="indented info-label__top"
                  name="teamMemberStandardRole"
                  label={
                    <InfoLabel
                      isDisabled={!formValues.teamMember}
                      showPopOver={false}
                      infoLabelContent={
                        "Please select a standard role for this team member"
                      }
                    />
                  }
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Select a standard role"
                    disabled={!formValues.teamMember}
                    options={standardRoles.map((standardRole) => ({
                      value: standardRole.standardRoleName,
                      label: standardRole.standardRoleName,
                    }))}
                  />
                </Form.Item>
                <Form.Item name="projectLead" valuePropName="checked">
                  <Checkbox
                    onChange={() =>
                      toggleRoleSelection(INPUT_FIELDS.PROJECT_LEAD)
                    }
                  >
                    Project lead
                  </Checkbox>
                </Form.Item>
              </Form>
            </div>
          ) : (
            <ChangeTeamLeadAfterRoleChangeModal
              setSelectedRequestWithTeammemberToTransfer={
                setSelectedRequestWithTeamMemberToTransfer
              }
              currentUpdatedUser={user}
              teammembersAssigendToChangedRequest={
                teamMembersAssignedToChangedRequest
              }
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default RoleAssignmentModal;
