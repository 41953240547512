import React, { useMemo, useState } from "react";

import { CloseOutlined } from "@ant-design/icons";
import { ReactComponent as PlusIcon } from "@src/assets/icons/plus.svg";
import { StandardRoleList } from "@src/features/resource_plan_v2/components/standard_role_list/index";
import { SearchInput } from "@src/features/table_filtering/components/search_input";
import { useGetStandardRolesQuery } from "@src/services/slices/standardRolesApi";
import { StandardRole } from "@src/types";
import { GridApi } from "ag-grid-community";
import { Button, Drawer, Form } from "antd";
import { debounce, some, toLower } from "lodash";
import "./role_request_creation_drawer.less";

interface RoleRequestCreationDrawerProps {
  projectId: string;
  gridApi: GridApi;
  isFetching: boolean;
}

const RoleRequestCreationDrawer: React.FC<RoleRequestCreationDrawerProps> = ({
  projectId,
  gridApi,
  isFetching,
}) => {
  const { data: standardRoles } = useGetStandardRolesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      data: data?.filter((role) => !role.onlyVisibleForAdmin),
    }),
  });

  const [form] = Form.useForm();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const filteredRoles = useMemo(() => {
    if (!searchTerm) return standardRoles;

    return standardRoles.filter((role: StandardRole) => {
      // define the fields to search in
      const fields = [
        role.basicRole?.basicRoleName,
        role.roleSpecification?.roleSpecificationName,
        role.teamLeadFullName,
      ];

      // Check if the search term is included in any of the fields
      return some(
        fields,
        (value: string) => value && toLower(value).includes(toLower(searchTerm))
      );
    });
  }, [searchTerm, standardRoles]);

  /**
   *  Debounce the search input to avoid multiple filter calls
   */
  const onSearch = debounce((value: string) => {
    setSearchTerm(value);
  }, 300);

  /**
   *  Close the drawer and reset the search term
   */
  function onClose() {
    setIsDrawerOpen(false);
    setSearchTerm("");
    form.resetFields();
  }

  return (
    <>
      <Button
        icon={<PlusIcon />}
        type="primary"
        size="large"
        disabled={isFetching}
        onClick={() => setIsDrawerOpen(true)}
      >
        Add Role
      </Button>
      <Drawer
        className="role-request-creation-drawer"
        title="Add Role, Specification & Utilization"
        placement="right"
        width={344}
        closable={false}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        extra={
          <Button icon={<CloseOutlined />} type="text" onClick={onClose} />
        }
      >
        <Form form={form}>
          <Form.Item name="search">
            <SearchInput
              onChange={(value: string) => onSearch(value)}
              disabled={isFetching}
            />
          </Form.Item>
        </Form>
        <div className="creation-wrapper">
          {filteredRoles?.length === 0 && (
            <div className="no-standard-roles-found">
              No standard roles found
            </div>
          )}
          <StandardRoleList
            standardRoles={filteredRoles}
            projectId={projectId}
            gridApi={gridApi}
            onClose={onClose}
          />
        </div>
      </Drawer>
    </>
  );
};

export default RoleRequestCreationDrawer;
