import { CustomCellRendererProps } from "ag-grid-react";
import { Select } from "antd";
import { DefaultOptionType } from "antd/es/select";

interface SelectRoleRendererProps {
  props: CustomCellRendererProps;
  placeholder: string;
  options: string[];
}

const SelectRoleRenderer: React.FC<SelectRoleRendererProps> = ({
  props,
  placeholder,
  options,
}) => {
  const disabled: boolean =
    options?.length < 1 ||
    (props.colDef.field === "roleSpecificationName" &&
      !props.data.basicRoleName);

  /**
   * maps the options to an ant d option type
   *
   * @returns the ant d options with label and value
   */
  function generateOptions(): DefaultOptionType[] {
    return options?.map((option: string) => ({ label: option, value: option }));
  }

  /**
   * onChange function of the selection
   *
   * @param {string} value selected value
   */
  function onChange(value: string): void {
    props.setValue(value);
  }

  return (
    <Select
      showSearch
      optionFilterProp="label"
      data-testid="select-role-renderer"
      defaultValue={props.value}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      options={generateOptions()}
    />
  );
};

export default SelectRoleRenderer;
