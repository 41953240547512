import React, { useState } from "react";

import "./filters_bar.less";
import { DownloadButton } from "@src/features/table_filtering/components/download_button";
import { FilterButton } from "@src/features/table_filtering/components/filter_button";
import { FiltersDrawer } from "@src/features/table_filtering/components/filters_drawer";
import { SearchInput } from "@src/features/table_filtering/components/search_input/search_input";
import { TableSettingsButton } from "@src/features/table_filtering/components/table_settings_button";
import { TableSettingsConfig } from "@src/types";

export interface DownloadConfig {
  path: string;
  downloadableData?: any;
  downloadableFilteredData?: any;
  method?: string;
}

interface FiltersBarProps {
  drawerTitle?: string;
  downloadConfig?: DownloadConfig;
  tabs?: React.ReactNode;
  filters: React.ReactNode;
  extraButtons?: React.ReactNode;
  primaryActionButton?: React.ReactNode;
  quickFilterDefaultValue?: string;
  onQuickFilterChange?: (value: string) => void;
  tableSettings?: TableSettingsConfig;
  isLoading?: boolean;
}

const FiltersBar: React.FC<FiltersBarProps> = ({
  drawerTitle = "Filter",
  tabs,
  downloadConfig,
  filters,
  tableSettings,
  extraButtons,
  primaryActionButton,
  quickFilterDefaultValue,
  onQuickFilterChange,
  isLoading = false,
}) => {
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  return (
    <div className="table-filters-wrapper" data-testid="filters-bar">
      <div className="filters-group-pinned-left">
        {onQuickFilterChange && (
          <div data-testid={"search-input"} className="search-input">
            <SearchInput
              disabled={isLoading}
              defaultValue={quickFilterDefaultValue}
              onChange={onQuickFilterChange}
            />
          </div>
        )}
      </div>
      <div className="filters-group-pinned-right">
        <div className="filters-inner-group">
          {tabs && (
            <div data-testid={"tabs-group"} className="tabs-group">
              {tabs}
            </div>
          )}
        </div>
        {extraButtons && (
          <div className="filters-inner-group">{extraButtons}</div>
        )}
        <div className="filters-inner-group">
          {downloadConfig && (
            <DownloadButton
              path={downloadConfig.path}
              method={downloadConfig.method}
              completeData={downloadConfig.downloadableData}
              filteredData={downloadConfig.downloadableFilteredData}
              disabled={isLoading}
            />
          )}
          {tableSettings && (
            <TableSettingsButton
              tableSettings={tableSettings}
              disabled={isLoading}
            />
          )}
          <FilterButton
            setOpenFilterDrawer={setOpenFilterDrawer}
            disabled={isLoading}
          />
        </div>
        {primaryActionButton && (
          <div className="filters-inner-group">{primaryActionButton}</div>
        )}
      </div>
      <FiltersDrawer
        isDrawerOpen={openFilterDrawer}
        setIsDrawerOpen={setOpenFilterDrawer}
        drawerTitle={drawerTitle}
        filters={filters}
      />
    </div>
  );
};

export default FiltersBar;
