import "./filter_collapse_wrapper.less";
import React from "react";

import { Collapse } from "antd";
import { clsx } from "@src/utils/helper";

export interface FilterCollapseWrapperProps {
  isDataStatic?: boolean;
  title: string;
  children: React.ReactNode;
  search?: React.ReactNode;
}

const FilterCollapseWrapper: React.FC<FilterCollapseWrapperProps> = ({
  isDataStatic,
  title,
  children,
  search,
}) => {
  return (
    <Collapse
      defaultActiveKey={title}
      expandIconPosition="end"
      className="filter-collapse-wrapper"
    >
      <Collapse.Panel
        key={title}
        header={title}
        data-testid="filter-collapse-wrapper"
      >
        <div className="filter-panel">
          {search && <div className="filter-panel__border">{search}</div>}
          <div
            className={clsx(
              "filter-panel__content",
              isDataStatic && "filter-panel__content--static"
            )}
          >
            {children}
          </div>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

export default FilterCollapseWrapper;
