import {
  AllocationDetails,
  CapacityV2,
  ProposeAllocation,
  RequestOverviewDetails,
  TeamMemberCapacityV2,
} from "@src/types";
import {
  AllocationRowData,
  ProposeAllocationRowData,
  TeamLeadAllocationRowData,
} from "@src/types/role_request_types";
import {
  addErrorFlag,
  AllocationErrorContext,
  isValueValid,
  removeErrorFlag,
} from "@src/utils/edit_allocation_utils";
import { CellValueChangedEvent, ValueFormatterParams } from "ag-grid-community";
import { MessageInstance } from "antd/es/message/interface";
import dayjs from "dayjs";
import _ from "lodash";

/**
 * Maps the teamMember list from the backend to the AG Grid table dataset for the propose team member
 *
 * @param {TeamMemberCapacityV2[]} teamMember list to be mapped
 * @returns the AG Grid data list
 */
export function mapCapacityToTeamMemberRowData(
  teamMember: TeamMemberCapacityV2[]
): ProposeAllocationRowData[] {
  const employeeRows: ProposeAllocationRowData[] = teamMember?.map(
    (teamMember: TeamMemberCapacityV2) => {
      return {
        label: null,
        employee: {
          employeeId: teamMember.employeeId,
          fullName: teamMember.employeeName,
          genericRoleName: teamMember.standardRoleName,
        },
        allocations: teamMember.capacities.map((capacity: CapacityV2) => {
          return {
            date: capacity.date,
            percentage: capacity.capacity,
          };
        }),
      };
    }
  );

  return employeeRows || [];
}

/**
 * Maps the request object to the AG Grid table dataset for the propose team member required allocations
 *
 * @param {RequestOverviewDetails} request to be mapped
 * @returns the AG Grid data list
 */
export function getCapacityTopPinnedRow(
  request: RequestOverviewDetails
): ProposeAllocationRowData {
  const currentRoleAllocationDetails = request?.roleAllocationDetails || [];

  const requiredAllocations: AllocationRowData[] =
    currentRoleAllocationDetails.map((allocation: AllocationDetails) => {
      return {
        date: allocation.date,
        percentage: allocation.requiredPercentage,
      };
    });

  return {
    label: "Requested Workload",
    employee: null,
    allocations: requiredAllocations,
  };
}

/**
 *  Maps the request object to the AG Grid table dataset for the detail grid
 *
 * @param {RequestOverviewDetails} request to be mapped
 * @returns the AG Grid data list
 */
export function mapRequestToProposeAllocationsRowData(
  request: RequestOverviewDetails
): TeamLeadAllocationRowData[] {
  const allocationMap: Map<string, AllocationRowData> =
    buildMapFromRequestAllocations(request);

  return [
    {
      label: "",
      allocations: allocationMap,
    },
  ];
}

/**
 * Maps the request object to the AG Grid table dataset for all other propose allocation tables
 *
 * @param {RequestOverviewDetails} request to be mapped
 * @returns the AG Grid data list
 */
export function mapRequestToSuggestWorkloadRowData(
  request: RequestOverviewDetails
): TeamLeadAllocationRowData[] {
  const allocationMap: Map<string, AllocationRowData> =
    buildMapFromRequestAllocations(request);

  return [
    {
      label: "Requested Workload",
      allocations: allocationMap,
    },
    {
      label: "Suggested Workload",
      allocations: allocationMap,
    },
  ];
}

/**
 * Maps the request allocations into a map (key = colId / "M-YYYY", value = allocationRowData object)
 *
 * @param {RequestOverviewDetails} request to be mapped
 * @returns the new Map
 */
function buildMapFromRequestAllocations(
  request: RequestOverviewDetails
): Map<string, AllocationRowData> {
  const allocationMap = new Map<string, AllocationRowData>();

  request.roleAllocationDetails?.map((allocation: AllocationDetails) => {
    const year: number = dayjs(allocation.date).year();
    const month: number = dayjs(allocation.date).month() + 1;

    const allocationDetails: AllocationRowData = {
      percentage: allocation.requiredPercentage,
    };
    allocationMap.set(`${month}-${year}`, allocationDetails);
  });

  return allocationMap;
}

/**
 * The valueFormatter that is used in all propose allocation tables
 *
 * @param {ValueFormatterParams} params AG Grid valueFormatter params
 * @returns the value how it will be shown in the table
 */
export function valueFormatter(params: ValueFormatterParams) {
  const value = params.value || 0;
  return `${value}%`;
}

/**
 * Adds an error flag of the given colId in the given AG Grid context when the given value is not valid.
 * It also shows an error message when an Ant Design Message Instance is given.
 * Removes an error flag of the given colId in the given AG Grid context when the given value is valid.
 *
 * The context is a list of the colId where an error appears
 *
 * @param {CellValueChangedEvent} event
 * @param {MessageInstance} message
 */
export function toggleErrorFlagInContext(
  event: CellValueChangedEvent,
  message?: MessageInstance
): void {
  const value: number = event.newValue;
  const context: AllocationErrorContext = event.context;
  const colId: string = event.colDef.colId;

  if (isValueValid(value)) {
    removeErrorFlag(context, colId);
  } else {
    addErrorFlag(context, colId, message);
  }
}

/**
 * filters a given map for values that contain updatedPercentage
 * and returns a new map only containing these updatedPercentage values
 *
 * @param {Map<string, AllocationRowData>} allocations map that should be filtered
 * @returns the filtered map
 */
export function filterMapForUpdatedValues(
  allocations: Map<string, AllocationRowData>
): Map<string, AllocationRowData> {
  const allocationList = [...allocations]; // cast map to array to filter it

  const updatedAllocationList = allocationList.filter(
    ([_, allocation]) =>
      allocation.updatedPercentage || allocation.updatedPercentage === 0
  );

  return new Map<string, AllocationRowData>(updatedAllocationList);
}
/**
 * Maps the data to the payload
 *
 * @param {Map<string, AllocationRowData>} udatedAllocations map of allocations
 * @returns the mapped payload
 */
export function createProposeDifferentWorkloadPayload(
  udatedAllocations: Map<string, AllocationRowData>
): ProposeAllocation[] {
  const proposedAllocations: ProposeAllocation[] = [];
  udatedAllocations.forEach((allocation, colId) => {
    const [month, year] = _.split(colId, "-").map((value: string) =>
      parseInt(value)
    );
    proposedAllocations.push({
      allocation: allocation.updatedPercentage,
      isAllocationRequest: true,
      requestedMonth: month,
      requestedYear: year,
      uniqueId: `${year}-${month}`,
    });
  });

  return proposedAllocations;
}
