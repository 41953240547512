import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { HTTP_METHODS } from "@src/constants";
import { Deputy, DeputyOldAndNewDTO, StandardRole } from "@src/types";
import { message } from "antd";

import { baseApiSlice } from "./baseApiSlice";

export const deputiesApi = baseApiSlice.injectEndpoints({
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    /* GET endpoints */
    ...getEndpoints(builder),

    /* POST endpoints */
    ...postEndpoints(builder),
  }),
});

function getEndpoints(builder: EndpointBuilder<any, any, any>) {
  return {
    /**
     * Get the standard roles of a deputy he is allowed to assign to team members
     */
    getDeputyStandardRoles: builder.query<StandardRole[], number>({
      query: (deputyId) => `deputies/${deputyId}/standard-roles`,
      transformErrorResponse: async () => {
        message.error("Unable to get standard roles", 5);
      },
      providesTags: ["DeputyStandardRoles"],
    }),

    /**
     * Get deputies
     */
    getDeputies: builder.query<Deputy[], void>({
      query: () => "deputies",
      transformErrorResponse: async () => {
        message.error("Unable to get deputies", 5);
      },
      providesTags: ["Deputies"],
    }),
  };
}

function postEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod: string = HTTP_METHODS.POST;

  return {
    /**
     * Create a new deputy role assignment
     */
    addDeputy: builder.mutation<void, DeputyOldAndNewDTO>({
      query: (deputy) => ({
        url: "deputies",
        method: baseMethod,
        body: deputy,
      }),
      transformErrorResponse: async () => {
        message.error("Error creating new deputy assignment", 5);
      },
      invalidatesTags: ["Deputies", "AdminStandardRoles"],
    }),
  };
}

export const {
  /** Get Endpoints */
  useGetDeputyStandardRolesQuery,
  useLazyGetDeputyStandardRolesQuery,
  useLazyGetDeputiesQuery,

  /** Post Endpoints */
  useAddDeputyMutation,
} = deputiesApi;
