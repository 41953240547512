import React, { useMemo, useRef } from "react";

import { NoRowsOverlay } from "@src/components/overlays/no_rows_overlay";
import { REQUEST_STATUS } from "@src/constants";
import {
  getColumnDefs,
  getGridOptions,
} from "@src/features/staffing_request_details/utils/staffing_request_workload_table_grid_options";
import {
  getWorkloadDates,
  mapRowData,
} from "@src/features/staffing_request_details/utils/staffing_request_workload_table_utils";
import { RequestOverviewDetails } from "@src/types";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import type { ColumnVisibleEvent } from "ag-grid-community/dist/types/core/events";
import { AgGridReact } from "ag-grid-react";

interface StaffingRequestWorkloadTableProps {
  request: RequestOverviewDetails;
}

const StaffingRequestWorkloadTable: React.FC<
  StaffingRequestWorkloadTableProps
> = ({ request }) => {
  const gridApi = useRef(null);

  const isRequested: boolean = [
    REQUEST_STATUS.REQUESTED,
    REQUEST_STATUS.IN_PROGRESS,
  ].includes(request.status);

  const columnDefs = useMemo<ColDef[]>(() => {
    const { startDate, endDate } = getWorkloadDates(request);
    return getColumnDefs(startDate, endDate);
  }, [request]);

  /**
   * AG Grid Ready event
   *
   * @param {GridReadyEvent} event - AG Grid event parameter
   */
  function onGridReady(event: GridReadyEvent): void {
    gridApi.current = event.api;
  }

  return (
    <div
      className="staffing-request-workload-table"
      data-testid="staffing-request-workload-table"
    >
      <div className="ag-theme-alpine">
        <AgGridReact
          rowData={mapRowData(request, isRequested)}
          gridOptions={getGridOptions()}
          onGridReady={onGridReady}
          noRowsOverlayComponent={NoRowsOverlay}
          noRowsOverlayComponentParams={{
            text: "No requests found",
            customStyle: { marginTop: "48px" },
          }}
          onColumnVisible={(props: ColumnVisibleEvent) =>
            props.api.sizeColumnsToFit()
          }
          columnDefs={columnDefs}
        />
      </div>
    </div>
  );
};

export default React.memo(StaffingRequestWorkloadTable);
