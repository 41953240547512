// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-text {
  display: flex;
  line-height: 25px;
}
.status-subtext {
  display: flex;
  align-items: baseline;
  font-family: "RWESansWeb-Regular";
  font-size: 12px !important;
  color: #3b3d42;
  line-height: 20px;
}
.status-subtext > span {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 120px;
}
.status-profile-icon {
  height: 12px;
  width: 12px;
  margin-right: 2px;
  fill: #1d4477;
}
.status-icon {
  text-align: center;
}
.status-icon::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background-color: #adafb1;
  margin-right: 5px;
}
.status-icon-confirmed::before {
  background-color: #5ab88f;
}
.status-icon-requested::before {
  background-color: #778fad;
}
.status-icon-in-progress::before {
  background-color: #fff0b3;
}
.status-icon-declined::before {
  background-color: #e7343f;
}
.status-icon-in-recruitment::before {
  background-color: #fff0b3;
}
.status-icon-proposed::before {
  background-color: #99e0f7;
}
`, "",{"version":3,"sources":["webpack://./src/components/status/status.less"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,iBAAA;AAFJ;AAKE;EACE,aAAA;EACA,qBAAA;EACA,iCAAA;EACA,0BAAA;EACA,cAAA;EACA,iBAAA;AAHJ;AAHE;EAQI,uBAAA;EACA,gBAAA;EACA,YAAA;AAFN;AAME;EACE,YAAA;EACA,WAAA;EACA,iBAAA;EACA,aAAA;AAJJ;AAOE;EACE,kBAAA;AALJ;AAOI;EACE,WAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;EACA,0BAAA;EACA,kBAAA;EACA,yBAAA;EACA,iBAAA;AALN;AAQI;EACE,yBAAA;AANN;AASI;EACE,yBAAA;AAPN;AAUI;EACE,yBAAA;AARN;AAWI;EACE,yBAAA;AATN;AAYI;EACE,yBAAA;AAVN;AAaI;EACE,yBAAA;AAXN","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.status {\n  &-text {\n    display: flex;\n    line-height: 25px;\n  }\n\n  &-subtext {\n    display: flex;\n    align-items: baseline;\n    font-family: \"RWESansWeb-Regular\";\n    font-size: 12px !important;\n    color: @font-00;\n    line-height: 20px;\n    > span {\n      text-overflow: ellipsis;\n      overflow: hidden;\n      width: 120px;\n    }\n  }\n\n  &-profile-icon {\n    height: 12px;\n    width: 12px;\n    margin-right: 2px;\n    fill: @brand;\n  }\n\n  &-icon {\n    text-align: center;\n\n    &::before {\n      content: \"\";\n      display: inline-block;\n      width: 12px;\n      height: 12px;\n      -moz-border-radius: 6px;\n      -webkit-border-radius: 6px;\n      border-radius: 6px;\n      background-color: @light-grey-100;\n      margin-right: 5px;\n    }\n\n    &-confirmed::before {\n      background-color: @success;\n    }\n\n    &-requested::before {\n      background-color: @brand-60;\n    }\n\n    &-in-progress::before {\n      background-color: @yellow-30;\n    }\n\n    &-declined::before {\n      background-color: @alert;\n    }\n\n    &-in-recruitment::before {\n      background-color: @yellow-30;\n    }\n\n    &-proposed::before {\n      background-color: @energy-blue-40;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
