import React from "react";

import { REQUEST_STATUS } from "@src/constants";
import { EditorButton } from "@src/features/resource_plan_v2/components/editor_button/index";
import { FilterResourcePlan } from "@src/features/resource_plan_v2/components/filter_resource_plan";
import { ResourcePlanDownloadButton } from "@src/features/resource_plan_v2/components/resource_plan_download_button";
import { RoleRequestCreationDrawer } from "@src/features/resource_plan_v2/components/role_request_creation_drawer/index";
import { TabsResourcePlan } from "@src/features/resource_plan_v2/components/tabs_resource_plan";
import { FilterButton } from "@src/features/table_filtering/components/filter_button";
import { FiltersDrawer } from "@src/features/table_filtering/components/filters_drawer";
import { SearchInput } from "@src/features/table_filtering/components/search_input";
import { TableSettingsButton } from "@src/features/table_filtering/components/table_settings_button";
import { mapToTreeData } from "@src/features/table_filtering/utils/filter_utils";
import { setColumns, setQuickFilter } from "@src/services/resourcePlanSliceV2";
import { useGetProjectQuery } from "@src/services/slices/projectsSlice";
import { useAppDispatch } from "@src/setupStore";
import { TableSettingsConfig } from "@src/types";
import { RoleRequest } from "@src/types/role_request_types";
import { ColDef, GridApi } from "ag-grid-community";
import Cookies from "universal-cookie";

const cookies = new Cookies();

interface ResourcePlanToolBarProps {
  isFetching: boolean;
  roleRequestData: RoleRequest[];
  columnDefs: ColDef[];
  projectId: string;
  gridApi: GridApi;
}

const ResourcePlanToolBar: React.FC<ResourcePlanToolBarProps> = ({
  isFetching,
  roleRequestData,
  columnDefs,
  projectId,
  gridApi,
}) => {
  const dispatch = useAppDispatch();

  const { data: project } = useGetProjectQuery(projectId.toString());

  const [openFilterDrawer, setOpenFilterDrawer] =
    React.useState<boolean>(false);

  /**
   * Checks if the logged in user is the project manager of the project.
   */
  function isUserProjectManager(): boolean {
    const loggedInEmployeeId: number = cookies?.get("loggedInuser")?.employeeId;
    return loggedInEmployeeId === project.projectManager.employeeId;
  }
  /**
   * Builds the children for the Filters.
   *
   * @returns {React.ReactNode} - Filter Component (content of drawer).
   */
  function renderFilters(): React.ReactNode {
    return (
      <FilterResourcePlan
        disabled={isFetching}
        standardRoleNames={mapToTreeData(
          roleRequestData,
          "standardRoleName",
          true
        )}
      />
    );
  }

  /**
   * Build the initial settings for this table
   *
   * @returns {TableSettingsConfig} - The initial table settings
   */
  function getTableSettings(): TableSettingsConfig {
    const columnsToHide: string[] = columnDefs
      .filter((colDef: ColDef) => colDef.hide)
      .map((colDef: ColDef) => colDef.headerName);

    return {
      columns: columnDefs,
      onChange: (columns: string[]) => {
        dispatch(setColumns(columns));
      },
      columnsToHide: columnsToHide,
      defaultHiddenColumns: ["Project Identifier", "Specific Role", "Scope"],
    };
  }

  return (
    <div className="tool-bar">
      <div className="tool-bar__search-section search-input">
        <SearchInput
          disabled={isFetching}
          onChange={(value: string) => dispatch(setQuickFilter(value))}
        />
      </div>
      <div className="tool-bar__button-section">
        <TabsResourcePlan
          disabled={isFetching}
          amountOfDrafts={
            roleRequestData?.filter(
              (roleRequest: RoleRequest) =>
                roleRequest.status === REQUEST_STATUS.DRAFT
            ).length
          }
        />
        <div>
          <ResourcePlanDownloadButton
            gridApi={gridApi}
            project={project}
            disabled={isFetching}
          />
          <EditorButton
            disabled={isFetching || !isUserProjectManager()}
            projectId={projectId}
            isUserProjectManager={isUserProjectManager()}
          />
          <TableSettingsButton
            tableSettings={getTableSettings()}
            disabled={isFetching}
          />
          <FilterButton
            setOpenFilterDrawer={() => setOpenFilterDrawer(true)}
            disabled={isFetching}
          />
          <RoleRequestCreationDrawer
            projectId={projectId}
            gridApi={gridApi}
            isFetching={isFetching}
          />
        </div>
      </div>
      <FiltersDrawer
        isDrawerOpen={openFilterDrawer}
        setIsDrawerOpen={setOpenFilterDrawer}
        drawerTitle="Filter Already requested roles"
        filters={renderFilters()}
      />
    </div>
  );
};

export default ResourcePlanToolBar;
