import React from "react";

import { Button } from "antd";
import { useHistory } from "react-router-dom";

interface DetailsRendererParams {
  projectRoleRequestId: number;
}

const DetailsRenderer: React.FC<DetailsRendererParams> = ({
  projectRoleRequestId,
}) => {
  const history = useHistory();
  return (
    <Button
      type="link"
      onClick={() =>
        history.push(
          `staffingRequests/suggestTeamMember/${projectRoleRequestId}`
        )
      }
    >
      View Details
    </Button>
  );
};

export default DetailsRenderer;
