// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.propose-allocation-renderer {
  background: #eeeeef;
  padding: 0;
  height: 40px;
  overflow: hidden;
}
.propose-allocation-renderer__propose-time-label {
  font-family: RWESansWeb-Medium;
  font-size: 12px;
  color: #1d4477;
  height: 39px;
  align-items: center;
  width: 100%;
  display: flex;
  padding-left: 55px;
  border-bottom: 1px solid #cbccce !important;
}
.propose-allocation-renderer .ag-header {
  display: none;
}
.propose-allocation-renderer .ag-cell {
  background: #eeeeef;
}
.propose-allocation-renderer .ag-cell:not(.ag-cell-focus):not(.ag-cell-range-selected):not(
          .ag-selection-fill-right
        ):not(.ag-selection-fill-left):not(.ag-selection-fill-top):not(
          .ag-selection-fill-bottom
        ) {
  border-bottom: 1px solid #cbccce !important;
}
.propose-allocation-renderer .ag-cell.allocation-cell:not(.ag-cell-focus):not(.ag-cell-range-selected):not(
          .ag-selection-fill-right
        ):not(.ag-selection-fill-left):not(.ag-selection-fill-top):not(
          .ag-selection-fill-bottom
        ) {
  border-right: 1px solid #cbccce !important;
}
.propose-allocation-renderer .ag-center-cols-viewport {
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/features/staffing_request_details/custom_renderer/propose_allocation_renderer/propose_allocation_renderer.less"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,UAAA;EAEA,YAAA;EACA,gBAAA;AAFF;AAIE;EACE,8BAAA;EACA,eAAA;EACA,cAAA;EACA,YAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;EACA,2CAAA;AAFJ;AAMI;EACE,aAAA;AAJN;AAOI;EACE,mBAAA;AALN;AAMM;;;;;EAKE,2CAAA;AAJR;AAMM;;;;;EAKE,0CAAA;AAJR;AAQI;EACE,gBAAA;AANN","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.propose-allocation-renderer {\n  background: @dark-grey-10;\n  padding: 0;\n\n  height: 40px;\n  overflow: hidden;\n\n  &__propose-time-label {\n    font-family: RWESansWeb-Medium;\n    font-size: 12px;\n    color: @brand;\n    height: 39px;\n    align-items: center;\n    width: 100%;\n    display: flex;\n    padding-left: 55px;\n    border-bottom: 1px solid @silver-veil !important;\n  }\n\n  .ag {\n    &-header {\n      display: none;\n    }\n\n    &-cell {\n      background: @dark-grey-10;\n      &:not(.ag-cell-focus):not(.ag-cell-range-selected):not(\n          .ag-selection-fill-right\n        ):not(.ag-selection-fill-left):not(.ag-selection-fill-top):not(\n          .ag-selection-fill-bottom\n        ) {\n        border-bottom: 1px solid @silver-veil !important;\n      }\n      &.allocation-cell:not(.ag-cell-focus):not(.ag-cell-range-selected):not(\n          .ag-selection-fill-right\n        ):not(.ag-selection-fill-left):not(.ag-selection-fill-top):not(\n          .ag-selection-fill-bottom\n        ) {\n        border-right: 1px solid @silver-veil !important;\n      }\n    }\n\n    &-center-cols-viewport {\n      overflow: hidden;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
