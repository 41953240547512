import React, { useEffect } from "react";

import { EditOutlined, MailOutlined } from "@ant-design/icons";
import { FilterTabs } from "@src/features/table_filtering/components/filter_tabs";
import { setCurrentTabConfig } from "@src/services/resourcePlanSliceV2";
import { useAppDispatch, useAppSelector, RootState } from "@src/setupStore";
import { TabItem } from "@src/types/antd_types";

export enum ResourcePlanTabNames {
  DRAFTS = "drafts",
  RESOURCE_PLAN_INBOX = "resource-plan-inbox",
}

interface TabsResourcePlanProps {
  disabled: boolean;
  amountOfDrafts: number;
}

const TabsResourcePlan: React.FC<TabsResourcePlanProps> = ({
  disabled,
  amountOfDrafts,
}) => {
  const dispatch = useAppDispatch();

  const {
    resourcePlanTableState: { currentTabConfig },
  } = useAppSelector((state: RootState) => state.resourcePlanSliceV2);

  const tabItems: TabItem[] = [
    {
      key: ResourcePlanTabNames.RESOURCE_PLAN_INBOX,
      label: "Resource Plan & Inbox",
      icon: <MailOutlined />,
    },
    {
      key: ResourcePlanTabNames.DRAFTS,
      label:
        amountOfDrafts !== undefined ? `Drafts (${amountOfDrafts})` : "Drafts",
      icon: <EditOutlined />,
    },
  ];

  useEffect(() => {
    if (!currentTabConfig) {
      applyTabConfigOnChange(tabItems[0].key);
    }
  }, [currentTabConfig]);

  function applyTabConfigOnChange(key: string) {
    const tabConfig = tabItems.find((tab: TabItem) => tab.key === key);
    dispatch(
      setCurrentTabConfig({
        ...tabConfig,
        icon: undefined, // reset icon to undefined to avoid redux issues
      })
    );
  }

  return currentTabConfig ? (
    <FilterTabs
      disabled={disabled}
      items={tabItems}
      isLoading={disabled}
      defaultActiveConfig={currentTabConfig}
      onChange={applyTabConfigOnChange}
    />
  ) : null;
};

export default TabsResourcePlan;
