import React, { useState } from "react";

import { ROLES } from "@src/constants";
import { useGetDepartmentsQuery } from "@src/services/slices/departmentsSlice";
import {
  useGetEmployeesQuery,
  useLazyGetEmployeeByUiNumberQuery,
  useSaveEmployeeMutation,
  useUpdateEmployeeMutation,
} from "@src/services/slices/employeesSlice";
import { Department, Employee } from "@src/types";
import { Col, Form, Input, Modal, Row, Select, message } from "antd";
import "./employee_form.less";

export interface EmployeeFormProps {
  setModalVisible?: (modalVisible: boolean) => void;
  modalVisible: boolean;
  record: Employee;
  type: string;
}

const EmployeeForm: React.FC<EmployeeFormProps> = ({
  setModalVisible,
  modalVisible,
  record,
  type,
}) => {
  const [form] = Form.useForm<Employee>();
  const [formErrors, setFormErrors] = useState<Set<string>>(new Set());
  const [validationErrors, setValidationError] = useState<number>(0);

  /* rtk queries */
  const { data: departmentData } = useGetDepartmentsQuery();
  const { data: teamLeads, isFetching: isFetchingTeamLeads } =
    useGetEmployeesQuery({
      filterParams: { roles: [ROLES.TEAM_LEAD] },
    });

  /* Lazy queries */
  const [updateEmployee] = useUpdateEmployeeMutation();
  const [saveEmployee] = useSaveEmployeeMutation();
  const [fetchEmployeeByUiNumber] = useLazyGetEmployeeByUiNumberQuery();

  async function onFinish(fieldsValue: Employee) {
    const updatedEmployeeObject: Employee = {
      ...record,
      ...fieldsValue,
      departmentCode: departmentData?.find(
        (department: Department) =>
          department.departmentId === fieldsValue.departmentId
      ).orgUnit,
      fullName: `${fieldsValue.lastName}, ${fieldsValue.firstName}`,
    };
    updatedEmployeeObject.sapData = false;

    if (type === "add") {
      onAddEmployee(updatedEmployeeObject);
    } else if (type === "edit") {
      onUpdateEmployee(updatedEmployeeObject);
    }
  }

  function onAddEmployee(updatedEmployeeObject: Employee) {
    updatedEmployeeObject.employeeId = -1;
    updatedEmployeeObject.applicationRoles = ["Team member"];
    updatedEmployeeObject.primaryRoles = [];
    saveEmployee(updatedEmployeeObject)
      .unwrap()
      .then(() => {
        message.success("Successfully added employee");
      })
      .catch(() => {
        console.log("Error saving employee");
      });

    setModalVisible(false);
  }

  function onUpdateEmployee(updatedEmployeeObject: Employee) {
    updatedEmployeeObject.employeeId = record.employeeId;
    updateEmployee(updatedEmployeeObject)
      .unwrap()
      .then(() => {
        message.success("Successfully updated employee");
      })
      .catch(() => {
        console.log("Error updating employee");
      });

    setModalVisible(false);
  }

  function validateRange(rule: any, value: number): Promise<void> {
    const column: string = rule.field;

    if (value < 0 || value > 100) {
      return errorHandling(`Value should be in between 0 and 100`, column);
    }

    resetFormErrors(column);
    return Promise.resolve();
  }

  /**
   * Resets the error state for a specific form column.
   *
   * This function checks if the specified column has an error in the formErrors set.
   * If it does, the function creates a copy of the current formErrors,
   * removes the error associated with the specified column, and updates the formErrors state with the new set.
   * Finally, it updates the validationError state to reflect the current number of form errors.
   *
   * @param {string} column - The name of the form column for which the error state needs to be reset.
   */
  function resetFormErrors(column: string) {
    if (formErrors.has(column)) {
      const updatedErrorNodes: Set<string> = new Set(formErrors);
      updatedErrorNodes.delete(column);
      setFormErrors(new Set(updatedErrorNodes));
      setValidationError(updatedErrorNodes?.size);
    }
  }

  function validateRequiredFields(rule: any, value: string): Promise<void> {
    const column: string = rule.field;
    resetFormErrors(column);

    return !value
      ? errorHandling(`This field is required`, column)
      : Promise.resolve();
  }

  async function validateUiNumberField(
    rule: any,
    value: string
  ): Promise<void> {
    const column: string = rule.field;
    const allowedPrefixString = "UI, RE, R, SRV6T";

    let numericId: string = null;
    const isChanged = record?.uiNumber !== value;
    const existingEmployee: Employee = await fetchEmployeeByUiNumber(
      value
    ).unwrap();

    if (existingEmployee && isChanged) {
      return errorHandling(
        `The ui number: ${value} is already existing`,
        column
      );
    } else if (!value) {
      return Promise.resolve();
    } else if (value.startsWith("RE") || value.startsWith("UI")) {
      numericId = value.substring(2);
      return checkUiAndRcdNumberLength(numericId, column, allowedPrefixString);
    } else if (value.startsWith("R")) {
      numericId = value.substring(1);
      return checkUiAndRcdNumberLength(numericId, column, allowedPrefixString);
    } else {
      return errorHandling(
        `The ui number is in the wrong format (should contain the prefix UI, RE or R.`,
        column
      );
    }
  }

  function checkUiAndRcdNumberLength(
    numericValue: string,
    column: string,
    prefix: string
  ): Promise<void> {
    if (numericValue.length < 5 || numericValue.length > 6) {
      return errorHandling(
        `The number after the prefix (${prefix}) should have at least 5 to 6 characters.`,
        column
      );
    }

    resetFormErrors(column);
    return Promise.resolve();
  }

  function validateEmail(rule: any, value: string): Promise<void> {
    const column = rule.field;

    const regex = new RegExp(
      "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|" +
        "(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
    );

    if (regex.test(value) && value.includes("rwe.com")) {
      resetFormErrors(column);
      return Promise.resolve();
    } else {
      return errorHandling(`Wrong email format.`, column);
    }
  }

  function errorHandling(message: string, column: string): Promise<void> {
    setFormErrors((formErrors: Set<string>) => new Set(formErrors.add(column)));
    setValidationError(formErrors?.size);
    return Promise.reject(message);
  }

  function handleCancel() {
    form.resetFields();
    setFormErrors(new Set());
    setValidationError(0);
    setModalVisible(false);
  }

  return (
    <div className="employee-form">
      <Modal
        width="60%"
        open={modalVisible}
        maskClosable={false}
        title={type === "add" ? "Add new employee" : "Employee data"}
        centered
        closable={false}
        getContainer={false}
        bodyStyle={{ width: "100%" }}
        onCancel={handleCancel}
        okText={"Save"}
        okButtonProps={{
          form: "myForm",
          htmlType: "submit",
          disabled: formErrors.size > 0 || validationErrors > 0,
        }}
      >
        <div className="modal-form">
          <Form
            id="myForm"
            form={form}
            autoComplete="off"
            layout="vertical"
            size="large"
            onFinish={onFinish}
            initialValues={record}
          >
            <Row gutter={[48, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Employee first name"
                  name="firstName"
                  validateTrigger="onBlur"
                  rules={[{ validator: validateRequiredFields }]}
                >
                  <Input data-testid="surname" placeholder="Type first name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Employee last name"
                  name="lastName"
                  data-testid="last-name-form"
                  validateTrigger="onBlur"
                  rules={[{ validator: validateRequiredFields }]}
                >
                  <Input data-testid="lastName" placeholder="Type last name" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[48, 16]}>
              <Col span={12}>
                <Form.Item
                  label="UI Number"
                  name="uiNumber"
                  validateTrigger="onBlur"
                  rules={[
                    { validator: validateRequiredFields },
                    { validator: validateUiNumberField },
                  ]}
                >
                  <Input data-testid="uiNumber" placeholder="Type UI-Number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Line Manager"
                  name="teamLeadId"
                  validateTrigger="onBlur"
                  rules={[{ validator: validateRequiredFields }]}
                >
                  <Select
                    loading={isFetchingTeamLeads}
                    data-testid="teamleadSelection"
                    showSearch
                    placeholder="Select Line Manager"
                    optionFilterProp="label"
                    options={teamLeads?.content.map((teamLead: Employee) => ({
                      value: teamLead.employeeId,
                      label: teamLead.fullName,
                      "data-testid": teamLead.employeeId,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[48, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Contract type"
                  name="contractType"
                  validateTrigger="onBlur"
                  rules={[{ validator: validateRequiredFields }]}
                >
                  <Input
                    data-testid="contractType"
                    placeholder="Type contract type"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Availability"
                  name="totalAvailablePercentage"
                  validateTrigger="onBlur"
                  rules={[
                    { validator: validateRequiredFields },
                    { validator: validateRange },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Type availability (percentage)"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[48, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Org-Unit"
                  name="departmentId"
                  rules={[{ validator: validateRequiredFields }]}
                >
                  <Select
                    data-testid="department"
                    showSearch
                    data-type="number"
                    placeholder="Select Org-Unit"
                    optionFilterProp="label"
                    options={departmentData?.map((department: Department) => ({
                      value: department.departmentId,
                      label: `${department.orgUnit} ${department.orgName}`,
                      "data-testid": department.orgUnit,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Cost center (optional)"
                  name="costCenter"
                  validateTrigger="onBlur"
                >
                  <Input
                    data-testid="costCenter"
                    placeholder="Type cost Center"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[48, 16]}>
              <Col span={12}>
                <Form.Item
                  label="RCD-Number (optional)"
                  name="rcdNumber"
                  validateTrigger="onBlur"
                >
                  <Input
                    data-testid="rcdNumber"
                    placeholder="Type RCD-Number"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Position ID (optional)"
                  name="deskId"
                  validateTrigger="onBlur"
                >
                  <Input
                    data-testid="positionId"
                    placeholder="Type position ID"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[48, 16]}>
              <Col span={12}>
                <Form.Item
                  label="E-Mail"
                  name="email"
                  validateTrigger="onBlur"
                  rules={[{ validator: validateEmail }]}
                >
                  <Input data-testid="email" placeholder="Type E-Mail" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default EmployeeForm;
