import { useEffect, useState } from "react";

import { LeftOutlined } from "@ant-design/icons";
import { ResourcePlanTable } from "@src/features/resource_plan_v2/components/resource_plan_table";
import {
  clearFilterSaveProjectId,
  setProjectId,
} from "@src/services/resourcePlanSliceV2";
import { useGetProjectQuery } from "@src/services/slices/projectsSlice";
import { useAppDispatch, useAppSelector } from "@src/setupStore";
import { Button, Switch } from "antd";
import "./project_page_v2.less";
import { useHistory, useParams } from "react-router-dom";

const ProjectPageV2: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [showProjectDetails, setShowProjectDetails] = useState<boolean>(false);

  const { projectId } = useAppSelector((state) => state.resourcePlanSliceV2);
  const dispatch = useAppDispatch();

  // Todo: when fetching project details update ProjectSlice and endpoints
  const { data: projectData } = useGetProjectQuery(id);

  function onChangeSwitchDetails(checked: boolean) {
    setShowProjectDetails(checked);
    // Todo: implementation of showing project details
  }

  useEffect(() => {
    if (projectId !== id) {
      dispatch(clearFilterSaveProjectId());
      dispatch(setProjectId(id));
    }
  }, []);

  return (
    <div className="project_page_v2">
      <div className="top-bar">
        <div className="top-bar__button-title">
          <Button onClick={() => history.push("/projects")}>
            <LeftOutlined /> My Projects
          </Button>
          <h2>{projectData?.name}</h2>
        </div>
        <span className="top-bar__switch-details">
          <Switch onChange={onChangeSwitchDetails} /> <h5>Show Details</h5>
        </span>
      </div>
      {projectData && (
        <ResourcePlanTable
          projectId={id}
          projectEndDate={projectData?.endDate}
        />
      )}
    </div>
  );
};

export default ProjectPageV2;
