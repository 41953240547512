import React from "react";

import { RoleRequestStatusIndicator } from "@src/components/role_request_status_indicator";
import { NotificationBar } from "@src/features/staffing_request_details/components/notification_bar";
import { Col, Row } from "antd";
import "./role_request_modal_header.less";

interface RoleRequestModalHeaderProps {
  requestedRoleId: number;
  requestedRoleStatus: string;
  requestedRoleName: string;
  isRequestOutdated: boolean;
}

const RoleRequestModalHeader: React.FC<
  Partial<RoleRequestModalHeaderProps>
> = ({
  requestedRoleStatus,
  requestedRoleId,
  requestedRoleName,
  isRequestOutdated,
}) => {
  const status = isRequestOutdated ? "Outdated" : requestedRoleStatus;

  return (
    <>
      <Row className="role-request-header">
        <Col className="role-request-header-indicator">
          <RoleRequestStatusIndicator size="large" status={status} />
        </Col>
        <Col>
          <div className="role-request-header-title">
            {status} - {requestedRoleId} - {requestedRoleName}
          </div>
        </Col>
      </Row>
      <Row className="role-request-header-notification">
        <NotificationBar requestedRoleId={requestedRoleId} />
      </Row>
    </>
  );
};

export default RoleRequestModalHeader;
