import { columnTypes, groupHeaderStyleOptions } from "@src/utils/aggrid_utils";
import { getAllocationColumnDefs } from "@src/utils/allocation_column_def_utils";
import {
  editableColumnDef,
  editableGridOptions,
  editAllocationCellClassRules,
  editAllocationValueGetter,
  editAllocationValueSetter,
} from "@src/utils/edit_allocation_utils";
import {
  CellClassParams,
  CellClassRules,
  ColDef,
  EditableCallbackParams,
  GridOptions,
  ValueGetterParams,
  ValueSetterParams,
} from "ag-grid-community";

import { valueFormatter } from "./propose_allocations_utils";

export function getGridOptions(): GridOptions {
  return {
    ...groupHeaderStyleOptions,
    ...editableGridOptions,
    rowHeight: 40,
    domLayout: "autoHeight",
    suppressContextMenu: true,
    defaultColDef: {
      ...columnTypes.default,
      resizable: false,
      sortable: false,
    },
    context: {
      errorColIds: [],
    },
  };
}

const cellClassRules: CellClassRules = {
  "no-focus-border": (params: CellClassParams): boolean => {
    return params.node.rowIndex === 0;
  },
};

function getColDefMonth(year: number, month: number): ColDef {
  return {
    ...editableColumnDef,
    cellClassRules: { ...editAllocationCellClassRules, ...cellClassRules },
    editable: (params: EditableCallbackParams) => params.node.rowIndex !== 0,
    valueGetter: (params: ValueGetterParams) =>
      editAllocationValueGetter(params, year, month),
    valueSetter: (params: ValueSetterParams) =>
      editAllocationValueSetter(params, year, month),
    valueFormatter: valueFormatter,
  };
}

export function getColumnDefs(workloadEndDate: Date): ColDef[] {
  const workloadStartDate = new Date();

  const allocationColumns: ColDef[] = getAllocationColumnDefs(
    workloadStartDate,
    workloadEndDate,
    getColDefMonth
  );

  return [
    {
      field: "label",
      headerName: "",
      pinned: "left",
      width: 320,
      cellClass: "label no-focus-border",
      suppressFillHandle: true,
    },
    ...allocationColumns,
  ];
}
