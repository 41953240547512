// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-tabs .tab-label {
  display: flex;
  align-items: center;
  gap: 5px;
}
.filter-tabs .ant-form {
  margin: 0;
}
.filter-tabs .ant-radio-group {
  background: #f6f6f4;
  border-radius: 4px;
  border: 1px solid #efefec;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter-tabs .ant-radio-group .ant-radio-button-wrapper {
  display: flex;
  align-items: center;
  height: 32px;
  margin: 4px;
  flex: none;
  background: none;
  color: #1d4477;
  border: none;
  font-family: "RWESansWeb-Medium", sans-serif;
}
.filter-tabs .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.filter-tabs .ant-radio-group .ant-radio-button-wrapper svg {
  width: 14px;
  height: 14px;
  fill: #1d4477;
  margin-right: 2px;
}
.filter-tabs .ant-radio-group .ant-radio-button-wrapper-checked {
  border-radius: 2px;
  background: #ffffff;
  box-shadow: 0px 2px 2px 0px #0000000d;
}
.filter-tabs .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/features/table_filtering/components/filter_tabs/filter_tabs.less"],"names":[],"mappings":"AAEA;EAEI,aAAA;EACA,mBAAA;EACA,QAAA;AAFJ;AAFA;EAQI,SAAA;AAHJ;AALA;EAYI,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAJJ;AAbA;EAoBM,aAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,UAAA;EACA,gBAAA;EACA,cAAA;EACA,YAAA;EACA,4CAAA;AAJN;AAMM;EACE,aAAA;AAJR;AA3BA;EAmCQ,WAAA;EACA,YAAA;EACA,aAAA;EACA,iBAAA;AALR;AAQM;EACE,kBAAA;EACA,mBAAA;EACA,qCAAA;AANR;AAQQ;EACE,6BAAA;AANV","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.filter-tabs {\n  .tab-label {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n  }\n\n  .ant-form {\n    margin: 0;\n  }\n\n  .ant-radio-group {\n    background: @sand-40;\n    border-radius: 4px;\n    border: 1px solid @sand-70;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    .ant-radio-button-wrapper {\n      display: flex;\n      align-items: center;\n      height: 32px;\n      margin: 4px;\n      flex: none;\n      background: none;\n      color: @brand;\n      border: none;\n      font-family: \"RWESansWeb-Medium\", sans-serif;\n\n      &:not(:first-child)::before {\n        display: none;\n      }\n\n      svg {\n        width: 14px;\n        height: 14px;\n        fill: @brand;\n        margin-right: 2px;\n      }\n\n      &-checked {\n        border-radius: 2px;\n        background: @white;\n        box-shadow: 0px 2px 2px 0px #0000000d;\n\n        &:not(.ant-radio-button-wrapper-disabled)::before {\n          background-color: transparent;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
