import React, { useState } from "react";

import { DownloadButton } from "@src/features/table_filtering/components/download_button";
import "./download_resource_plan_by_region.less";
import { useGetRegionsQuery } from "@src/services/slices/regionsSlice";
import { Region } from "@src/types";
import { Form, Select } from "antd";
import { DefaultOptionType } from "antd/es/select";

interface DownloadResourcePlanByRegionProps {
  disabled: boolean;
}

const DownloadResourcePlanByRegion: React.FC<
  DownloadResourcePlanByRegionProps
> = ({ disabled }) => {
  const [selectedRegionId, setSelectedRegionId] = useState(0);
  const [btnDisabledStatus, setBtnDisabledStatus] = useState(true);
  const { data: regions } = useGetRegionsQuery();

  function onChange(selectedRegionId: number) {
    if (selectedRegionId === 0) {
      setSelectedRegionId(0);
    } else {
      const regionObject: Region = regions.find(
        (region: Region) => region.regionId === selectedRegionId
      );

      if (regionObject) {
        setSelectedRegionId(regionObject.regionId);
      }
    }

    setBtnDisabledStatus(false);
  }

  function getRegionOptions(): DefaultOptionType[] {
    const noneOption: DefaultOptionType = {
      value: 0,
      label: "no region selected",
    };

    const regionsAsOptions: DefaultOptionType[] =
      regions?.map((region: Region) => ({
        value: region.regionId,
        label: region.regionName,
      })) || [];

    regionsAsOptions.unshift(noneOption);

    return regionsAsOptions;
  }

  return (
    <Form layout="vertical" size="large">
      <div className="form-inputs">
        <Form.Item>
          <Select
            className="region-selection"
            placeholder="please select a region"
            data-testid="region-selection"
            showSearch
            onChange={onChange}
            optionFilterProp="label"
            disabled={disabled}
            options={getRegionOptions()}
          />
        </Form.Item>
        <DownloadButton
          className="download-button"
          path={
            "/reports/export-admin-project-overview?regionId=" +
            selectedRegionId
          }
          disabled={btnDisabledStatus || disabled}
        />
      </div>
    </Form>
  );
};

export default DownloadResourcePlanByRegion;
