import React, { useEffect } from "react";

import { ReactComponent as CheckIcon } from "@src/assets/icons/union.svg";
import { ReactComponent as WarningIcon } from "@src/assets/icons/warning.svg";
import { FilterTabs } from "@src/features/table_filtering/components/filter_tabs";
import { setCurrentTabConfig } from "@src/services/requestOverviewSlice";
import { RootState, useAppDispatch, useAppSelector } from "@src/setupStore";
import { TabItem } from "@src/types/antd_types";

export enum StaffingRequestsTabs {
  REQUIRED_ACTION = "required-action",
  ALL = "all",
}

const tabsConfig: TabItem[] = [
  {
    key: StaffingRequestsTabs.REQUIRED_ACTION,
    label: "Required Action",
    icon: <WarningIcon />,
  },
  {
    key: StaffingRequestsTabs.ALL,
    label: "All",
    icon: <CheckIcon />,
  },
];

interface TabsStaffingRequestProps {
  disabled?: boolean;
}

const TabsStaffingRequests: React.FC<TabsStaffingRequestProps> = ({
  disabled = false,
}) => {
  const dispatch = useAppDispatch();

  const {
    requestOverviewTableState: { currentTabConfig },
  } = useAppSelector((state: RootState) => state.requestOverviewSlice);

  useEffect(() => {
    if (!currentTabConfig) {
      applyTabConfigOnChange(tabsConfig[0].key);
    }
  }, [currentTabConfig]);

  function applyTabConfigOnChange(key: string) {
    const tabConfig = tabsConfig.find((tab) => tab.key === key);
    dispatch(
      setCurrentTabConfig({
        ...tabConfig,
        icon: undefined, // reset icon to undefined to avoid redux issues
      })
    );
  }

  return (
    currentTabConfig && (
      <FilterTabs
        disabled={disabled}
        items={tabsConfig}
        defaultActiveConfig={currentTabConfig}
        onChange={applyTabConfigOnChange}
      />
    )
  );
};

export default TabsStaffingRequests;
