import React from "react";

import { StandardRole, RequestOverviewDetails } from "@src/types";
import { message } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import moment from "moment";

export const sapDTOStatusEnum = {
  ADDED: "Added",
  UPDATED: "Updated",
  DELETED: "Deleted",
  ERROR: "Error",
  NOCHANGE: "no change",
} as const;

export const getErrorMessage = (errorMessage: string): void => {
  message.error({
    content: errorMessage,
  });
};

export const calculatePercentage = (
  leftElements: number,
  totalElements: number
): number => {
  const percent = Math.trunc((leftElements * 100) / totalElements);
  return percent;
};

export const RequestIdTransformation = (
  requestId: number | string | null | undefined,
  projectName?: string
): string | null => {
  if (requestId) {
    if (projectName) {
      const formatedProjectName = projectName
        .toString()
        .replace(" - ", " ")
        .replace(/ /g, "_");
      return `${formatedProjectName}_${requestId.toString().padStart(4, "0")}`;
    } else {
      return `${requestId.toString().padStart(4, "0")}`;
    }
  } else {
    return null;
  }
};

interface AllocationDetails {
  year: number;
  month: number;
}

export const calculateTableHeader = (
  childRequest: AllocationDetails[] | null | undefined,
  allocationDetailsLast: AllocationDetails
): {
  startYear: number;
  startMonth: number;
  endYear: number;
  endMonth: number;
  noOfYears: number;
  totalMonths: number;
} => {
  const currentDate = new Date();
  const startYear = currentDate.getFullYear();
  let startMonth = currentDate.getMonth() + 1;
  const hasChildRequest = childRequest?.length > 0;

  const endYear = hasChildRequest
    ? Math.max(
        allocationDetailsLast.year,
        childRequest[childRequest.length - 1].year
      )
    : allocationDetailsLast.year;
  let endMonth = hasChildRequest
    ? Math.max(
        allocationDetailsLast.month / 100 + allocationDetailsLast.year,
        childRequest[childRequest.length - 1].month / 100 +
          childRequest[childRequest.length - 1].year
      )
    : allocationDetailsLast.month;

  /* istanbul ignore else*/
  if (hasChildRequest) {
    startMonth = Math.round((startMonth - startYear) * 100);
    endMonth = Math.round((endMonth - endYear) * 100);
  }

  const noOfYears = endYear - startYear;
  const totalMonths = moment([startYear, startMonth, 1]).diff(
    moment([endYear, endMonth, 1]),
    "months",
    true
  );

  return {
    startYear: startYear,
    startMonth: startMonth,
    endYear: endYear,
    endMonth: endMonth,
    noOfYears: noOfYears,
    totalMonths: totalMonths,
  };
};

interface DateRanges {
  startYear: number;
  startMonth: number;
  endYear: number;
  endMonth: number;
}

export const createStartMonthAndEndMonth = (
  dateRanges: DateRanges,
  year: number
): { currentStartMonth: number; currentEndMonth: number } => {
  let currentEndMonth = 12;
  let currentStartMonth = 1;

  if (new Date().getFullYear() === year) {
    currentStartMonth = new Date().getMonth() + 1;
  } else if (dateRanges.startYear === dateRanges.endYear) {
    currentEndMonth = 12;
  } else if (year === dateRanges.startYear) {
    currentStartMonth = dateRanges.startMonth;
  } else if (year === dateRanges.endYear) {
    currentEndMonth = dateRanges.endMonth;
  }

  return {
    currentStartMonth: currentStartMonth,
    currentEndMonth: currentEndMonth,
  };
};

export const sortAlphabetically = <T extends Record<string, any>>(
  listToSort: T[],
  attributeName: keyof T
): void => {
  if (listToSort && listToSort.length !== 0) {
    listToSort.sort((a, b) => {
      const aValue = a[attributeName]?.toString().toUpperCase();
      const bValue = b[attributeName]?.toString().toUpperCase();
      if (aValue < bValue) {
        return -1;
      }
      if (aValue > bValue) {
        return 1;
      }
      return 0;
    });
  }
};

interface User {
  fullName: string;
  employeeId: string | number;
}

interface ModalMessage {
  title: JSX.Element;
  button: string;
  warning: JSX.Element | string;
}

export const RoleReassignModalMessage = async (
  user: User,
  standardRoles: StandardRole[]
): Promise<ModalMessage> => {
  const modalMessage: ModalMessage = {
    title: (
      <p>
        <b>
          The changes you made effect responsibilities for standard roles, old
          and new requests would be assigned to this team lead.
          <br />
          Proposals will turn in requested again. Forwarded requests will be
          transferred to <u>{user.fullName}</u>. <br />
          Please check them again to confirm your changes
        </b>
      </p>
    ),
    button: "Confirm",
    warning: "",
  };

  const li: JSX.Element[] = [];

  for (const standardRole of standardRoles) {
    if (
      standardRole.primaryTeamLeadEmployeeId !== null &&
      standardRole.primaryTeamLeadEmployeeId !== user.employeeId
    ) {
      li.push(
        <li key={standardRole.standardRoleId}>
          <b> {standardRole.standardRoleName}</b> : from{" "}
          <u>{standardRole.teamLeadFullName}</u> to <u>{user.fullName}</u>
        </li>
      );
    }
  }

  modalMessage.warning = (
    <>
      {li.length !== 0 && "Handover responsibilities of roles"}
      <ul>{li}</ul>
    </>
  );

  return modalMessage;
};

export const toEqualStringLists = (
  list1: string[],
  list2: string[]
): boolean => {
  if (!list1 || !list2) {
    return false;
  }
  if (list1.length !== list2.length) {
    return false;
  }
  const listHelper = list1.filter((item) => list2.includes(item));
  return listHelper.length === list1.length;
};

export function getDistinctList<T>(list: T[], sorted?: boolean): T[] {
  return sorted ? [...new Set(list)].sort() : [...new Set(list)];
}

export const switchNameAndSurname = (
  fullNameToSwitchNameAndSurname: string
): string => {
  const nameStartIndex = fullNameToSwitchNameAndSurname.indexOf(",") + 1;
  const name = fullNameToSwitchNameAndSurname.substring(nameStartIndex).trim();

  const surname = fullNameToSwitchNameAndSurname
    .substring(0, fullNameToSwitchNameAndSurname.indexOf(","))
    .trim();

  return `${name} ${surname}`;
};

export function areArraysIdentical<T>(arr1: T[], arr2: T[]): boolean {
  return _.isEqual(_.sortBy(arr1), _.sortBy(arr2));
}

/**
 * This function is used to get the initials of a name
 * @param name {string} the name to get the initials from
 */
export function getInitials(name: string): string {
  if (!name) {
    return "";
  }

  return name
    .split(/[\s-]+/) // Split on spaces and hyphens
    .filter((n) => n)
    .map((n) => n[0])
    .join("");
}

/**
 * Returns the earliest workload start date from a workload allocation object.
 * If the parent request has a start date, it will be used. Otherwise, the earliest child request start date will be used.
 * If the child request is not earlier than the parent request, the parent request start date will be used.
 * Otherwise, the child request start date will be used.
 *
 * @returns {Date | null} Earliest start date as a Date object, or `null` if none found.
 *
 * @param request
 */
export function getWorkloadStartDate(request: RequestOverviewDetails): string {
  const currentRequest: RequestOverviewDetails =
    request?.childRequest || request;
  const date: Date = currentRequest?.workloadStartDate;
  return date ? dayjs(date).format("DD/MM/YYYY") : "";
}

/**
 * Formats a date string in "DD/MM/YYYY" format.
 *
 * @param {string} input - The date string to format, compatible with the Date constructor.
 * @returns {string} - The formatted date string in "DD/MM/YYYY" format.
 *
 * @example
 * formatDate("2024-11-30"); // Returns "30/11/2024"
 */
export function formatDate(input: string | Date): string {
  if (input === null) return "";
  if (typeof input === "string") {
    input = new Date(input);
  }
  const day: string = String(input.getDate()).padStart(2, "0");
  const month: string = String(input.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year: string = input.getFullYear().toString();

  return `${day}/${month}/${year}`;
}

/**
 * Concatenates multiple CSS class names into a single string.
 *
 * @param classes - A list of CSS class names.
 * @returns A single string with class names separated by a space.
 */
export function clsx(...classes: string[]): string {
  return classes.join(" ");
}
