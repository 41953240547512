import React, { useCallback, useEffect, useRef, useState } from "react";

import { AgGridReact } from "ag-grid-react";
import { Select } from "antd";

import "./reassign_team_member_with_requests.less";

import {
  columnDefs,
  gridOptions,
} from "../../utils/reassign_team_member_table_utils";

export default function ReassignTeamMemberWithRequests({
  selectedStandardRoles,
  openedDetailsSapDTO,
}) {
  const [selectedRoleNames, setSelectedRoleNames] = useState([]);
  const gridApi = useRef(null);

  useEffect(() => {
    if (selectedStandardRoles) {
      setSelectedRoleNames(
        selectedStandardRoles.map((role) => role.standardRoleName)
      );
    }
  }, [selectedStandardRoles]);

  const onChange = (selectedGenericRoleIds, assignedTeammemberId) => {
    //get member with assigned requests
    const member = openedDetailsSapDTO.data.teammemberWithAssignedRequests.find(
      (member) => member.assignedTeammemberId === assignedTeammemberId
    );

    //get only selected requests objects
    for (let request of member.totalAssignedRequestsByStandardrole) {
      request.forwardRequest = selectedGenericRoleIds.includes(
        request.standardRoleId
      );
    }
  };

  const requestsRenderer = (params) => {
    const genericRoleNames = selectedStandardRoles.map(
      (role) => role.standardRoleName
    );
    const allEmployeeRequestsWthoutSelectedRole =
      params.data.totalAssignedRequestsByStandardrole.filter(
        (sa) => !genericRoleNames.includes(sa.standardRoleName)
      );

    return (
      <Select
        placeholder="No requests assigned"
        data-testid="role-selection"
        showSearch
        mode="multiple"
        className="resource-selection"
        onChange={(props) =>
          onChange(props, params?.data?.assignedTeammemberId)
        }
        defaultValue={allEmployeeRequestsWthoutSelectedRole.map(
          (req) => req.genericRoleId
        )}
        optionFilterProp="label"
        options={allEmployeeRequestsWthoutSelectedRole?.map(
          (assignedRequests) => ({
            value: assignedRequests.genericRoleId,
            assignedTeammember: params?.data?.assignedTeammemberId,
            label: `${assignedRequests.genericRoleName} (${assignedRequests.totalRequests})`,
          })
        )}
      />
    );
  };

  const onGridReady = (params) => {
    gridApi.current = params.api;
  };

  const frameworkComponents = {
    requestsRenderer: requestsRenderer,
  };

  const assignedRolesMsg = () => {
    const selectedStanardRoleNames = (
      <b>{selectedRoleNames.toString().replace(/,/g, ",  ")}</b>
    );
    return (
      <div className="assigned-roles-msg" data-testid="assigned-roles-info">
        You have assigned{" "}
        <span className="label">{selectedStanardRoleNames}</span> to:{" "}
        <span className="label">
          {openedDetailsSapDTO && (
            <>
              {openedDetailsSapDTO.data.firstName}{" "}
              {openedDetailsSapDTO.data.lastName}
            </>
          )}
        </span>
      </div>
    );
  };

  const onFirstDataRendered = useCallback(() => {
    gridApi.current.sizeColumnsToFit();
  }, []);

  return (
    <>
      {selectedRoleNames && selectedRoleNames.length !== 0 && (
        <>{assignedRolesMsg()}</>
      )}
      <div
        className="assigned-roles-msg"
        data-testid="assigned-roles-msg-warning"
      >
        If you deselect requests than the requests will be set back to requested
        and will stay with the old Team Lead's list.
        <br />
        Otherwise, the assigned requests will be transferred to{" "}
        <span className="label">
          {openedDetailsSapDTO && (
            <>
              {openedDetailsSapDTO.data.firstName}{" "}
              {openedDetailsSapDTO.data.lastName}
            </>
          )}
        </span>
      </div>
      <div className="ag-theme-alpine header-white">
        <AgGridReact
          rowData={openedDetailsSapDTO.data.teammemberWithAssignedRequests}
          columnDefs={columnDefs}
          autoSizeAllColumns={true}
          onGridReady={onGridReady}
          gridOptions={gridOptions}
          components={frameworkComponents}
          onFirstDataRendered={onFirstDataRendered}
        />
      </div>
    </>
  );
}
