import { REQUEST_STATUS, BADGE_VARIANTS } from "@src/constants";
import { TeamLeadRequestOverviewItem } from "@src/types";
import { userUtils } from "@src/utils/login_utils";
import { ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";

export function showBadge(
  primaryTeamLeadEmployeeId: number,
  status: string
): boolean {
  return (
    !userUtils.getEmployeeData().isPrimary(primaryTeamLeadEmployeeId) ||
    status !== REQUEST_STATUS.IN_PROGRESS
  );
}

export function getBadgeVariant(params: ValueGetterParams): string | undefined {
  const shouldShowBadge: boolean = showBadge(
    params.data.primaryTeamLeadEmployeeId,
    params.data.status
  );
  if (!shouldShowBadge) return;
  if (params.data.outdated) return BADGE_VARIANTS.OUTDATED;
  if (params.data.isDeclined) return BADGE_VARIANTS.DECLINED;
  if (params.data.commentAvailable && !params.data.commentReadByEmployee)
    return BADGE_VARIANTS.COMMENT;
  if (params.data.isUpdated) return BADGE_VARIANTS.UPDATED;
}

export function getBadgeClass(variant: string): string {
  switch (variant) {
    case BADGE_VARIANTS.OUTDATED:
      return "outdatedBadge";
    case BADGE_VARIANTS.COMMENT:
      return "commentBadge";
    case BADGE_VARIANTS.DECLINED:
    case BADGE_VARIANTS.UPDATED:
      return "notificationCount";
    default:
      return "";
  }
}

export function isDateInRange(
  date: Date,
  rangeStartDate: Date,
  rangeEndDate: Date
): boolean {
  return dayjs(date).isBetween(rangeStartDate, rangeEndDate, "days", "[]");
}

export function mapToRowData(
  requests: TeamLeadRequestOverviewItem[]
): TeamLeadRequestOverviewItem[] {
  return requests?.map((request) => ({
    ...request,
    region: request.region || "N.A.",
  }));
}
