import { useMemo, useRef } from "react";

import { NoRowsOverlay } from "@src/components/overlays/no_rows_overlay";
import {
  setConfirmationButtonDisableStatus,
  setDeclineAllocations,
} from "@src/services/nextStepModalSlice";
import { RequestOverviewDetails } from "@src/types";
import {
  AllocationRowData,
  TeamLeadAllocationRowData,
} from "@src/types/role_request_types";
import {
  CellValueChangedEvent,
  ColDef,
  ColumnVisibleEvent,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { App } from "antd";
import _ from "lodash";
import { useDispatch } from "react-redux";

import {
  getColumnDefs,
  getGridOptions,
} from "../../utils/propose_allocation_grid_options";
import {
  filterMapForUpdatedValues,
  mapRequestToSuggestWorkloadRowData,
  toggleErrorFlagInContext,
} from "../../utils/propose_allocations_utils";

interface DeclineProposeTableProps {
  request: RequestOverviewDetails;
}

const DeclineProposeTable: React.FC<DeclineProposeTableProps> = ({
  request,
}) => {
  const { message } = App.useApp();

  const gridApi = useRef<GridApi | null>(null);
  const dispatch = useDispatch();

  const workloadEndDate: Date = _.last(request.roleAllocationDetails)?.date;

  const columnDefs = useMemo<ColDef[]>(() => {
    return getColumnDefs(workloadEndDate);
  }, [workloadEndDate]);

  const rowData = useMemo<TeamLeadAllocationRowData[]>(() => {
    return mapRequestToSuggestWorkloadRowData(request);
  }, [request]);

  /**
   * AG Grid Ready event
   *
   * @param {GridReadyEvent} event - AG Grid event parameter
   */
  function onGridReady(event: GridReadyEvent): void {
    gridApi.current = event.api;
  }

  /**
   * AG Grid onCellValueChanged event
   *
   * Toggles an error if the new value is invalid or remove it when valid.
   *
   * It handles the enabling and disabling of the confirmation button and adds the proposed
   * allocations to the store.
   *
   * Checks if any value in the table is invalid or if nothing is updated. If yes, confirmation button will be disabled.
   * If not, the confirmation button will be enabled and the updated allocation will be stored.
   *
   * @param {CellValueChangedEvent} event AG Grid event parameter
   */
  function onCellValueChanged(event: CellValueChangedEvent) {
    let shouldDisableConfirmationButton = false;

    toggleErrorFlagInContext(event, message);

    const hasAnyInvalidValue: boolean = event.context.errorColIds.length > 0;
    shouldDisableConfirmationButton = hasAnyInvalidValue;

    if (!hasAnyInvalidValue) {
      const updatedAllocations: Map<string, AllocationRowData> =
        filterMapForUpdatedValues(event.data.allocations);

      // when no allocations are updated, the button needs to be disabled
      dispatch(setDeclineAllocations(updatedAllocations));
      shouldDisableConfirmationButton = !updatedAllocations?.size;
    }

    dispatch(
      setConfirmationButtonDisableStatus(shouldDisableConfirmationButton)
    );
  }

  return (
    <div className="ag-theme-alpine">
      <AgGridReact
        rowData={rowData}
        gridOptions={getGridOptions()}
        onGridReady={onGridReady}
        onColumnVisible={(event: ColumnVisibleEvent) =>
          event.api.sizeColumnsToFit()
        }
        onCellValueChanged={onCellValueChanged}
        columnDefs={columnDefs}
      />
    </div>
  );
};

export default DeclineProposeTable;
