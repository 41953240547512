import { getAllocationColumnDefs } from "@src/utils/allocation_column_def_utils";
import {
  editableColumnDef,
  editableGridOptions,
  editAllocationCellClassRules,
  editAllocationValueGetter,
  editAllocationValueSetter,
} from "@src/utils/edit_allocation_utils";
import {
  ColDef,
  GridOptions,
  ValueGetterParams,
  ValueSetterParams,
} from "ag-grid-community";

import { valueFormatter } from "./propose_allocations_utils";

export function getProposeTeamMemberTimeRendererGridOptions(): GridOptions {
  return {
    ...editableGridOptions,
    rowHeight: 40,
    suppressContextMenu: true,
    domLayout: "autoHeight",
    context: {
      errorColIds: [],
    },
  };
}

export function getColumnDefs(workloadEndDate: Date): ColDef[] {
  const workloadStartDate = new Date();

  return getAllocationColumnDefs(
    workloadStartDate,
    workloadEndDate,
    getColDefMonth
  );
}

function getColDefMonth(year: number, month: number): ColDef {
  return {
    ...editableColumnDef,
    cellClassRules: editAllocationCellClassRules,
    valueGetter: (params: ValueGetterParams) =>
      editAllocationValueGetter(params, year, month),
    valueSetter: (params: ValueSetterParams) =>
      editAllocationValueSetter(params, year, month),
    valueFormatter: valueFormatter,
  };
}
