import { MsalProvider } from "@azure/msal-react";
import { setupStore } from "@src/setupStore";
import { App as AntApp, ConfigProvider } from "antd";
import { Provider } from "react-redux";

import "./App.css";
import { msalInstance } from "./auth";
import { AppLayout } from "./components/app_layout";
import ErrorBoundary from "./components/error_boundary";
import { ValidateAuth } from "./components/login/";
import { extendDaysjsPlugins } from "./dayjs_plugins";
import { defaultTheme } from "./stylings/default_theme";
import { initializeFaro } from "./utils/faro";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Router from "@src/router/Router";
import { BrowserRouter } from "react-router-dom";

extendDaysjsPlugins();

initializeFaro();

function App() {
  return (
    <ConfigProvider theme={defaultTheme}>
      <AntApp>
        <Provider store={setupStore({})}>
          <div className="app-container">
            <MsalProvider instance={msalInstance}>
              <ErrorBoundary>
                <BrowserRouter>
                  <ValidateAuth>
                    <AppLayout> {<Router />}</AppLayout>
                  </ValidateAuth>
                </BrowserRouter>
              </ErrorBoundary>
            </MsalProvider>
          </div>
        </Provider>
      </AntApp>
    </ConfigProvider>
  );
}

export default App;
