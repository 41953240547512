// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-button__editor-select {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/features/resource_plan_v2/components/editor_button/editor_button.less"],"names":[],"mappings":"AAGE;EACE,WAAA;AAFJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.editor-button {\n  &__editor-select {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
