import React from "react";

import { RadioButtonRenderer } from "@src/custom_renderer/radio_button_renderer";
import { EmployeeNameRenderer } from "@src/features/staffing_request_details/custom_renderer/employee_name_renderer";
import { ProposeAllocationRenderer } from "@src/features/staffing_request_details/custom_renderer/propose_allocation_renderer";
import { SearchInput } from "@src/features/table_filtering/components/search_input";
import { TeamMemberCapacityV2 } from "@src/types";
import { FrameworkComponents } from "@src/types/aggrid_types";
import {
  AllocationRowData,
  ProposeAllocationRowData,
} from "@src/types/role_request_types";
import { columnTypes, groupHeaderStyleOptions } from "@src/utils/aggrid_utils";
import { getAllocationColumnDefs } from "@src/utils/allocation_column_def_utils";
import {
  CellClassParams,
  ColDef,
  FilterModel,
  GetRowIdParams,
  GridOptions,
  ValueGetterParams,
} from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import dayjs from "dayjs";

import { valueFormatter } from "./propose_allocations_utils";

/**
 * Get the grid options
 *
 * @returns {GridOptions} The grid options
 */
export function getGridOptions(): GridOptions {
  return {
    ...groupHeaderStyleOptions,
    components: proposeTeamMemberComponents(),
    suppressContextMenu: true,
    masterDetail: true,
    embedFullWidthRows: true,
    detailRowHeight: 40,
    animateRows: false,
    defaultColDef: {
      ...columnTypes.default,
      resizable: false,
      sortable: false,
    },
    getRowId: (params: GetRowIdParams<ProposeAllocationRowData>) => {
      return params.data.employee?.employeeId?.toString() ?? params.data.label;
    },
    detailCellRenderer: (props: CustomCellRendererProps) => (
      <ProposeAllocationRenderer props={props} />
    ),
  };
}

/**
 * Propose team member components
 * @returns {FrameworkComponents} The framework components
 */
function proposeTeamMemberComponents(): FrameworkComponents {
  return {
    nameCellRenderer: (
      props: CustomCellRendererProps<TeamMemberCapacityV2>
    ) => {
      return <EmployeeNameRenderer props={props} />;
    },
    radioButtonRenderer: (
      props: CustomCellRendererProps<TeamMemberCapacityV2>
    ) => {
      return <RadioButtonRenderer params={props} />;
    },
  };
}

/**
 * Handle the search event
 *
 * @param props     The search component properties
 * @param searchValue The search value
 */
function onSearch(
  props: CustomCellRendererProps<TeamMemberCapacityV2>,
  searchValue: string
): void {
  const filter: FilterModel = {
    [props.column.getColId()]: {
      filterType: "text",
      type: "contains",
      filter: searchValue,
    },
  };

  props.api.setFilterModel(filter);
}

/**
 * Get the column definitions for the grid
 *
 * @param requestEndDate    The request end date
 * @returns {ColDef[]} The column definitions
 */
export function getColumnDefs(workloadEndDate: Date): ColDef[] {
  const workloadStartDate = new Date();

  const allocationColumns: ColDef[] = getAllocationColumnDefs(
    workloadStartDate,
    workloadEndDate,
    getColDefMonth
  );

  return [
    {
      width: 10,
      pinned: "left",
      field: "label",
      cellRendererSelector: (props: CustomCellRendererProps) =>
        !props.node.rowPinned && { component: "radioButtonRenderer" },
      colSpan: (params: CellClassParams) => {
        return params.node.rowPinned && 2;
      },
    },
    {
      field: "employee",
      headerName: "",
      pinned: "left",
      width: 250,
      filter: "agTextColumnFilter",
      cellRenderer: "nameCellRenderer",
      filterValueGetter: (params: ValueGetterParams) => {
        return params.data.employee?.fullName;
      },
      headerComponent: (
        props: CustomCellRendererProps<TeamMemberCapacityV2>
      ) => (
        <SearchInput
          onChange={(searchValue: string) => onSearch(props, searchValue)}
        />
      ),
    },
    ...allocationColumns,
  ];
}

/**
 * Get the column definition for the month
 *
 * @param year     The year
 * @param month    The month
 */
function getColDefMonth(year: number, month: number): ColDef {
  return {
    valueGetter: (params: ValueGetterParams) =>
      customValueGetter(params, year, month),
    valueFormatter: valueFormatter,
  };
}

/**
 * Get the custom value getter
 *
 * @param {ValueGetterParams} params The value getter parameters
 * @param {number} year The year
 * @param {number} month The month
 *
 * @returns {number} The custom value
 */
export function customValueGetter(
  params: ValueGetterParams,
  year: number,
  month: number
): number {
  const foundAllocation: AllocationRowData = params.data.allocations.find(
    (allocation: AllocationRowData) =>
      dayjs(allocation.date).month() + 1 === month &&
      dayjs(allocation.date).year() === year
  );
  return foundAllocation?.percentage || 0;
}
